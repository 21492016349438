import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { Field } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useState } from "react";
import Styles from "../profile.module.css";
import WorkStyles from "./worksection.module.css";

import Checkbox from "@mui/material/Checkbox";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import SkillSelection from "./SkillSelection";

function AddWorkExperience(props) {
  const { values, handleChange, handleBlur, errors, touched } = props;

  const [selectedWorkSkills, setSelectedWorkSkills] = useState([]);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const updatedValues = { ...values };
    updatedValues.isWorking = isChecked;
    if (!isChecked) {
      updatedValues.workEndDate = ""; // Reset "Worked Till" date field when unchecked
    }
    handleChange({
      target: {
        name: event.target.name,
        value: isChecked,
      },
    }); // Call Formik's handleChange to update form values
    handleChange({
      target: {
        name: "workEndDate",
        value: isChecked ? "" : updatedValues.workEndDate,
      },
    }); // Call Formik's handleChange to update "Worked Till" date field
  };

  const handleCompanyNameChange = (e) => {
    let { value } = e.target;
    // Remove extra spaces
    value = value.replace(/\s+/g, " ");
    // Ensure it doesn't start with a space
    if (value.startsWith(" ")) {
      value = value.slice(1);
    }
    // Ensure only one space after each character
    value = value.replace(/(\s)(?=\s+)/g, "");
    // Restrict the length to 50 characters
    value = value.slice(0, 50);
    // Remove any characters that are not letters, numbers, spaces, '/', or '.'
    value = value.replace(/[^A-Za-z0-9\s\/.]/g, "");
    // Update the state with the modified value
    handleChange({
      target: {
        name: "companyname",
        value,
      },
    });
  };

  const handleDesignationChange = (e) => {
    let { value } = e.target;
    // Remove extra spaces
    value = value.replace(/\s+/g, " ");
    // Ensure it doesn't start with a space
    if (value.startsWith(" ")) {
      value = value.slice(1);
    }
    // Ensure only one space after each character
    value = value.replace(/(\s)(?=\s+)/g, "");
    // Restrict the length to 30 characters
    value = value.slice(0, 30);
    // Remove any characters that are not letters or spaces
    value = value.replace(/[^A-Za-z\s]/g, "");

    // Update the state with the modified value
    handleChange({
      target: {
        name: "designation",
        value,
      },
    });
  };

  const handleDescChange = (e) => {
    let { value } = e.target;
    // Remove extra spaces
    // value = value.replace(/\s+/g, ' ');
    // Ensure it doesn't start with a space
    if (value.startsWith(" ")) {
      value = value.slice(1);
    }
    // Ensure only one space after each character
    value = value.replace(/(\s)(?=\s+)/g, "");
    // Allow only one newline character after each word
    value = value.replace(/(\s*\n){2,}/g, "\n");

    // Handle pressing Enter key
    value = value.replace(/(\n\n+)/g, "\n");
    // Restrict the length to 1000 characters
    value = value.slice(0, 1000);
    // Remove any characters that are not letters, numbers, spaces, '/', or '.'
    value = value.replace(/[^A-Za-z0-9\s\/.]/g, "");
    // Update the state with the modified value
    handleChange({
      target: {
        name: "jobDesc",
        value,
      },
    });
  };

  React.useEffect(() => {
    handleChange({
      target: {
        name: "skillUsedInWork",
        value: selectedWorkSkills,
      },
    });
  }, [selectedWorkSkills]);

  // State variables to track focus or click on input fields
  const [focusedInput, setFocusedInput] = useState("");
  const [clickedInput, setClickedInput] = useState("");

  // Event handler for focusing on input fields
  const handleInputFocus = (fieldName) => {
    setFocusedInput(fieldName);
  };

  // Event handler for clicking on input fields
  const handleInputClick = (fieldName) => {
    setClickedInput(fieldName);
  };

  // Check if the current input field is focused or clicked
  const isFieldFocusedOrClicked = (fieldName) =>
    focusedInput === fieldName || clickedInput === fieldName;

  return (
    <div className={Styles.profile_input_section}>
      <div className={Styles.profile_section_row}>
        <div className={Styles.profile_element_company_name}>
          <Field
            sx={{
              width: "100%",
            }}
            as={TextField}
            id="outlined-basic"
            label="Company Name *"
            variant="outlined"
            name="companyname"
            onChange={handleCompanyNameChange}
            onBlur={handleBlur}
            onFocus={() => handleInputFocus("companyname")}
            onClick={() => handleInputClick("companyname")}
            error={touched.companyname && !!errors.companyname}
            helperText={touched.companyname && errors.companyname}
          />
        </div>
      </div>
      <div className={Styles.profile_section_row}>
        <div className={Styles.profile_element}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth error={touched.empType && !!errors.empType}>
              <InputLabel id="demo-simple-select-label">
                Employment Type *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Employment Type *"
                name="empType"
                value={values.empType}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={() => handleInputFocus("empType")}
                onClick={() => handleInputClick("empType")}
              >
                <MenuItem value={"Internship"}>Internship</MenuItem>
                <MenuItem value={"Part-Time"}>Part-Time</MenuItem>
                <MenuItem value={"Full-Time"}>Full-Time</MenuItem>
              </Select>
              <FormHelperText style={{ color: "#d32f2f" }}>
                {touched.empType  &&
                  errors.empType}
              </FormHelperText>
            </FormControl>
          </Box>
        </div>
        <div className={Styles.profile_element}>
          <Box component="form" autoComplete="off">
            <Field
              fullWidth
              as={TextField}
              id="outlined-basic"
              label="Designation *"
              name="designation"
              variant="outlined"
              onChange={handleDesignationChange}
              onBlur={handleBlur}
              onFocus={() => handleInputFocus("designation")}
              onClick={() => handleInputClick("designation")}
              error={touched.designation && !!errors.designation}
              helperText={touched.designation && errors.designation}
              sx={{
                borderRadius: "8px",

                backgroundColor: "#FFFFFF",
                width: "100%",
              }}
            />
          </Box>
        </div>
      </div>
      <div className={Styles.profile_section_row}>
        <div className={Styles.profile_element}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Field
              name="workStartDate"
              as={TextField}
              type="date"
              fullWidth
              label="Joining Date *"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.workStartDate && !!errors.workStartDate}
              helperText={touched.workStartDate && errors.workStartDate}
              sx={{
                borderRadius: "8px",

                backgroundColor: "#FFFFFF",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </LocalizationProvider>
        </div>

        <div className={Styles.profile_element}>
          <Field
            name="workEndDate"
            as={TextField}
            type="date"
            fullWidth
            label="Worked Till *"
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              !values.isWorking && touched.workEndDate && !!errors.workEndDate
            }
            helperText={
              !values.isWorking && touched.workEndDate && errors.workEndDate
            }
            sx={{
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
            disabled={values.isWorking}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              checked={values.isWorking ? true : false}
              label="Currently Working"
              name="isWorking"
              onChange={handleCheckboxChange} // Use custom handler for checkbox change
            />
          </FormGroup>
        </div>
      </div>
      <div className={Styles.profile_section_row}>
        <Field
          fullWidth
          name="jobDesc"
          rows={4}
          multiline
          as={TextField}
          id="outlined-basic"
          label="Job description *"
          variant="outlined"
          onChange={handleDescChange}
          onBlur={handleBlur}
          onFocus={() => handleInputFocus("jobDesc")}
          onClick={() => handleInputClick("jobDesc")}
          error={touched.jobDesc && !!errors.jobDesc}
          helperText={touched.jobDesc && errors.jobDesc}
          sx={{
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
          }}
        />
      </div>
      <div className={WorkStyles.profile_section_work_skill_container}>
        <h1 className={WorkStyles.skill_required_head}>Skills Used</h1>
        <div className={WorkStyles.skill_selection_container}>
          <SkillSelection
            selectedWorkSkills={selectedWorkSkills}
            setSelectedWorkSkills={setSelectedWorkSkills}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            name="skillUsedInWork"
          />
        </div>
      </div>
    </div>
  );
}

export default AddWorkExperience;
