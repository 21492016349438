import Home from "./Dashboard/Home";
import Headers from "../Headers";
import Profile_page from "./../Profile/Profile_page";

function ProfileAccount() {
  

  return (
    <div>
      <Headers />
      <Home contentdes= {<Profile_page />} />
    </div>
  );
}

export default ProfileAccount;
