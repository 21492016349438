import Styles from "./details.module.css";
import TickIcon from "../../../assets/tick_Icon.svg";
import crossLanguageIcon from "../../../assets/cross_language_icon.svg";
import tickLanguageIcon from "../../../assets/tick_language_icon.svg";

export const DetailsViewMode = ({ personalDetails, isOTPValidated }) => {

  return (
    <>
      <div>
        <div className={Styles.details_input_container}>
          <div>
            <div className={Styles.details_data_wrap_about}>
              <h1 className={Styles.details_data_header}>About You</h1>
              <p style={{wordWrap:'break-word'}} >{personalDetails.aboutYou === '' ? '...Pending' : personalDetails.aboutYou }</p>
            </div>
          </div>
          <div className={Styles.details_input_wrapper}>
            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>First Name</h1>
              <p>{personalDetails.firstname === '' ? '...Pending' : personalDetails.firstname}</p>
            </div>
            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header} >Last Name</h1>
              <p>{personalDetails.lastname === '' ? '...Pending' : personalDetails.lastname}</p>
            </div>
          </div>
          <div className={Styles.details_input_wrapper}>
            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>Gender</h1>
              <p>{personalDetails.gender === '' ? '...Pending' : personalDetails.gender}</p>
            </div>
            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>Date of Birth</h1>
              <p>{personalDetails.dob === '' ? '...Pending' : personalDetails.dob}</p>
            </div>
          </div>
          <div className={Styles.details_input_wrapper}>
            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>Email ID</h1>
              <div className={Styles.details_verify_wrap}>
                <p>{personalDetails.email}</p>
                <img
                  className={Styles.verified_tick}
                  src={TickIcon}
                  alt="tick"
                />
              </div>
            </div>
            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>Phone Number</h1>
              <div className={Styles.details_verify_wrap}>
                <p>{personalDetails.phoneNumber}</p>
                {isOTPValidated && (
                  <img
                    className={Styles.verified_tick}
                    src={TickIcon}
                    alt="tick"
                  />
                )}
              </div>
            </div>
          </div>
          <div className={Styles.details_input_wrapper}>
            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>Nationality</h1>
              <p>{personalDetails.nationality === '' ? '...Pending' : personalDetails.nationality}</p>
            </div>

            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>State</h1>
              <p>{personalDetails.state === '' ? '...Pending' : personalDetails.state}</p>
            </div>
          </div>
          <div className={Styles.details_input_wrapper}>
            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>City</h1>
              <p>{personalDetails.city === '' ? '...Pending' : personalDetails.city}</p>
            </div>

            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>Address</h1>
              <p>{personalDetails.permanentAddress === '' ? '...Pending' : personalDetails.permanentAddress}</p>
            </div>
          </div>
          <div className={Styles.details_input_wrapper}>
            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>Pin Code</h1>
              <p>{personalDetails.pinCode === '' ? '...Pending' : personalDetails.pinCode}</p>
            </div>
            <div className={Styles.details_data_wrap_mob}>
              <h1 className={Styles.details_data_header}>Linkedin Profile URL</h1>
              <p>{personalDetails.linkedinlink === '' ? '...Pending' : personalDetails.linkedinlink}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <p className={Styles.language_title}>Languages</p>
        <div className={Styles.language_lists}>
          <div className={Styles.language_data}>
            <h1>Language</h1>
            {personalDetails.languages.map((item, index) => (
              <div key={index}>
                <p className={Styles.language_detail}>{item.language}</p>
              </div>
            ))}
          </div>
          <div className={Styles.language_data}>
            <h1>Proficiency</h1>
            {personalDetails.languages.map((item, index) => (
              <div key={index}>
                <p className={Styles.language_detail}>{item.proficiency}</p>
              </div>
            ))}
          </div>
          <hr />
          <div className={Styles.language_data_mob}>
            <h1>Read</h1>
            {personalDetails.languages.map((item, index) => (
              <div key={index}>
                <p className={Styles.language_detail}>
                  {item.skills.read ? (
                    <img src={tickLanguageIcon} alt="Yes" />
                  ) : (
                    <img src={crossLanguageIcon} alt="No" />
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className={Styles.language_data_mob}>
            <h1>Write</h1>
            {personalDetails.languages.map((item, index) => (
              <div key={index}>
                <p className={Styles.language_detail}>
                  {item.skills.write ? (
                    <img src={tickLanguageIcon} alt="Yes" />
                  ) : (
                    <img src={crossLanguageIcon} alt="No" />
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className={Styles.language_data_mob}>
            <h1>Speak</h1>
            {personalDetails.languages.map((item, index) => (
              <div key={index}>
                <p className={Styles.language_detail}>
                  {item.skills.speak ? (
                    <img src={tickLanguageIcon} alt="Yes" />
                  ) : (
                    <img src={crossLanguageIcon} alt="No" />
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <div className={Styles.language_section}>
        <p className={Styles.language_title}>Languages</p>
        <div className={Styles.language_container}>
          <div className={Styles.language_head}>
            <h1 className={Styles.language_head_title}>Language</h1>
            <h1 className={Styles.language_head_title}>Proficiency</h1>
            <h1 className={Styles.language_head_title_mob}>Read</h1>
            <h1 className={Styles.language_head_title_mob}>Write</h1>
            <h1 className={Styles.language_head_title_mob}>Speak</h1>
          </div>
          <div className={Styles.language_lists}>
            <div className={Styles.language_data}>
              {personalDetails.languages.map((item, index) => (
                <div key={index}>
                  <p className={Styles.language_detail}>{item.language}</p>
                </div>
              ))}
            </div>
            <div className={Styles.language_data}>
              {personalDetails.languages.map((item, index) => (
                <div key={index}>
                  <p className={Styles.language_detail}>{item.proficiency}</p>
                </div>
              ))}
            </div>
            <div className={Styles.language_data_mob}>
              {personalDetails.languages.map((item, index) => (
                <div key={index}>
                  <p className={Styles.language_detail}>
                    {item.skills.read ? (
                      <img src={tickLanguageIcon} alt="Yes" />
                    ) : (
                      <img src={crossLanguageIcon} alt="No" />
                    )}
                  </p>
                </div>
              ))}
            </div>
            <div className={Styles.language_data_mob}>
              {personalDetails.languages.map((item, index) => (
                <div key={index}>
                  <p className={Styles.language_detail}>
                    {item.skills.write ? (
                      <img src={tickLanguageIcon} alt="Yes" />
                    ) : (
                      <img src={crossLanguageIcon} alt="No" />
                    )}
                  </p>
                </div>
              ))}
            </div>
            <div className={Styles.language_data_mob}>
              {personalDetails.languages.map((item, index) => (
                <div key={index}>
                  <p className={Styles.language_detail}>
                    {item.skills.speak ? (
                      <img src={tickLanguageIcon} alt="Yes" />
                    ) : (
                      <img src={crossLanguageIcon} alt="No" />
                    )}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
