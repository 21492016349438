import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Check_Icon from "../../../assets/Check_Icon.svg";
import Warning from "../../../assets/Warning.svg";
import { Link } from "react-router-dom";

export default function AccordianUsage(props) {
  const { activityList, progressDetails } = props;

  let progress = 0;
  let completedModules = 0;
  let courseUrl = 0;
  let moduleCompletePercentage = "";
  if (progressDetails !== null && progressDetails !== undefined) {
    progress = progressDetails.moduleCompletePercentage;
    completedModules = progressDetails.module;
    courseUrl = progressDetails.resumeUrl;
    moduleCompletePercentage = progressDetails.moduleCompletePercentage;
  }

  return (
    <div>
      <Accordion className="accordian-container">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="dropdown-icon" />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Past Activites</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {activityList.map((eachList) => {
            return (
              (eachList.containerState === "COMPLETED" ||
                eachList.containerState === "Deadline Exceeded" ||
                eachList.containerState === "Pass" ||
                eachList.containerState === "submitted" ||
                eachList.containerState === "completed") && (
                <div>
                  <div
                    className="completed-activites-section"
                    hidden={
                      !(
                        eachList.containerState === "Pass" ||
                        eachList.containerState === "COMPLETED" ||
                        eachList.containerState === "submitted" ||
                        eachList.containerState === "completed"
                      )
                    }
                  >
                    {eachList.subtitle === "Quiz" && (
                      <div
                        style={
                          window.innerWidth > 576
                            ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }
                            : {
                              textAlign: "left",
                            }
                        }
                      >
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details">
                            <p className="activity-subtitle"> TEST CLEARED </p>
                            <h1 className="activity-category">
                              {eachList.title}
                            </h1>
                          </div>
                        </div>
                        <div className="button-section">
                          <Link
                            to="/dashboard/assessment-report"
                            style={{ textDecoration: "none" }}
                          >
                            <button
                              className="activity-btn"
                              style={
                                window.innerWidth > 576
                                  ? { width: 177 }
                                  : { width: "100%" }
                              }
                            >
                              <p
                                style={{
                                  alignSelf: "center",
                                  color: "#0B6AEA",
                                  fontWeight: "bold",
                                  margin: "auto",
                                }}
                              >
                                {" "}
                                View Report
                              </p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                    {eachList.subtitle === "Default course" && (
                      <div
                        style={
                          window.innerWidth > 576
                            ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }
                            : {
                              textAlign: "left",
                            }
                        }
                      >
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details">
                            <p className="activity-subtitle">
                              COURSE COMPLETED
                            </p>
                            <h1 className="activity-category">
                              {eachList.title}
                            </h1>
                          </div>
                        </div>
                        <div className="button-section">
                          <Link
                            to="/dashboard/Describing"
                            state={{
                              courseUrl: courseUrl,
                              moduleCompletePercentage:
                                moduleCompletePercentage,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <button
                              className="activity-btn"
                              style={
                                window.innerWidth > 576
                                  ? { width: 177 }
                                  : { width: "100%" }
                              }
                            >
                              {" "}
                              <p
                                style={{
                                  alignSelf: "center",
                                  color: "#0B6AEA",
                                  fontWeight: "bold",
                                  margin: "auto",
                                }}
                              >
                                Re Watch Course
                              </p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
 {eachList.subtitle === "Course" && (
                      <div
                        style={
                          window.innerWidth > 576
                            ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }
                            : {
                              textAlign: "left",
                            }
                        }
                      >
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details">
                            <p className="activity-subtitle">
                              COURSE COMPLETED
                            </p>
                            <h1 className="activity-category">
                              {eachList.title}
                            </h1>
                          </div>
                        </div>
                        <div className="button-section">
                          <Link
                            to="/dashboard/Describing"
                            state={{
                              courseUrl: courseUrl,
                              moduleCompletePercentage:
                                moduleCompletePercentage,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <button
                              className="activity-btn"
                              style={
                                window.innerWidth > 576
                                  ? { width: 177 }
                                  : { width: "100%" }
                              }
                            >
                              {" "}
                              <p
                                style={{
                                  alignSelf: "center",
                                  color: "#0B6AEA",
                                  fontWeight: "bold",
                                  margin: "auto",
                                }}
                              >
                                Re Watch Course
                              </p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                    {eachList.subtitle === "Assignment" && (
                      <div
                        style={
                          window.innerWidth > 576
                            ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }
                            : {
                              textAlign: "left",
                            }
                        }
                      >
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details">
                            <p className="activity-subtitle">
                              ASSIGNMENT COMPLETED
                            </p>
                            <h1 className="activity-category">
                              {eachList.title}
                            </h1>
                          </div>
                        </div>
                        <div className="button-section">
                          <Link
                            to="/dashboard/assign"
                            style={{ textDecoration: "none" }}
                          >
                            <button
                              className="activity-btn"
                              style={
                                window.innerWidth > 576
                                  ? { width: 177 }
                                  : { width: "100%" }
                              }
                            >
                              <p
                                style={{
                                  alignSelf: "center",
                                  color: "#0B6AEA",
                                  fontWeight: "bold",
                                  margin: "auto",
                                }}
                              >
                                View Submission
                              </p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                    {eachList.subtitle === "ECE -1" && (
                      <div
                        style={
                          window.innerWidth > 576
                            ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }
                            : {
                              textAlign: "left",
                            }
                        }
                      >
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details">
                            <p className="activity-subtitle">
                              {" "}
                              ASSESSMENT CLEARED{" "}
                            </p>
                            <h1 className="activity-category">
                              {eachList.title}
                            </h1>
                          </div>
                        </div>
                        <div className="button-section">
                          <Link to='/dashboard/report' style={{ textDecoration: "none" }} state={{ courseId: eachList.id }}>
                            <button className="activity-btn" style={{ width: 177 }}>
                              <p style={{
                                alignSelf: 'center',
                                color: '#0B6AEA',
                                fontWeight: 'bold',
                                margin: 'auto'
                              }} > View Report</p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                    {eachList.subtitle === "ECE-2" && (
                      <div style={{ display: "inline-flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details" >
                            <p className="activity-subtitle"> ASSESSMENT CLEARED </p>
                            <h1 className="activity-category">{eachList.title}</h1>
                          </div>
                        </div>
                        <div className="button-section">
                          <Link to='/dashboard/report' style={{ textDecoration: "none" }} state={{ courseId: eachList.id }}>
                            <button className="activity-btn" style={{ width: 177 }}>
                              <p style={{
                                alignSelf: 'center',
                                color: '#0B6AEA',
                                fontWeight: 'bold',
                                margin: 'auto'
                              }} > View Report</p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                    {eachList.subtitle === "CS" && (
                      <div style={{ display: "inline-flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details" >
                            <p className="activity-subtitle"> ASSESSMENT CLEARED </p>
                            <h1 className="activity-category">{eachList.title}</h1>
                          </div>
                        </div>
                        <div className="button-section">
                          <Link to='/dashboard/report' style={{ textDecoration: "none" }} state={{ courseId: eachList.id }}>
                            <button className="activity-btn" style={{ width: 177 }}>
                              <p style={{
                                alignSelf: 'center',
                                color: '#0B6AEA',
                                fontWeight: 'bold',
                                margin: 'auto'
                              }} > View Report</p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                    {eachList.subtitle === "ME" && (
                      <div style={{ display: "inline-flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details" >
                            <p className="activity-subtitle"> ASSESSMENT CLEARED </p>
                            <h1 className="activity-category">{eachList.title}</h1>
                          </div>
                        </div>
                        <div className="button-section">
                          <Link to='/dashboard/report' style={{ textDecoration: "none" }} state={{ courseId: eachList.id }}>
                            <button className="activity-btn" style={{ width: 177 }}>
                              <p style={{
                                alignSelf: 'center',
                                color: '#0B6AEA',
                                fontWeight: 'bold',
                                margin: 'auto'
                              }} > View Report</p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                    {eachList.subtitle === "IT" && (
                      <div style={{ display: "inline-flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details" >
                            <p className="activity-subtitle"> ASSESSMENT CLEARED </p>
                            <h1 className="activity-category">{eachList.title}</h1>
                          </div>
                        </div>
                        <div className="button-section">
                          <Link to='/dashboard/report' style={{ textDecoration: "none" }} state={{ courseId: eachList.id }}>
                            <button className="activity-btn" style={{ width: 177 }}>
                              <p style={{
                                alignSelf: 'center',
                                color: '#0B6AEA',
                                fontWeight: 'bold',
                                margin: 'auto'
                              }} > View Report</p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                    {eachList.subtitle === "EE" && (
                      <div style={{ display: "inline-flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details" >
                            <p className="activity-subtitle"> ASSESSMENT CLEARED </p>
                            <h1 className="activity-category">{eachList.title}</h1>
                          </div>
                        </div>
                        <div className="button-section">
                          <Link to='/dashboard/report' style={{ textDecoration: "none" }} state={{ courseId: eachList.id }}>
                            <button className="activity-btn" style={{ width: 177 }}>
                              <p style={{
                                alignSelf: 'center',
                                color: '#0B6AEA',
                                fontWeight: 'bold',
                                margin: 'auto'
                              }} > View Report</p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                    {eachList.subtitle === "survey" && (
                      <div style={{ display: "inline-flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details" >
                            <p className="activity-subtitle">SURVEY COMPLETED</p>
                            <h1 className="activity-category">{eachList.title}</h1>
                          </div>
                        </div>
                        {/* <div className="button-section">
                          <Link to="/dashboard/assign" style={{ textDecoration: "none" }}>
                            <buton className="activity-btn" style={{ width: 177 }}>
                              <p style={{
                                alignSelf: 'center',
                                color: '#0B6AEA',
                                fontWeight: 'bold',
                                margin: 'auto'
                              }} >View Submission</p>
                            </buton>
                          </Link>
                        </div> */}
                      </div>
                    )}
                    {eachList.subtitle === "Survey1" && (
                      <div style={{ display: "inline-flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div className="icon-text-section">
                          <div className="icon-section">
                            <img src={Check_Icon} />
                          </div>
                          <div className="completed-details" >
                            <p className="activity-subtitle">SURVEY COMPLETED</p>
                            <h1 className="activity-category">{eachList.title}</h1>
                          </div>
                        </div>
                        {/* <div className="button-section">
                          <Link to="/dashboard/assign" style={{ textDecoration: "none" }}>
                            <buton className="activity-btn" style={{ width: 177 }}>
                              <p style={{
                                alignSelf: 'center',
                                color: '#0B6AEA',
                                fontWeight: 'bold',
                                margin: 'auto'
                              }} >View Submission</p>
                            </buton>
                          </Link>
                        </div> */}
                      </div>
                    )}
                  </div>
                  <div
                    className="completed-activites-section"
                    hidden={!(eachList.containerState === "Deadline Exceeded")}
                  >
                    <div className="icon-text-section">
                      <div className="icon-section">
                        <img src={Warning} />
                      </div>
                      <div className="completed-details">
                        <p className="activity-subtitle">DEADLINE EXCEEDED</p>
                        <h1 className="activity-category">{eachList.title}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
