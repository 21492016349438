import {
  TextField,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Select,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import Box from "@mui/material/Box";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Styles from "./details.module.css";
import TickIcon from "../../../assets/tick_Icon.svg";
import deleteIcon from "../../../assets/delete.svg";
import IndiaFlag from "../../../assets/India.svg";

import Autocomplete from "@mui/material/Autocomplete";
import addIcon from "../../../assets/add.svg";
import { DetailsViewMode } from "./DetailsViewMode";
import { min, set, subYears } from "date-fns";

const todayDOB = subYears(new Date(), 15);

const countryCode = [
  { code: "+91", name: "India", flag: IndiaFlag },
  // { code: '+1', name: 'United States', flag: IndiaFlag },
  // { code: '+44', name: 'United Kingdom', flag: IndiaFlag },
];

const genders = ["Male", "Female", "Transgender", "Prefer not to say"];

const nationality = [{ label: "Indian", value: "Indian" }];

const states = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Andaman and Nicobar Islands", value: "Andaman and Nicobar" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Chandigarh", value: "Chandigarh" },
  {
    label: "Dadra and Nagar Haveli and Daman and Diu",
    value: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Ladakh", value: "Ladakh" },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "New Delhi", value: "New Delhi" },
  { label: "Odisha", value: "Odisha" },
  { label: "Puducherry", value: "Puducherry" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
];

const listProficiency = [
  { label: "Basic", value: "Basic" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Fluent", value: "Fluent" },
  { label: "Native", value: "Native" },
];

const listOfLanguages = [
  { label: "English", value: "English" },
  { label: "Hindi", value: "Hindi" },
  { label: "Bengali", value: "Bengali" },
  { label: "Marathi", value: "Marathi" },
  { label: "Telugu", value: "Telugu" },
  { label: "Tamil", value: "Tamil" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Urdu", value: "Urdu" },
  { label: "Kannada", value: "Kannada" },
  { label: "Odia", value: "Odia" },
  { label: "Malayalam", value: "Malayalam" },
];

const validationSchema = Yup.object().shape({
  aboutYou: Yup.string()
    .required("About you is required")
    .max(500, "Maximum 500 characters allowed"),
  firstname: Yup.string()
    .required("First name is required")
    .matches(
      /^[A-Za-z]+(?: [A-Za-z]+)?$/,
      "First name must contain only letters and can have only one space"
    )
    .max(25, "First name must be at most 25 characters"),
  lastname: Yup.string()
    .required("Last name is required")
    .matches(
      /^[A-Za-z]+(?: [A-Za-z]+)?$/,
      "Last name must contain only letters and can have only one space"
    )
    .max(25, "Last name must be at most 25 characters"),
  gender: Yup.string().required("Gender is required"),
  dob: Yup.date()
    .max(new Date(todayDOB), "Age should be minimum of 15 years")
    .required("Date of birth is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .min(10, "Phone Number should be atleast 10 digits")
    .required("Phone number is required"),
  nationality: Yup.string().required("Nationality is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string()
    .required("City is required")
    .min(3, "City must be at least 3 characters")
    .max(35, "City must be at most 35 characters"),
  permanentAddress: Yup.string()
    .min(5, "Address must be at least 5 characters")

    .required("Address is required"),
  pinCode: Yup.string()
    .matches(/^\d{6}$/, "Invalid PIN code")
    .min(6, "Pin code must be 6 digits")
    .required("Pin code is required"),
  linkedinlink: Yup.string()
    .matches(/^https/, 'Linkedin URL must start with "https"')
    .matches(/^https:\/\/.*linkedin\.com.*$/, "Invalid LinkedIn profile URL")
    .max(51, "Maximum 50 characters allowed for linkedin URL")
    .required("LinkedIn profile URL is required"),
  languages: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required("Language is required"),
      proficiency: Yup.string().when("language", (language) =>
        language && language.length > 0
          ? Yup.string().required("Proficiency is required")
          : Yup.string()
      ),
      skills: Yup.object().test(
        "atLeastOneCheckbox",
        "Please select at least one option.",
        (value) => value.read || value.write || value.speak
      ),
    })
  ),
});

export const Details = ({
  editMode,
  setEditMode,
  personalDetails,
  setPersonalDetails,
  isOTPValidated,
  savePersonalToKeycloak,
  handleSendOTP,
  setProfileData,
  profileData,
  setEnteredNumber,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(countryCode[0].code);
  const [isSameNum, setSameNum] = useState(true);

  const [checkboxStates, setCheckboxStates] = useState([]);

  // Function to toggle checkbox state and update error visibility
  const handleCheckboxChange = (index, skill, checked) => {
    const updatedCheckboxStates = [...checkboxStates];
    updatedCheckboxStates[index] = {
      ...updatedCheckboxStates[index],
      [skill]: checked,
    };
    setCheckboxStates(updatedCheckboxStates);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };const handleSubmit = (values) => {
    // setPersonalDetails(values);
    savePersonalToKeycloak(values);
    // setEditMode(false);
    setEditMode(!editMode);
    //do something with the form data
    //do keycloak profile update
  };

  const handleChange = (e) => {
    setPersonalDetails({ ...personalDetails, [e.target.name]: e.target.value });
    if (e.target.name === "phoneNumber") {
 
      setEnteredNumber(e.target.value);
    }
  };

  return (
    <>
      {editMode ? (
        <Formik
          initialValues={{ ...personalDetails }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
          className={Styles.details_input_container}
        >
          {({
            errors,
            touched,
            values,
            setFieldValue,
            validateForm,
            setFieldTouched,
          }) => (
            <Form className={Styles.form_data}>
              <div>
                
                <Field
                  className={Styles.details_data_wrap_about}
                  name="aboutYou"
                  as={TextField}
                  sx={{ flexDirection: "column", wordWrap: "break-word" }}
                  fullWidth
                  rows={5}
                  multiline
                  label="About You *"
                  value={values.aboutYou}
                  error={touched.aboutYou && !!errors.aboutYou}
                  helperText={touched.aboutYou && errors.aboutYou}
                  inputProps={{
                    maxLength: 500,
                  }}
                  InputProps={{
                    endAdornment: (
                      <div style={{ width: "100%", textAlign: "end" }}>
                        <span className={Styles.details_about_you_count}>
                          {values.aboutYou.length}/{500}
                        </span>
                      </div>
                    ),
                  }}
                />
              </div>
              <div className={Styles.details_input_wrapper}>
                <div className={Styles.details_data_wrap}>
                  <Field
                    name="firstname"
                    as={TextField}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        /^[A-Za-z]*$/.test(inputValue) &&
                        inputValue.length <= 25
                      ) {
                        setFieldValue("firstname", inputValue);
                      }
                    }}
                  
                    fullWidth
                    label="First Name *"
                    values={values.firstname}
                    error={touched.firstname && !!errors.firstname}
                    helperText={touched.firstname && errors.firstname}
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                <div className={Styles.details_data_wrap}>
                  <Field
                    name="lastname"
                    as={TextField}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        /^[A-Za-z]*$/.test(inputValue) &&
                        inputValue.length <= 25
                      ) {
                        setFieldValue("lastname", inputValue);
                      }
                    }}
                    fullWidth
                    label="Last Name *"
                    values={values.lastname}
                    error={touched.lastname && !!errors.lastname}
                    helperText={touched.lastname && errors.lastname}
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
              </div>
              <div className={Styles.details_input_wrapper}>
                <div className={Styles.details_data_wrap}>
                  <Field
                    name="gender"
                    as={TextField}
                    select
                    fullWidth
                    label="Gender *"
                    value={values.gender}
                    
                    error={touched.gender && errors.gender}
                    sx={{
                      borderRadius: "8px",

                      backgroundColor: "#FFFFFF",
                    }}
                    helperText={touched.gender && errors.gender}
                  >
                    {genders.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
                <div className={Styles.details_data_wrap}>
                  <Field
                    name="dob"
                    as={TextField}
                    type="date"
                    fullWidth
                    label="Date of Birth *"
                    value={values.dob}
                    error={touched.dob && !!errors.dob}
                    helperText={touched.dob && errors.dob}
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>

              <div className={Styles.details_input_wrapper}>
                <div className={Styles.details_data_wrap}>
                  <Field
                    name="email"
                    as={TextField}
                    fullWidth
                    disabled={true}
                    label="Email *"
                    value={values.email}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                <div className={Styles.phone_number_wrap}>
                  <div className={Styles.details_data_wrap_phone}>
                    <Field
                      name="phoneNumber"
                      as={TextField}
                      fullWidth
                      label="Phone Number *"
                      value={values.phoneNumber}
                      sx={{
                        borderRadius: "8px",
                        backgroundColor: "#FFFFFF",
                      }}
                      onChange={(e) => {
                        let { value } = e.target;
                        // Remove non-numeric characters and ensure only digits are allowed
                        const numericValue = value.replace(/\D/g, "");
                        // Ensure the first digit is valid for a mobile phone number (6, 7, 8, or 9)
                        const sanitizedValue =
                          numericValue.startsWith("6") ||
                          numericValue.startsWith("7") ||
                          numericValue.startsWith("8") ||
                          numericValue.startsWith("9")
                            ? numericValue
                            : "";
                        // Ensure the length of the phone number is not more than 10 digits
                        const limitedValue = sanitizedValue.slice(0, 10);

                        // Update the value in the input field
                        e.target.value = limitedValue;

                        setSameNum(
                          personalDetails.phoneNumber === e.target.value
                        );

                        setPersonalDetails({
                          ...personalDetails,
                          phoneNumber: limitedValue,
                        });
                        if (value.length < 11) {
                          handleChange(e);
                          values.phoneNumber = limitedValue;
                          setFieldValue("phoneNumber", limitedValue);
                        }
                      }}
                      error={errors.phoneNumber}
                      helperText={errors.phoneNumber}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+91</InputAdornment>
                        ),
                        endAdornment: (
                          <div>
                            {isOTPValidated && isSameNum && (
                              <img
                                className={Styles.verified_tick}
                                src={TickIcon}
                                alt="tick"
                              />
                            )}
                          </div>
                        ),
                      }}
                    />

                    {!isOTPValidated ||
                      (!isSameNum &&
                        personalDetails.phoneNumber.length === 10 && (
                          <button
                            type="button"
                            onClick={handleSendOTP}
                            className={Styles.verify_button}
                            disabled={errors.phoneNumber}
                          >
                            Verify
                          </button>
                        ))}
                  </div>
                  {!isOTPValidated && (
                    <p className={Styles.otp_message}>
                      We will send a OTP in a text message to verify your number
                    </p>
                  )}
                </div>
              </div>
              <div className={Styles.details_input_wrapper}>
                <div className={Styles.details_data_wrap}>
                  <Field
                    name="nationality"
                    as={TextField}
                    select
                    fullWidth
                    label="Nationality *"
                    value={values.nationality}
                    error={touched.nationality && !!errors.nationality}
                    helperText={touched.nationality && errors.nationality}
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    {nationality.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
                <div className={Styles.details_data_wrap}>
                  <Field
                    name="state"
                    as={TextField}
                    select
                    fullWidth
                    label="State/UT *"
                    value={values.state}
                    error={touched.state && !!errors.state}
                    helperText={touched.state && errors.state}
                    sx={{
                      borderRadius: "8px",

                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    {states.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>

            
                </div>
              </div>

              <div className={Styles.details_input_wrapper}>
                <div className={Styles.details_data_wrap}>
    
                  <Field
                    as={TextField}
                    label="City *"
                    name="city"
                    error={errors.city && touched.city}
                    helperText={touched.city && errors.city}
                    onChange={(e) => {
                      let inputValue = e.target.value;
                      inputValue = inputValue.replace(/\s+/g, " ");
                      // Ensure it doesn't start with a space
                      if (inputValue.startsWith(" ")) {
                        inputValue = inputValue.slice(1);
                      }

                      // Ensure only one space after each character
                      inputValue = inputValue.replace(/(\s)(?=\s+)/g, "");

                      if (
                        /^[A-Za-z ]*$/.test(inputValue) &&
                        inputValue.length <= 35
                      ) {
                        setFieldValue("city", inputValue);
                      }
                    }}
                  />
                </div>

                <div className={Styles.details_data_wrap}>
                  <Box sx={{ borderRadius: "0px" }}>
                    <Field
                      name="permanentAddress"
                      as={TextField}
                      fullWidth
                      label="Address *"
                      value={values.permanentAddress}
                      multiline
                      rows={3}
                      error={
                        errors.permanentAddress && touched.permanentAddress
                      }
                      helperText={
                        touched.permanentAddress && errors.permanentAddress
                      }
                      onChange={(e) => {
                        let { value } = e.target;
                        // Remove extra spaces
                        value = value.replace(/\s+/g, " ");
                        // Ensure it doesn't start with a space
                        if (value.startsWith(" ")) {
                          value = value.slice(1);
                        }
                        // Ensure only one space after each character
                        value = value.replace(/(\s)(?=\s+)/g, "");
                        // Restrict the length to 50 characters
                        value = value.slice(0, 50);
                        // Remove any characters that are not letters, numbers, spaces, '/', or '.'
                        value = value.replace(/[^A-Za-z0-9\s\/.]/g, "");
                        setFieldValue("permanentAddress", value);
                      }}
                      sx={{
                        borderRadius: "32px",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  </Box>
                </div>
              </div>

              <div className={Styles.details_input_wrapper}>
                <div className={Styles.details_data_wrap}>
                  <Field
                    name="pinCode"
                    as={TextField}
                    fullWidth
                    label="Pin Code *"
                    value={values.pinCode}
                    onChange={(e) => {
                      const { value } = e.target;
                      let checkNums = value.replace(/\D/g, "");
                      if (checkNums.startsWith('0')) {
                        checkNums = checkNums.substring(1);
                    }
                    
                      const limitnums = checkNums.slice(0, 6);
                      values.pinCode = limitnums;
                      personalDetails.pinCode = limitnums;
                      setFieldValue("pinCode", limitnums);
                      if (value.length < 7 && value.startsWith("0")) {
                        handleChange(e);
                      }
                    }}
                    error={errors.pinCode && touched.pinCode}
                    helperText={touched.pinCode && errors.pinCode}
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                <div className={Styles.details_data_wrap}>
                  <Field
                    name="linkedinlink"
                    as={TextField}
                    fullWidth
                    label="LinkedIn Profile URL *"
                    value={values.linkedinlink}
                    error={touched.linkedinlink && !!errors.linkedinlink}
                    helperText={touched.linkedinlink && errors.linkedinlink}
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
              </div>
              <div>
                <p className={Styles.language_title}>Languages</p>
                <p
                  style={{
                    color: "rgb(105, 112, 119)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    marginTop: "-12px",
                  }}
                >
                  Atleast 1 language should be added
                </p>
              </div>
              <FieldArray name="languages">
                {({ push, remove }) => {
                  if (
                    values.languages.length === 0 &&
                    values.languages.length < 1
                  ) {
                    push({
                      language: "",
                      proficiency: "",
                      skills: {
                        read: false,
                        write: false,
                        speak: true,
                      },
                    });
                  }

                  return (
                    <>
                      {values.languages.map((language, index) => {
                        return (
                          <div key={index}>
                            <div className={Styles.language_section_one}>
                              <div className={Styles.language_selector}>
                                <Field
                                  name={`languages[${index}].language`}
                                  as={TextField}
                                  select
                                  fullWidth
                                  label="Language"
                                  /*   error={
                                  touched.languages &&
                                  touched.languages[index] &&
                                  !!errors.languages?.[index]?.language
                                } */
                                  error={
                                    touched.languages &&
                                    touched.languages[index]?.language &&
                                    errors.languages?.[index]?.language
                                  }
                                  helperText={
                                    touched.languages &&
                                    touched.languages[index]?.language &&
                                    errors.languages?.[index]?.language
                                  }
                                  onBlur={() => {
                                    setFieldTouched(
                                      `languages[${index}].proficiency`,
                                      false
                                    );
                                    setFieldTouched(
                                      `languages[${index}].language`,
                                      true
                                    );
                                  }}
                                >
                                  {listOfLanguages.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                      disabled={values.languages.some(
                                        (lang) =>
                                          lang.language === option.value &&
                                          lang.language !==
                                            values.languages[index].language
                                      )}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </div>
                              <div className={Styles.proficiency_selector}>
                                <Field
                                  name={`languages[${index}].proficiency`}
                                  as={TextField}
                                  select
                                  fullWidth
                                  label="Proficiency"
                                  disabled={
                                    values.languages[index].language === ""
                                  }
                                  //  error={errors.language[index] ? errors.languages[index].proficiency : false}
                                  error={
                                    touched.languages &&
                                    touched.languages[index]?.proficiency &&
                                    !!errors.languages?.[index]?.proficiency
                                  }
                                  /* helperText={
                                  errors.languages &&
                                  errors.languages[index]?.proficiency &&
                                  errors.languages?.[index]?.proficiency
                                } */

                                  helperText={
                                    touched.languages &&
                                    touched.languages[index]?.proficiency &&
                                    errors.languages?.[index]?.proficiency
                                  }

                                  // helperText={touched.language[index] ? touched.languages[index]?.proficiency : ''}
                                >
                                  {listProficiency.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </div>
                            </div>
                            <div className={Styles.language_section_two}>
                              <div className={Styles.language_select_wrap}>
                                <div className={Styles.language_checkbox}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size="small"
                                        validateOnBlur
                                        className={Styles.checkbox_box}
                                        checked={
                                          values.languages[index]?.skills.read
                                        }
                                        style ={{
                                          color: "#0B6AEA",
                                        }}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `languages[${index}].skills.read`,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    }
                                    className={Styles.checkbox_label}
                                    label="Read"
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size="small"
                                        validateOnBlur
                                        className={Styles.checkbox_box}
                                        checked={
                                          values.languages[index]?.skills.write
                                        }
                                        style ={{
                                          color: "#0B6AEA",
                                        }}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `languages[${index}].skills.write`,
                                            e.target.checked
                                          );

                                          handleCheckboxChange(
                                            index,
                                            "write",
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    }
                                    className={Styles.checkbox_label}
                                    label="Write"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size="small"
                                        validateOnBlur
                                        className={Styles.checkbox_box}
                                        checked={
                                          values.languages[index]?.skills.speak
                                        }
                                        style ={{
                                          color: "#0B6AEA",
                                        }}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `languages[${index}].skills.speak`,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    }
                                    className={Styles.checkbox_label}
                                    label="Speak"
                                  />
                                </div>
                                <div>
                                  {errors.languages &&
                                    errors.languages[index]?.skills && (
                                      <div className={Styles.checkbox_error}>
                                        {errors.languages[index].skills}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div
                                className={Styles.language_delete_button}
                                disabled={values.languages.length === 1}
                                onClick={() =>
                                  values.languages.length !== 1 && remove(index)
                                }
                              >
                                <p className={Styles.language_delete_para}>
                                  Delete
                                </p>
                                <img
                                  className={Styles.deleteIcon}
                                  src={deleteIcon}
                                  alt="delete"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className={Styles.language_add}>
                        {values.languages.length <= 2 && (
                          <div
                            className={Styles.language_add_button}
                            onClick={() =>
                              push({
                                language: "",
                                proficiency: "",
                                skills: {
                                  read: false,
                                  write: false,
                                  speak: true,
                                },
                              })
                            }
                          >
                            <p className={Styles.language_add_para}>
                              Add Language
                            </p>
                            <img
                              className={Styles.deleteIcon}
                              src={addIcon}
                              alt="add"
                            />
                          </div>
                        )}
                      </div>
                    </>
                  );
                }}
              </FieldArray>
              <div className={Styles.changes_button}>
                <button
                  type="submit"
                  disabled={!isSameNum}
                  className={Styles.save_button}
                >
                  <p>Save Changes</p>
                </button>
                <p
                  type="reset"
                  onClick={() => setEditMode(!editMode)}
                  className={Styles.discard_button}
                >
                  Discard Changes
                </p>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <DetailsViewMode
          personalDetails={personalDetails}
          isOTPValidated={isOTPValidated}
        />
      )}
    </>
  );
};
