import React from 'react';
import { Modal } from 'react-bootstrap';
import Tissue from "../../../assets/img/Tissue.svg";
import Warning_sign from "../../../assets/img/Warning_sign.svg";
import "./DeadLineExceeded.css";

const DeadLineExceededPage = ({ show, handleClose, errorMessage }) => {
    return (

        <Modal className="mt-5" show={show} >
            <div className='deadline_border'>
                <div className='deadline_outerlayer'>
                    <div className='deadline_tissuebox'>
                        <img src={Tissue} alt="" />
                    </div>
                    <div className='deadline_insidelayer'>
                        <div className='deadline_exceededword'>Deadline Exceeded</div>
                        <div className='deadline_warningbox'>
                            <div className='deadline_warningsign'>
                                <div className='deadline_warningimg'>
                                    <img src={Warning_sign} alt='' />
                                </div>
                            </div>
                            <div className='deadline_warningboxp'>The assignment deadline has been exceeded. You will not be able to submit the assignment anymore but, you can proceed to the next action item</div>
                        </div>
                    </div>
                    <button className='deadline_btn' onClick={handleClose} >
                        <div className='deadline_okaybtn' onClick={handleClose} >Okay</div>
                    </button>
                </div>
            </div>
        </Modal>

    );
};

export default DeadLineExceededPage;
