import Headers from "../../Headers";
import Home from "../Dashboard/Home";
import RoleRecommendationPage from "./RoleRecommendationPage";
const RoleRecommendation = () => {
    const content = <RoleRecommendationPage />;
    return (
        <div>
            <Headers />
            <Home contentdes={content} />
        </div>
    );
}

export default RoleRecommendation;
