  import React, {  useEffect } from "react";
// import custom_style from '../assets/css/style.css';
// import owl_css from '../assets/css/owl.carousel.min.css';

import axios from 'axios';
function Headers()  {
  
    /*
    useEffect(() => {
        const styleURL1 = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css';
        axios.get(styleURL1).then((response) => {
          const style1 = document.createElement('style');
          style1.rel = 'stylesheet';
          style1.integrity = 'sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9';
          style1.crossOrigin = 'anonymous';
          style1.innerHTML = response.data;
          document.body.appendChild(style1);
        });
        return () => {
            // Clean up the script when the component unmounts
            const style1 = document.querySelector(`style[src="${styleURL1}"]`);
            if (style1) {
              document.body.removeChild(style1);
            }
          };
        }, []);

            useEffect(() => {
        const styleURL1 = 'https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css';
        axios.get(styleURL1).then((response) => {
          const style1 = document.createElement('style');
          style1.rel = 'stylesheet';
          style1.innerHTML = response.data;
          document.body.appendChild(style1);
        });
        return () => {
            // Clean up the script when the component unmounts
            const style1 = document.querySelector(`style[src="${styleURL1}"]`);
            if (style1) {
              document.body.removeChild(style1);
            }
          };
        }, []);*/
       
    return (
      
        
<section>
<meta charSet="utf-8"/>
<meta name="viewport" content="width=device-width, initial-scale=1"/>
<title>CREAMCOLLAR : Together We Grow</title>
{/* <script src="https://code.jquery.com/jquery-3.7.0.js" integrity="sha256-JlqSTELeR4TLqP0OG9dxM7yDPqX1ox/HfgiSLBj8+kM=" crossOrigin="anonymous"></script> 
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-HwwvtgBNo3bZJJLYd8oVXjrBZt8cqVSpeBNS5n7C8IVInixGAoxmnlMuBnhbgrkm" crossOrigin="anonymous"></script> */}
{/* <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9" crossOrigin="anonymous"/>
<link rel="stylesheet" href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css"/>
<link rel="stylesheet" href={owl_css}></link>
<link rel="stylesheet" href={custom_style}/> */}

</section>
     
    );
  }
  
  export default Headers;