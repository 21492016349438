import React, { useState, useEffect } from "react";
import CCLogonew from "../../src/assets/CCLogonew.png";
import Check_Icon from "../../src/assets/Check_Icon.svg";
import CC_Logo from "../../src/assets/img/CC_Logo.svg";
import bell from "../../src/assets/img/bell.png";
import Navpages from "./Navpages";
import { Button } from "reactstrap";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import Profile_Avatar from "../../src/assets/img/Profile_Avatar.png";
import { Card } from "reactstrap";
import qs from "qs";
import { useLocation } from "react-router-dom";
import Keycloak_config from "../services/Keycloak_config";
import { error } from "jquery";
import "./OtpVerify.css";

const OtpVerification = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const nodeapiBaseUrl = process.env.REACT_APP_NODEJS_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const formData = location.state?.formData || {};
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [enteredOTP, setEnteredOTP] = useState("");
  const [enteredEmailOTP, setEnteredEmailOTP] = useState("");
  const [activeprofilebar, setactiveprofilebar] = useState(0);
  const [otpVerified, setOtpVerified] = useState(false);
  const [emailOtpVerified, setEmailOtpVerified] = useState(false);
  const [unverifiedOTP, setUnverifiedOTP] = useState({ type: "", message: "" });
  const [thilight, sethilight] = useState(false);
  const [message, setMessage] = useState("");
  const [registrationDone, setRegistrationDone] = useState(false);
  const [timer, setTimer] = useState(60);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(false);
  const [mobileOtpChanged, setMobileOtpChanged] = useState(false);
  const [emailOtpChanged, setEmailOtpChanged] = useState(false);

  // const handleShowErrorModal = (errorMessage) => {
  //   setModalErrorMessage(errorMessage);
  //   setShowErrorModal(true);

  // };

  const Handleusericon = () => {
    setactiveprofilebar(!activeprofilebar);
  };

  const handleSubmit = () => {
    const registrationData = {
      attributes: {
        phoneNumber: formData.mobileNumber,
        "Area of Interest": formData.area,
        isPhoneValidated: true,
        Specialization: formData.specialization,
      },
      credentials: [
        {
          temporary: false,
          type: "password",
          value: formData.password,
        },
      ],

      firstName: formData.firstname,
      lastName: formData.lastname,
      email: formData.email,
      emailVerified: true,
      enabled: true,
      requiredActions: ["VERIFY_EMAIL"],
    };

    axios
      .post(
        `${apiBaseUrl}/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/token`,
        qs.stringify(Keycloak_config),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        const accessToken = response.data.access_token;
        axios
          .post(
            `${apiBaseUrl}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/users`,
            registrationData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            setRegistrationDone(true); // Only set this after async operation completes successfully.
            /*   setTimeout(() => {
              navigate("/");
            }, 2000); */
            // After user registration, get the user ID
            axios
              .get(
                `${apiBaseUrl}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/users?username=${formData.email}`,
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              )
              .then((response) => {
                const user = response.data[0]; // Assuming there's only one user with the given username
                const userId = user.id; // This is the user ID

                axios.post(
                  `${process.env.REACT_APP_NODEJS_TS}/create-profile-details`,
                  {
                    userId: userId,
                    firstName: registrationData.firstName,
                    lastName: registrationData.lastName,
                    email: registrationData.email,
                    emailVerified: true,
                    phoneNumber: registrationData.attributes.phoneNumber,
                    isPhoneValidated: true,
                  }
                );
              })
              .catch((error) => {
                console.error("Error retrieving user information:", error);
                setRegistrationStatus("error");
              });
          })
          .catch((error) => {
            console.error("Error during registration:", error);
            setRegistrationStatus("error");
          });
      })
      .catch((error) => {
        console.error("Error getting access token:", error);
        setRegistrationStatus("error");
      });
  };

  const handleSendOTP = async () => {
    const phoneN = "+91" + formData.mobileNumber;
    if (!phoneN) {
      setMobileNumberError(true);
      return;
    } else {
      setMobileNumberError(false);
    }

    try {
      const response = await axios.post(
        `${nodeapiBaseUrl}:5003/api/send-sms-otp`,
        {
          phone: phoneN, // Use profileData.mobileNumber
        }
      );

      setOtpSent(true);
    } catch (error) {
      console.error(error.message);
      // handleShowErrorModal(error.message);
    }
  };

  const handleEmailSendOTP = async () => {
    const email = localStorage.getItem("emailForOtp");

    try {
      const response = await axios.post(
        `${nodeapiBaseUrl}:5003/api/send-email-otp`,
        {
          email: email,
        }
      );

      const result = true; // Assuming the server returns JSON
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  useEffect(() => {
    let interval;

    if (timer > 0) {
      // Start the timer countdown
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      // Timer reached 0, you can activate the "Resend OTPs" link here
      clearInterval(interval);
    }

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [timer]);

  // useEffect(() => {
  //   // Redirect if both OTPs are verified
  //   if (otpVerified && emailOtpVerified) {
  //     setRegistrationDone(true);
  //     setTimeout(() => {

  //       navigate('/');
  //     }, 2000); // Adjust the delay time (in milliseconds) as needed
  //   }
  // }, [otpVerified, emailOtpVerified, unverifiedOTP, navigate]);

  const showResendLink = timer === 0;

  async function handleVerifyOTP() {
    setOtpVerified(false);

    const phoneN = "+91" + formData.mobileNumber;

    try {
      const response = await axios.post(
        `${nodeapiBaseUrl}:5003/api/verify-sms-otp`,
        {
          phone: phoneN,
          otp: enteredOTP,
        }
      );

      // Add logic to handle successful OTP verification, such as updating UI or redirecting
      if (response.data.isOTPValid) {
        setOtpVerified(true);
        setMobileOtpChanged(false);
        setUnverifiedOTP({ type: "", message: "" });
        setErrorMessage("");
        return true;

        // Additional actions on successful verification
      } else {
        setOtpVerified(false);
        // setErrorMessage('Mobile OTP verification failed.')
        setErrorMessage("Invalid OTPs entered, Please re-enter the valid OTP");
        setUnverifiedOTP({
          type: "Mobile OTP",
          message: "Mobile OTP verification failed. Please try again.",
        }); // Set error message for mobile OTP verification failure
        // Additional actions on failed verification
        return false;
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
  }

  async function handleVerifyEmailOTP() {
    if (emailOtpVerified && !emailOtpChanged) {
      return true;
    }
    //setEmailOtpVerified(false);
    const email = localStorage.getItem("emailForOtp");
    const otp = enteredEmailOTP;
    try {
      // Call the server to verify OTP
      const response = await fetch(
        `${nodeapiBaseUrl}:5003/api/verify-email-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, otp }),
        }
      );

      const result = await response.json(); // Assuming the server returns JSON

      if (result.isOTPValid) {
        setEmailOtpVerified(true);
        setEmailOtpChanged(false);
        setUnverifiedOTP({ type: "", message: "" });
        setErrorMessage("");
        return true;
      } else {
        setEmailOtpVerified(false);
        // setErrorMessage('Email OTP verification failed.')
        setErrorMessage("Invalid OTPs entered, Please re-enter the valid OTP");
        setUnverifiedOTP({
          type: "Email OTP",
          message: "Email OTP verification failed. Please try again.",
        }); // Set error message for email OTP verification failure
        return false;
      }
    } catch (error) {
      //setEmailOtpVerified(false);
      console.error("Error verifying OTP:", error);
      return false;
    }
  }

  const combinedOtpClickHandler = async () => {
    setErrorMessage("");
    setUnverifiedOTP({ type: "", message: "" });
    // Verify the email OTP first
    const emailOtpResult = await handleVerifyEmailOTP();
    // Verify the mobile OTP next
    const mobileOtpResult = await handleVerifyOTP();
    // Check if both OTPs are verified before submitting
    if (emailOtpResult && mobileOtpResult) {
      handleSubmit();
    } else {
      console.error("One or both OTPs are incorrect.");
      // You can set an error message in state to display to the user
      setErrorMessage("One or both OTPs are incorrect.");
    }
  };

  const handleResendOTP = (event) => {
    setEmailOtpVerified(false);
    setOtpVerified(false);
    if (event) {
      event.preventDefault();
    }

    handleSendOTP();
    handleEmailSendOTP();
    setTimer(60); // Reset the timer to 60 seconds
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        paddingTop: "60px",
        position: "absolute",
        textAlign: "center",
        alignItems: "center",
        display: "inline-flex",
        flexDirection: "column",
        backgroundColor: "rgb(246, 249, 252)",
      }}
    >
      {!registrationDone && (
        <div
          style={{
            background: "#F6F9FC",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 32,
            display: "inline-flex",
          }}
        >
          <div
            style={{
              flex: "1 1 0",
              height: 32,
              width: 152,
              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 15,
              display: "flex",
            }}
          >
            <div
              style={{
                color: "#5A5A5A",
                fontSize: 32,
                fontFamily: "Inter",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              <img src={CC_Logo} alt="" />
            </div>
          </div>

          <div style={{}} className="fg_outerlayer">
            <div
              style={{
                alignSelf: "stretch",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 12,
                display: "flex",
              }}
            >
              <div style={{}} className="fg_verification">
                Verification
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  color: "#4A5965",
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                An SMS and email with the One Time Password (OTP) have been sent
                to your mobile number and email ID respectively
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 20,
                display: "flex",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                  gap: 20,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 4,
                    display: "flex",
                  }}
                >
                  <input
                    style={{}}
                    className="fg_mobile"
                    type="otp"
                    value={enteredOTP}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.match(/^\d{0,6}$/)) {
                        setEnteredOTP(value);
                        setMobileOtpChanged(true);
                      }
                    }}
                    placeholder="Mobile Number OTP"
                    required
                    disabled={otpVerified}
                  />
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 4,
                    display: "flex",
                  }}
                >
                  <input
                    style={{}}
                    className="fg_mobile"
                    type="otp"
                    value={enteredEmailOTP}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.match(/^\d{0,6}$/)) {
                        setEnteredEmailOTP(value);
                        setEmailOtpChanged(true);
                      }
                    }}
                    placeholder="Email ID OTP"
                    required
                    disabled={emailOtpVerified}
                  />
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  gap: 8,
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    color: "#80919F",
                    fontSize: 12,
                    fontFamily: "Inter",
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  Time Remaining: {timer}s
                </div>

                {timer === 0 && (
                  <>
                    <div
                      style={{
                        color: "#0B6AEA",
                        fontSize: 12,
                        fontFamily: "Inter",
                        fontWeight: "400",
                        textDecoration: "underline",
                        cursor: "pointer",
                        wordWrap: "break-word",
                      }}
                      onClick={handleResendOTP}
                    >
                      Resend OTPs
                    </div>
                  </>
                )}
              </div>
              {errorMessage && (
                <div
                  style={{
                    color: "red",
                    fontSize: 14,
                    fontFamily: "Inter",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  {errorMessage}
                </div>
              )}
              <Button
                style={{
                  alignSelf: "stretch",
                  paddingLeft: 32,
                  paddingRight: 32,
                  paddingTop: 12,
                  paddingBottom: 12,
                  background:
                    enteredOTP.trim().length === 6 &&
                    enteredEmailOTP.trim().length === 6
                      ? "#0B6AEA"
                      : "#E2EBF3",
                  borderRadius: 8,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "inline-flex",
                }}
                disabled={
                  !(
                    enteredOTP.trim().length === 6 &&
                    enteredEmailOTP.trim().length === 6
                  )
                }
                onClick={combinedOtpClickHandler}
              >
                <div
                  className="fg_account"
                  style={{
                    color:
                      enteredOTP.trim().length === 6 &&
                      enteredEmailOTP.trim().length === 6
                        ? "white"
                        : "#4A5965",
                    fontFamily: "Inter",
                    wordWrap: "break-word",
                  }}
                >
                  Create Your Account
                </div>
              </Button>
              <NavLink
                  to="/register"
                  className={({ isActive }) =>
                    isActive ? "active nav-link" : "none nav-link"
                  }
                  style={{ textDecoration: "none" ,width: "100%"}}
                >
              <Button
                style={{
                  backgroundColor: "#fff",
                  alignSelf: "stretch",
                  paddingLeft: 32,
                  paddingRight: 32,
                  paddingTop: 12,
                  paddingBottom: 12,
                  borderRadius: 8,
                  border: "1px #0B6AEA solid",
                  justifyContent: "center",
                  alignItems: "center",
                    display: "inline-flex",
                  width: "100%",
                }}
              >
               
                  <div
                    className="fg_account"
                    style={{
                      color: "#0B6AEA",
                      fontFamily: "Inter",
                      wordWrap: "break-word",
                    }}
                  >
                    Go Back
                  </div>
                
              </Button>
              </NavLink>
            </div>
            <div style={{ alignSelf: "stretch" }}>
              <span
                style={{
                  color: "#4A5965",
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                By creating an account or logging in, you agree to CreamCollar’s{" "}
              </span>
              <span
                style={{
                  color: "#4A5965",
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  textDecoration: "underline",
                  wordWrap: "break-word",
                }}
              >
                Conditions of Use
              </span>
              <span
                style={{
                  color: "#4A5965",
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                {" "}
                and{" "}
              </span>
              <span
                style={{
                  color: "#4A5965",
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  textDecoration: "underline",
                  wordWrap: "break-word",
                }}
              >
                Privacy Policy
              </span>
              <span
                style={{
                  color: "#4A5965",
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                .
              </span>
            </div>
          </div>
        </div>
      )}

      {registrationDone && (
        <div
          style={{
            width: "100%",
            height: "100%",

            background: "#F6F9FC",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 32,
            display: "inline-flex",
          }}
        >
          <div
            style={{
              flex: "1 1 0",
              height: 50,
              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 15,
              display: "flex",
            }}
          >
            <div
              style={{
                color: "#5A5A5A",
                fontSize: 32,
                fontFamily: "Inter",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              <img src={CC_Logo} alt="" />
            </div>
          </div>

          <div
            style={{
              alignSelf: "stretch",
              height: 505,
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 10,
              paddingBottom: 32,
              width: "50%",
              background: "white",
              boxShadow: "0px 8px 40px rgba(9, 44, 76, 0.08)",
              borderRadius: 20,
              flexDirection: "column",
              gap: 32,
              alignSelf: "center",
              gap: 40,
              display: "flex",
            }}
          >
            <div
              style={{
                marginTop: 50,
                color: "#5A5A5A",
                fontSize: 32,
                fontFamily: "Inter",
                fontWeight: "700",
                wordWrap: "break-word",
                alignItems: "space-between",
                gap: 32,
              }}
            >
              <img src={Check_Icon} style={{ width: "25%" }} />

              <h2
                className="success-message"
                style={{
                  color: "#00213D",
                  fontSize: 20,
                  fontFamily: "Inter",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                You're all set! Registration completed successfully.
              </h2>

              <Button
                style={{
                  alignSelf: "stretch",
                  paddingLeft: 32,
                  paddingRight: 32,
                  paddingTop: 12,
                  paddingBottom: 12,
                  background: "#0B6AEA",

                  borderRadius: 8,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "inline-flex",
                }}
              >
                <NavLink to="/loginpage" style={{ textDecoration: "none" }}>
                  <div
                    className="fg_account"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Inter",
                      wordWrap: "break-word",
                    }}
                  >
                    Explore The Platform
                  </div>
                </NavLink>
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* 
       <div className='d-flex flex-column justify-content-center align-items-center' style={{marginTop: 100 }}>
         <Card className='m-3 text-start' style={{  background: '#F6F9FC',width: '35%', maxWidth: '1000px', alignItems: 'center'}}>
         {registrationDone && (
            <div className="success-message m-5" style={{ color: 'green', fontSize: '1rem', width: '100%', height: '200px', textAlign: 'center'}}>
                
                <div style={{marginBottom: 40, flex: '1 1 0', height: 55, paddingTop: 15, paddingLeft: '35%', paddingBottom: 15, justifyContent: 'flex-start', alignItems: 'center', gap: 15, display: 'flex'}}>
                <div style={{color: '#5A5A5A', fontSize: 32, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}><img src={CC_Logo} alt="" /></div>
            </div>
            <div style={{color: '#5A5A5A', fontSize: 32, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word', alignItems: 'center'}}>
                <h2 className="success-message" style={{color: '#00213D', fontSize: 20, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>You're all set! Registration completed successfully.</h2> 
                <h2 className="success-message " style={{color: '#00213D', fontSize: 20, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>A verification link has been sent to your email.</h2>
                </div>
            </div>
        )}
        
        </Card>
        </div> */}
    </div>
  );
};

export default OtpVerification;
