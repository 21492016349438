import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import { useState } from "react";
import delete_details from "../../assets/img/delete_details.svg";
import { IoClose } from "react-icons/io5";
import "./profile.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";

export function Profile_details_header(properties) {
  return (
    <div className={properties.bottomborder} style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          alignSelf: "stretch",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 32,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            flex: "1 1 0",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 10,
            display: "inline-flex",
          }}
        >
          <div className="section-header">{properties.topic}</div>
        </div>
        <div
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 12,
            display: "flex",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 4,
              display: "inline-flex",
            }}
          >
            <button
              className="buttonbkcolornone2"
              onClick={properties.clickname}
              style={{
                padding: 0,
                color: "#0B6AEA",
                fontSize: 16,
                fontFamily: "Inter",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              {" "}
              {properties.adddetailsbtn ? (
                <span>
                  {window.innerWidth <= 576 ? "" : "Add Details"}
                  <img
                    src={properties.imgtoshow}
                    style={{ marginLeft: 6, height: 22 }}
                    alt=""
                  />
                </span>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </div>
      <div style={{ fontSize: 14, fontWeight: 400 }}>{properties.subTopic}</div>
      <p
        style={{
          color: "#697077",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
        }}
      >
        {properties.fieldWisePercentage}
      </p>
    </div>
  );
}

export function Profile__education_details_header(properties) {
  const { topic } = properties;

  let hideEditBtn = true;

  const classXPresent = properties.propData.educationaldetails.some(
    (each) => each.educationLevel === "Class X"
  );

  const classXIIPresent = properties.propData.educationaldetails.some(
    (each) => each.educationLevel === "Class XII"
  );

  const graduationPresent = properties.propData.educationaldetails.some(
    (each) => each.educationLevel === "Graduation"
  );

  hideEditBtn = classXIIPresent && classXPresent && graduationPresent;
 
  return (
    <div className={properties.bottomborder} style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          alignSelf: "stretch",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 32,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            flex: "1 1 0",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 10,
            display: "inline-flex",
          }}
        >
          <div className="section-header">{properties.topic}</div>
        </div>
        <div
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 12,
            display: "flex",
          }}
        >
          {!hideEditBtn && (
            <div
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <button
                className="buttonbkcolornone2"
                onClick={properties.clickname}
                style={{
                  padding: 0,
                  color: "#0B6AEA",
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                {" "}
                {properties.adddetailsbtn ? (
                  <span>
                    {window.innerWidth <= 576 ? "" : "Add Details"}
                    <img
                      src={properties.imgtoshow}
                      style={{ marginLeft: 6, height: 22 }}
                      alt=""
                    />
                  </span>
                ) : (
                  ""
                )}
              </button>
            </div>
          )}
        </div>
      </div>
      <div style={{ fontSize: 14, fontWeight: 400 }}>{properties.subTopic}</div>
      <p
        style={{
          color: "#697077",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
        }}
      >
        {properties.fieldWisePercentage}
      </p>
    </div>
  );
}

export function Profile_details_generalview(properties) {
  return (
    <div
      style={{
        alignSelf: "stretch",
        height: "auto",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 32,
        display: "flex",
        width: "100%",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          padding: 16,
          background: "#F2F7FE",
          borderRadius: 8,
          justifyContent: "space-between",
          alignItems: "center",
          gap: 24,
          display: "inline-flex",
        }}
      >
        <div className="certificate-list-field">
          <div
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 4,
              display: "flex",
            }}
          >
            <div className="card-head">{properties.heading}</div>
            <div className="card-desc">{properties.description}</div>
          </div>
        </div>
        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 16,
            display: "flex",
          }}
        >
          <div style={{ width: 32, height: 32, position: "relative" }}>
            <img
              src={properties.editicon}
              onClick={properties.editbtnclick}
              style={{ width: 32, height: 32, position: "relative" }}
            />
          </div>
          <div style={{ width: 32, height: 32, position: "relative" }}>
            <img
              src={properties.deleteicon}
              onClick={properties.deletebtnclick}
              style={{ width: 32, height: 32, position: "relative" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function Profile_details_interestview(properties) {
  return (
    <div
      style={{
        alignSelf: "stretch",
        height: 66,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 12,
        display: "flex",
      }}
    >
      <div
        style={{
          color: "#00213D",
          fontSize: 12,
          fontFamily: "Inter",
          fontWeight: "600",
          wordWrap: "break-word",
        }}
      >
        {properties.heading}
      </div>
      <div
        style={{
          alignSelf: "stretch",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 8,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 8,
            paddingBottom: 8,
            borderRadius: 20,
            border: "1px #E2EBF3 solid",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 4,
            display: "flex",
          }}
        >
          <div
            style={{
              color: "#4A5965",
              fontSize: 16,
              fontFamily: "Inter",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            {properties.keyelements}
          </div>
        </div>
        <div
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 8,
            paddingBottom: 8,
            borderRadius: 20,
            border: "1px #E2EBF3 solid",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 4,
            display: "flex",
          }}
        >
          <div
            style={{
              color: "#4A5965",
              fontSize: 16,
              fontFamily: "Inter",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            Photoshop
          </div>
        </div>
        <div
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 8,
            paddingBottom: 8,
            borderRadius: 20,
            border: "1px #E2EBF3 solid",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 4,
            display: "flex",
          }}
        >
          <div
            style={{
              color: "#4A5965",
              fontSize: 16,
              fontFamily: "Inter",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            Illustrator
          </div>
        </div>
      </div>
    </div>
  );
}
export function Profile_edit_educational_content(properties) {
  let educationtype = {
    "Class X": "Class X",
    "Class XII": "Class XII",
    Graduation: "Graduation",
  };

  const schoolBoard = {
    "Andhra Pradesh Board": "Board of Intermediate Education (Andhra Pradesh)",
    "Arunachal Pradesh Board": "Arunachal Pradesh Board",
    "Assam Board": "Assam Higher Secondary Education Council",
    "Bihar State Board": "Bihar School Examination Board",
    "Cambridge Board": "Council for the Indian School Certificate Examinations",
    CBSE: "Central Board of Secondary Education",
    "Chhattisgarh Board": "Chhattisgarh Board of Secondary Education",
    "Delhi Board": "Delhi Board of Senior Secondary Education",
    "Goa Board": "Goa Board of Secondary and Higher Secondary Education",
    "Gujarat Board": "Gujarat Secondary and Higher Secondary Education Board",
    "Haryana Board": "Board of School Education Haryana",
    "Himachal Pradesh Board": "H.P. Board of School Education",
    IB: "International Baccalaureate",
    ICSE: "ICSE Board (Indian Council of Secondary Education",
    "Jammu and Kashmir Board": "The J & K State Board of School Education",
    "Jharkhand Board": "Jharkhand Academic Council, Ranchi",
    "Karnataka Board": "Govt. of Karnataka Dept. of Pre-University Education",
    "Kerala Board": "Kerala Board of Higher Secondary Education",
    "Ladakh Board": "Ladakh Board",
    "Madhya Pradesh Board": "Board of Secondary Education, Madhya Pradesh",
    "Maharashtra Board":
      "Maharashtra State Board of Secondary and Higher Secondary Education",
    "Manipur Board": "Board of Secondary Education, Manipur",
    "Meghalaya Board": "Meghalaya Board of School Education",
    "Mizoram Board": "Mizoram Board of School Education",
    "Nagaland Board": "Nagaland Board of School Education",
    "Odisha Board": "Council of Higher Secondary Education, Odisha",
    "Punjab Board": "Punjab School Education Board",
    "Rajasthan Board": "Rajasthan Board of Secondary Education",
    "Sikkim Board": "Sikkim Board",
    "Tamil Nadu Board":
      "Tamil Nadu State Board of School Examinations(Sec.) & Board of Higher Secondary Examinations",
    "Telangana Board": "Telangana State Board of Intermediate Education",
    "Tripura Board": "Tripura Board of Secondary Education",
    "Uttar Pradesh Board": "U.P. Board of High School & Intermediate Education",
    "Uttarakhand Board": "Board of School Education Uttarakhand",
    "West Bengal Board": "West Bengal Board of Secondary Education",
  };

  const schoolmedium = {
    English: "English",
    Telugu: "Telugu",
    Tamil: "Tamil",
    Hindi: "Hindi",
  };

  if (properties.education_action === "add") {
    const classXPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Class X"
    );

    const classXIIPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Class XII"
    );

    const graduationPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Graduation"
    );

    if (classXPresent) {
      delete educationtype["Class X"];
    }

    if (classXIIPresent) {
      delete educationtype["Class XII"];
    }

    if (graduationPresent) {
      delete educationtype["Graduation"];
    }
  }


  const { isEditMode, isThisEduLevelBox } = properties;


  return (
    <div
      style={{
        alignSelf: "stretch",
        paddingBottom: 32,
        borderBottom: "1px #E2EBF3 solid",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "flex",
      }}
    >
      <div className="education-input-field">
        <div className="education-list">
          <Textselectbox
            textplacing="Education"
            keyname="educationLevel"
            labelPlacing="Education"
            validationarea="no"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.specificvaluechange}
            selectedvalue={properties.mydata.educationLevel}
            datatoselect={educationtype}
            profileData={properties.propData}
            isDisabled={isEditMode}
            eduLevel={isThisEduLevelBox !== undefined ? true : false}
          />
        </div>
        <div className="education-list">
          <Textselectbox
            textplacing="Board"
            keyname="boardOrg"
            validationarea="no"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            selectedvalue={properties.mydata.boardOrg}
            datatoselect={schoolBoard}
            mydata={properties.mydata}
          />
        </div>
      </div>
      <div className="education-input-field">
        <div className="education-list">
          <Textselectbox
            textplacing="School Medium"
            keyname="schoolMedium"
            validationarea="no"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            selectedvalue={properties.mydata.schoolMedium}
            datatoselect={schoolmedium}
          />
        </div>
        <div className="education-list">
          <ProfileDatePicker
            textplacing="Pass Out Year "
            changedate={properties.changedate}
            keyname="endDate"
            valuechange={properties.valuechange}
            realdata={properties.mydata.endDate}
          />
        </div>
      </div>
      <div className="education-input-field">
        <div className="education-list">
          <Textinputfield
            textplacing="Mark in %"
            keyname="mark"
            labelPlacing="Mark in %"
            validationarea="limitrangeofmark"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            realdata={properties.mydata.mark}
          />
        </div>
        <div
          style={{
            flex: "1 1 0px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "4px",
            display: "inline-flex",
            alignSelf: "stretch",
          }}
        ></div>
      </div>
    </div>
  );
}

export function Profile_edit_educational_content2(properties) {
  const educationtype = {
    "Class X": "Class X",
    "Class XII": "Class XII",
    Graduation: "Graduation",
  };


  const courses = {
    "B.E": "B.E",
    "B.Tech": "B.Tech",
  };

  const specializations = {
    "Computer Science": "Computer Science",
    "Business Administration": "Business Administration",
    "Electrical Engineering": "Electrical Engineering",
    "Mechanical Engineering": "Mechanical Engineering",
    "Civil Engineering": "Civil Engineering",
    Mechatronics: "Mechatronics",
    "Thermal Engineering": "Thermal Engineering",
    "Automobile Engineering": "Automobile Engineering",
  };
  const gradingtypelist = { Percentage: "Percentage", CGPA: "CGPA" };

  if (properties.education_action === "edit") {
    const classXPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Class X"
    );

    const classXIIPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Class XII"
    );

    const graduationPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Graduation"
    );

    if (classXPresent) {
      delete educationtype["Class X"];
    }

    if (classXIIPresent) {
      delete educationtype["Class XII"];
    }

    if (graduationPresent) {
      delete educationtype["Graduation"];
    }
  }

  if (properties.education_action === "edit") {
    const classXPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Class X"
    );

    const classXIIPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Class XII"
    );

    const graduationPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Graduation"
    );

    if (classXPresent) {
      delete educationtype["Class X"];
    }

    if (classXIIPresent) {
      delete educationtype["Class XII"];
    }

    if (graduationPresent) {
      delete educationtype["Graduation"];
    }
  }

  if (properties.education_action === "edit") {
    const classXPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Class X"
    );

    const classXIIPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Class XII"
    );

    const graduationPresent = properties.propData.educationaldetails.some(
      (each) => each.educationLevel === "Graduation"
    );

    if (classXPresent) {
      delete educationtype["Class X"];
    }

    if (classXIIPresent) {
      delete educationtype["Class XII"];
    }

    if (graduationPresent) {
      delete educationtype["Graduation"];
    }
  }

  const { mydata, isEditMode, isThisEduLevelBox } = properties;

  return (
    <div className="education-college-edit-content">
      <div className="education-input-field2">
        <div className="education-input-field2">
          <Textselectbox
            textplacing="Education"
            keyname="educationLevel"
            validationarea="no"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.specificvaluechange}
            selectedvalue={properties.mydata.educationLevel}
            datatoselect={educationtype}
            profileData={properties.propData}
            isDisabled={isEditMode}
            eduLevel={isThisEduLevelBox !== undefined ? true : false}
          />
        </div>
        <div className="education-input-field2">
          <Textinputfield
            textplacing="University/College"
            keyname="university"
            validationarea="alphaonly"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            realdata={properties.mydata.university}
          />
        </div>
      </div>
      <div className="education-input-field2">
        <div
          style={{
            flex: "1 1 0",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 4,
            display: "inline-flex",
            width: "100%",
          }}
        >
          <Textselectbox
            textplacing="Courses"
            keyname="courses"
            validationarea="no"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            selectedvalue={properties.mydata.courses}
            datatoselect={courses}
          />
        </div>
        <div
          style={{
            flex: "1 1 0px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "4px",
            display: "inline-flex",
            alignSelf: "stretch",
          }}
        >
          <Textselectbox
            textplacing="Specializations"
            keyname="specializations"
            valuechange={properties.valuechange}
            validationarea="no"
            functionnamecall={properties.functionnamecall}
            selectedvalue={properties.mydata.specializations}
            datatoselect={specializations}
          />
        </div>
      </div>
      <div className="education-input-field2" style={{ gap: 32 }}>
        <div
          style={{
            flex: "1 1 0",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 4,
            display: "inline-flex",
            width: "100%",
          }}
        >
          <ProfileDatePicker
            textplacing="Start Date"
            changedate={properties.changedate}
            keyname="collegeStartDate"
            valuechange={properties.valuechange}
            realdata={properties.mydata.collegeStartDate}
          />
        </div>
        <div
          style={{
            flex: "1 1 0px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "4px",
            display: "inline-flex",

            alignSelf: "stretch",
          }}
        >
          <ProfileDatePickerAdjecent
            textplacing="End Date"
            changedate={properties.changedate}
            keyname="collegeEndDate"
            valuechange={properties.valuechange}
            realdata={properties.mydata.collegeEndDate}
          />
        </div>
      </div>

      <div className="education-input-field2">
        <div
          style={{
            flex: "1 1 0",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 4,
            display: "inline-flex",
            width: "100%",
          }}
        >
          <Textselectbox
            textplacing="Grading System"
            keyname="gradingtype"
            validationarea="no"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            selectedvalue={properties.mydata.gradingtype}
            datatoselect={gradingtypelist}
          />
        </div>
        <div
          style={{
            flex: "1 1 0px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "4px",
            display: "inline-flex",
            alignSelf: "stretch",
          }}
        >
          {mydata.gradingtype === "CGPA" && (
            <Textinputfield
              textplacing="CGPA"
              keyname="mark"
              validationarea="cgpa"
              functionnamecall={properties.functionnamecall}
              valuechange={properties.valuechange}
              realdata={properties.mydata.mark}
            />
          )}
          {mydata.gradingtype === "Percentage" && (
            <Textinputfield
              textplacing="Mark in %"
              keyname="mark"
              validationarea="limitrangeofmark"
              functionnamecall={properties.functionnamecall}
              valuechange={properties.valuechange}
              realdata={properties.mydata.mark}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export function CheckBox(properties) {
  return (
    <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
      <input type="checkbox" />
      <p style={{ marginTop: "13px" }}>Currently Working Here</p>
    </div>
  );
}

export function Profile_edit_view_content(properties) {
  const employertype = {
    CurrentEmployer: "CurrentEmployer",
    PreviousEmployer: "PreviousEmployer",
  };
  const employmenttype = {
    FullTime: "FullTime",
    PartTime: "PartTime",
    Intenship: "Intenship",
  };
  return (
    <div className="edit-section-container">
      <div className="edit-section-view-sections">
        <div className="work-lists ">
          <Textselectbox
            textplacing="Current Employer"
            keyname="employertype"
            validationarea="no"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            selectedvalue={properties.mydata.employertype}
            datatoselect={employertype}
            isDisabled={properties.workEditMode}
          />
        </div>
        <div className="work-lists">
          <Textselectbox
            textplacing="Employment Type"
            keyname="employmenttype"
            validationarea="no"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            selectedvalue={properties.mydata.employmenttype}
            datatoselect={employmenttype}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="edit-section-view-sections">
        <div className="edit-section-view-sections">
          <Textinputfield
            textplacing="Company Name"
            keyname="companyname"
            labelPlacing="Company Name"
            validationarea="alphaonly"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            realdata={properties.mydata.companyname}
          />
        </div>
        <div className="edit-section-view-sections">
          <Textinputfield
            textplacing="Designation"
            keyname="designation"
            labelPlacing="Designation"
            validationarea="alphaonly"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            realdata={properties.mydata.designation}
          />
        </div>
      </div>
      <div className="edit-section-view-sections">
        <div className="edit-section-view-sections">
          <ProfileDatePicker
            textplacing="Joining Date"
            changedate={properties.changedate}
            labelPlacing="Joining Date"
            keyname="joiningdate"
            valuechange={properties.valuechange}
            realdata={properties.mydata.joiningdate}
          />
        </div>
        <div className="edit-section-view-sections">
          <ProfileDatePicker
            textplacing="Worked Till"
            labelPlacing="Worked Till"
            changedate={properties.changedate}
            keyname="lastworkingday"
            valuechange={properties.valuechange}
            realdata={properties.mydata.lastworkingday}
          />
        </div>
      </div>
      <div
        style={{
          alignSelf: "stretch",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "40px",
          display: "inline-flex",
          width: "100%",
        }}
      >
        <div className="w-100">
          <Textinputfieldarea
            textplacing="Job description"
            keyname="jobdescription"
            labelPlacing="Job Description"
            validationarea="no"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            realdata={properties.mydata.jobdescription}
          />
        </div>
      </div>
    </div>
  );
}

export function Profile_interest_edit_view(properties) {
  return (
    <div className="certificate-edit-section">
      <div className="certificate-input-section">
        <div className="certificate-input-field">
          <Textinputfield
            keyname="cetification_provider_name"
            validationarea="alphaonly"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            realdata={properties.mydata.cetification_provider_name}
            textplacing="Certification Provider Name"
            labelPlacing="Certification Provider Name"
          />
        </div>
        <div className="certificate-input-field">
          <Textinputfield
            keyname="certification_course_name"
            validationarea="alphaonly"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            realdata={properties.mydata.certification_course_name}
            textplacing="Certification Course Name"
            labelPlacing="Certification Course Name"
          />
        </div>
      </div>
      <div className="certificate-input-section">
        <div
          style={{
            flex: "1 1 0",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 4,
            display: "inline-flex",
            alignSelf: "stretch",
          }}
        >
          <Textinputfield
            keyname="certificate_url"
            validationarea="alphaonly"
            functionnamecall={properties.functionnamecall}
            valuechange={properties.valuechange}
            realdata={properties.mydata.certificate_url}
            textplacing="Certificate URL"
            labelPlacing="Certificate URL"
          />
        </div>
        <div
          style={{
            flex: "1 1 0px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "4px",
            display: "inline-flex",
            alignSelf: "stretch",
            width: "100",
          }}
        >
          <ProfileDatePicker
            keyname="certification_date"
            changedate={properties.changedate}
            valuechange={properties.valuechange}
            realdata={properties.mydata.certification_date}
            textplacing="Worked Till"
          />
        </div>
      </div>
    </div>
  );
}

export function Textinputfieldarea(properties) {
  const changeinputfield_focus = () => {
    const labelFlag = document.getElementById(properties.keyname);
    var log_lab = document.getElementById(properties.keyname);
    log_lab.style.display = "block";
    log_lab.style.color = "#0B6AEA";

    labelFlag.style.display = "block";
    labelFlag.textContent = properties.labelPlacing;

  };

  const changeinputfield_blur = () => {
    var log_lab = document.getElementById(properties.keyname);
    const labelFlag = document.getElementById(properties.keyname);
    log_lab.style.color = "#4A5965";

    const inputEl =
      document.getElementById(`textarea-${properties.keyname}`).value !== "";
   
    if (!inputEl) {
      labelFlag.style.display = "none";
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "159px",
        alignSelf: "stretch",
        borderRadius: "12px",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "flex",
      }}
    >
      <label
        id={properties.keyname}
        style={{
          marginTop: "-7px",
          position: "absolute",
          display: "none",
          color: "#0B6AEA",
          marginLeft: "20px",
          zIndex: 1,
          fontSize: "12px",
          padding: "0 8px",
          background: "white",
          fontFamily: "Inter",
          fontWeight: 400,
          lineHeight: "15px",
          wordWrap: "break-word",
        }}
      >
        About You
      </label>
      <textarea
        rows="3"
        className="log_btn_hover w-100"
        style={{
          height: "159px",
          resize: "none",
          padding: "16px 28px",
          alignSelf: "stretch",
          paddingTop: "16px",
          paddingBottom: "16px",
          background: "white",
          borderRadius: "12px",
          border: "1px #E2EBF3 solid",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
        }}
        type="text"
        functionnamecall={properties.functionnamecall}
        validationarea={properties.validationarea}
        id={`textarea-${properties.keyname}`}
        name={properties.keyname}
        onClick={changeinputfield_focus}
        onBlur={changeinputfield_blur}
        onChange={properties.valuechange}
        placeholder={properties.textplacing}
        maxLength={500}
        required
        autoComplete="off"
      >
        {properties.realdata}
      </textarea>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          right: "27px",
          paddingBottom: 16,
          color: "#80919F",
          fontSize: 12,
          fontFamily: "Inter",
          fontWeight: "400",
          wordWrap: "break-word",
        }}
      >
        0/500
      </div>
      <span
        style={{
          marginLeft: "28px",
          color: "#EB5757",
          fontSize: "12px",
          fontFamily: "Inter",
          fontWeight: 400,
          lineHeight: "15px",
          wordWrap: "break-word",
        }}
      ></span>
    </div>
  );
}
export function Textinputfield(properties) {
  const changeinputfield_focus = () => {
    const labelFlag = document.getElementById(properties.keyname);
    var log_lab = document.getElementById(properties.keyname);
    log_lab.style.display = "block";
    log_lab.style.color = "#0B6AEA";
   
    labelFlag.style.display = "block";
    labelFlag.textContent = properties.labelPlacing;
  };
  const changeinputfield_blur = (event) => {
    var log_lab = document.getElementById(properties.keyname);
    const labelFlag = document.getElementById(properties.keyname);
    log_lab.style.color = "#0B6AEA";

    labelFlag.style.display = "block";

    const checkSpanErrEl = document.getElementById(
      `span-${properties.keyname}`
    );

    if (
      document.getElementById(`input-${properties.keyname}`).value.trim() === ""
    ) {
      checkSpanErrEl.style.display = "block";
    } else {
      checkSpanErrEl.style.display = "none";
    }
  };
  return (
    <div
      style={{
        width: "100%",
        alignSelf: "stretch",
        borderRadius: "12px",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "flex",
      }}
    >
      <label
        id={properties.keyname}
        style={{
          marginTop: "-7px",
          position: "absolute",
          display: "none",
          color: "#0B6AEA",
          marginLeft: "20px",
          zIndex: 1,
          fontSize: "12px",
          padding: "0 8px",
          background: "white",
          fontFamily: "Inter",
          fontWeight: 400,
          lineHeight: "15px",
          wordWrap: "break-word",
        }}
      >
        {properties.labelPlacing}
      </label>
      <input
        className="log_btn_hover"
        style={{
          padding: "16px 28px",
          alignSelf: "stretch",
          height: "57px",
          paddingTop: "16px",
          paddingBottom: "16px",
          background: "white",
          borderRadius: "12px",
          border: "1px #E2EBF3 solid",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
        }}
        type="text"
        id={`input-${properties.keyname}`}
        functionnamecall={properties.functionnamecall}
        validationarea={properties.validationarea}
        name={properties.keyname}
        onChange={properties.valuechange} // Here
        placeholder={properties.textplacing}
        value={properties.realdata}
        onFocus={changeinputfield_focus}
        onBlur={changeinputfield_blur}
        required
        autoComplete="off"
      />

      <span
        className="err-msg"
        id={`span-${properties.keyname}`}
        style={{ display: "none" }}
      >
        Required
      </span>
    </div>
  );
}
export function ProfileDatePicker(properties) {
  const changeinputfield_focus = () => {
    const labelFlag = document.getElementById(properties.keyname);
    var log_lab = document.getElementById(properties.keyname);
    log_lab.style.display = "block";
    log_lab.style.color = "#0B6AEA";
   
    labelFlag.style.display = "block";
    labelFlag.textContent = properties.textplacing;
  
  };
  const changeinputfield_blur = () => {
    var log_lab = document.getElementById(properties.keyname);
    const labelFlag = document.getElementById(properties.keyname);
    labelFlag.style.color = "#0B6AEA";
    labelFlag.style.display = "block";
    //labelFlag.textContent = textplacing;

    const dateEl = document.getElementById(`dateEl-${properties.keyname}`);
    const dateErrMsg = document.getElementById(`span-${properties.keyname}`);
    if (dateEl.value === "") {
      dateErrMsg.style.display = "block";
    } else {
      dateErrMsg.style.display = "none";
    }
  };

  return (
    <div
      style={{
        width: "100%",
        alignSelf: "stretch",
        height: "57px",
        borderRadius: "12px",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "flex",
        paddingBottom: 15,
        width: "100%",
      }}
    >
      <label
        id={properties.keyname}
        style={{
          marginTop: "-7px",
          position: "absolute",
          display: "none",
          color: "#0B6AEA",
          marginLeft: "20px",
          zIndex: 3,
          fontSize: "12px",
          padding: "0 8px",
          background: "white",
          fontFamily: "Inter",
          fontWeight: 400,
          lineHeight: "15px",
          wordWrap: "break-word",
        }}
      >
        {properties.textplacing}
      </label>

      <div style={{ width: "100%" }}>
        <DatePicker
          className="customDatePicker log_btn_hover"
          style={{
            padding: "16px 20px !important",
            alignSelf: "stretch",
            border: "12px solid brown",
            width: "100% !important",
            paddingTop: "16px",
            paddingBottom: "16px",
            background: "white",
            borderRadius: "12px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
          }}
          id={`dateEl-${properties.keyname}`}
          name={properties.keyname}
          required
          onClick={changeinputfield_focus}
          onBlur={changeinputfield_blur}
          autoComplete="off"
          value={
            properties.realdata
              ? dateFormat(properties.realdata, "dd-mm-yyyy")
              : ""
          }
          maxDate={new Date()}
          onChange={(date) => {
            properties.changedate(properties.keyname, date);
          }}
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}
          placeholderText={properties.textplacing}
        />
        <span
          className="err-msg"
          id={`span-${properties.keyname}`}
          style={{ display: "none" }}
        >
          Required
        </span>
      </div>
    </div>
  );
}

export function ProfileDatePickerAdjecent(properties) {
  const changeinputfield_focus = () => {
    const labelFlag = document.getElementById(properties.keyname);
    var log_lab = document.getElementById(properties.keyname);
    log_lab.style.display = "block";
    log_lab.style.color = "#0B6AEA";

    labelFlag.style.display = "block";
    labelFlag.textContent = properties.textplacing;

  };
  const changeinputfield_blur = () => {
    var log_lab = document.getElementById(properties.keyname);
    const labelFlag = document.getElementById(properties.keyname);
    labelFlag.style.color = "#0B6AEA";
    labelFlag.style.display = "block";
    //labelFlag.textContent = textplacing;

    const dateEl = document.getElementById(`dateEl-${properties.keyname}`);
    const dateErrMsg = document.getElementById(`span-${properties.keyname}`);
    if (dateEl.value === "") {
      dateErrMsg.style.display = "block";
    } else {
      dateErrMsg.style.display = "none";
    }
  };

  return (
    <div
      style={{
        width: "100%",
        alignSelf: "stretch",
        height: "57px",
        borderRadius: "12px",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "flex",
        paddingBottom: 15,
        width: "100%",
        zIndex: 20,
      }}
    >
      <label
        id={properties.keyname}
        style={{
          marginTop: "-7px",
          position: "absolute",
          display: "none",
          color: "#0B6AEA",
          marginLeft: "20px",
          zIndex: 21,
          fontSize: "12px",
          padding: "0 8px",
          background: "white",
          fontFamily: "Inter",
          fontWeight: 400,
          lineHeight: "15px",
          wordWrap: "break-word",
        }}
      >
        {properties.textplacing}
      </label>

      <DatePicker
        className="customDatePicker log_btn_hover"
        style={{
          padding: "16px 20px !important",
          alignSelf: "stretch",
          border: "12px solid brown",
          width: "100% !important",
          paddingTop: "16px",
          paddingBottom: "16px",
          background: "white",
          borderRadius: "12px",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          zIndex: 20,
          border: "1px solid red",
        }}
        id={`dateEl-${properties.keyname}`}
        name={properties.keyname}
        required
        onClick={changeinputfield_focus}
        onBlur={changeinputfield_blur}
        autoComplete="off"
        value={
          properties.realdata
            ? dateFormat(properties.realdata, "dd-mm-yyyy")
            : ""
        }
        maxDate={new Date()}
        onChange={(date) => {
          properties.changedate(properties.keyname, date);
        }}
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={100}
        placeholderText={properties.textplacing}
      />
      <span
        className="err-msg"
        id={`span-${properties.keyname}`}
        style={{ display: "none" }}
      >
        Required
      </span>
    </div>
  );
}

export function Textselectbox(properties) {
  var classXPresent = undefined;
  var classXIIPresent = undefined;

  const {
    isDisabled = false,
    isThisEduLevelBox = false,
    profileData,
  } = properties;

  if (properties.eduLevel) {
    classXPresent = profileData.educationaldetails.some(
      (each) => each.educationLevel === "Class X"
    );

    classXIIPresent = profileData.educationaldetails.some(
      (each) => each.educationLevel === "Class XII"
    );

  }


  const isAddMode = false;


  return (
    <div
      style={{
        width: "100%",
        alignSelf: "stretch",
        height: "57px",
        borderRadius: "12px",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "flex",
        paddingTop: 0,
      }}
    >
      <select
        className="log_btn_hover"
        id={`input-${properties.keyname}`}
        disabled={
          isDisabled ||
          (properties.textplacing === "Board"
            ? properties.mydata.educationLevel === ""
            : null)
        }
        name={properties.keyname}
        style={{
          padding: "12px 20px",
          alignSelf: "stretch",
          color: "#80919F",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          paddingTop: "16px",
          paddingBottom: "16px",
          background: "white",
          borderRadius: "12px",
          width: "100%",
          border: "1px #E2EBF3 solid",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
        }}
        type="text"
        functionnamecall={properties.functionnamecall}
        validationarea={properties.validationarea}
        onChange={properties.valuechange}
        // onFocus={changeinputfield_focus} onBlur={changeinputfield_blur}
        required
        autoComplete="off"
        value={properties.selectedvalue}
      >
        <option
          value="Select"
          style={{ fontSize: 14, fontWeight: 400, fontFamily: "Inter" }}
          hidden
        >
          {properties.textplacing}
        </option>
        {Object.entries(properties.datatoselect).map(([key, value]) => {
        
          return (
            <option
              key={key}
              value={value}
              selected={properties.selectedvalue == value ? "selected" : false}
            >
              {value}
            </option>
          );
        })}
      </select>
      <span
        style={{
          marginLeft: "28px",
          color: "#EB5757",
          fontSize: "12px",
          fontFamily: "Inter",
          fontWeight: 400,
          lineHeight: "15px",
          wordWrap: "break-word",
        }}
      ></span>
    </div>
  );
}

export function Profile_edit_view_footer(properties) {
  const { errors, eduDetails } = properties;



  return (
    <div
      style={{
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 24,
        display: "inline-flex",
        marginTop: 24,
      }}
    >
      <button
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 12,
          paddingBottom: 12,
          background: "#0B6AEA",
          borderRadius: 8,
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          display: "flex",
        }}
        type="submit"
        onClick={properties.clickingsubmitbtn || properties.handleSubmit }
      >
        <div className="save-changes-btn">Save Changes</div>
      </button>

      <button className="discard-btn" onClick={properties.discard_editview}>
        <div className="discard-btn">Discard Changes</div>
      </button>
    </div>
  );
}

export function TechnicalCompetencies(props) {
  let softwareVehicleSkills = JSON.parse(localStorage.getItem('skillsData'))

  const { selectedSkill, discardSkills, toggleSkillSection } = props;
  const selectedvalueskills = selectedSkill ? selectedSkill : [];
  const [skillArr, setSkill] = useState(softwareVehicleSkills);
  const [selectedSkillArr, setSelectedSkillArr] = useState(selectedvalueskills);

  const chooseSkill = (event) => {
    const skillChoosed = event.target.value;
    const filtered = softwareVehicleSkills.filter(
      (each) => !selectedSkillArr.includes(each.skillName)
    );

    //setSkill([...filtered]);

    setSelectedSkillArr([...selectedSkillArr, skillChoosed]);
  };

  const removeSkill = (skill) => {
    const filterSelectedSkillArr = selectedSkillArr.filter(
      (each) => each !== skill
    );
   // setSkill([...skillArr, skill]);
    setSelectedSkillArr(filterSelectedSkillArr);
  };

  const saveSkills = () => {
   
    const { saveSkillData, toggleSkillSection } = props;
    saveSkillData(selectedSkillArr);
    toggleSkillSection();
  };

/*   useEffect(() => {
    const filtered = softwareVehicleSkills.filter(
      (each) => !selectedSkill.includes(each.skillName)
    );
    setSkill([...filtered]);
  }, []);
 */
  // const discardSkills = () => setSelectedSkillArr([])

  return (
    <div className="skill-section-container">
      <div className="skill-flexbox">
        <div className="skill-selection-flexbox">
          <div className="selected-option-flexbox">
            {/* <select
              disabled={selectedSkillArr.length >= 7}
              className="skill-input-section"
              onChange={chooseSkill}
            >
              {skillArr.map((each) => (
                <option
                  disabled={each === "Select a Option"}
                  selected={each === "Select a Option"}
                >
                  {each}
                </option>
              ))}
            </select> */}

            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Select Your Skills
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={selectedSkillArr.length >= 7}
                label="Select Your Skills"
                placeholder="Select a Option"
                fullWidth
                sx={{
                  width: "350px",
                }}
                onChange={chooseSkill}
              >
                {skillArr.map((each) => (
                  <MenuItem
                    key={each.skillName}
                    disabled={selectedSkillArr.includes(each.skillName)}
                    value={each.skillName}
                  >
                    {each.skillName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <ul className="skill-selected-section">
            {selectedSkillArr.map((each) => (
              <li key={each} className="skill-selected">
                <p className="skill-text">{each}</p>{" "}
                <IoClose onClick={() => removeSkill(each)} />
              </li>
            ))}
          </ul>
        </div>
        <Profile_edit_view_footer
          clickingsubmitbtn={saveSkills}
          discard_editview={toggleSkillSection}
        />
      </div>
    </div>
  );
}

export function TechnicalCompetenciesSelected(props) {
  const { selectedSkill } = props;

  return (
    <div className="skill-section-container">
      <div className="skill-flexbox">
        <div className="skill-selection-flexbox">
          <ul className="skill-selected-section-view">
            {selectedSkill.map((each) => (
              <li key={each} className="skill-selected">
                <p className="skill-text">{each}</p>{" "}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export function Addlanguages(properties) {
  const listproficiency = {
    Basic: "Basic",
    Intermediate: "Intermediate",
    Fluent: "Fluent",
    Native: "Native",
  };
  const listoflanguages = {
    English: "English",
    తెలుగు: "తెలుగు",
    தமிழ்: "தமிழ்",
    हिन्दी: "हिन्दी",
  };

  const isEnglishPresent = properties.profileData.Languages.some(
    (each) => each.Language === "English"
  );
  const isHindiPresent = properties.profileData.Languages.some(
    (each) => each.Language === "हिन्दी"
  );
  const isTamilPresent = properties.profileData.Languages.some(
    (each) => each.Language === "தமிழ்"
  );
  const isTeluguPresent = properties.profileData.Languages.some(
    (each) => each.Language === "తెలుగు"
  );

  const [selectedLanguage, setSelectedLanguage] = useState("");

  let filteredLanguages = Object.keys(listoflanguages).filter(
    (language) => !isEnglishPresent
  );

  filteredLanguages = Object.keys(listoflanguages).filter(
    (language) => !isHindiPresent
  );
  filteredLanguages = Object.keys(listoflanguages).filter(
    (language) => !isTamilPresent
  );
  filteredLanguages = Object.keys(listoflanguages).filter(
    (language) => !isTamilPresent
  );



  const chooseLanguage = (event) => {
    const languageChosen = event.target.value;
    setSelectedLanguage(languageChosen);
  };

  const deleteLanguage = (index) => {

    properties.setLanguageCount((prev) => prev - 1);
    properties.setProfileData((prev) => {
      const newData = prev.Languages;
      const filteredData = newData.splice(index, 1);
      return { ...prev, Language: filteredData };
    });
  };

  return (
    <div
      style={{
        width: "100%",
        alignSelf: "stretch",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "40px",
        display: "inline-flex",
      }}
    >
      <div
        style={{
          flex: "1 1 0",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 4,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            width: "100%",
            alignSelf: "stretch",

            borderRadius: "12px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
            paddingTop: 0,
          }}
        >
          <label
            id={"log_lab_language"}
            style={{
              marginTop: "-7px",
              position: "absolute",
              display: "none",
              color: "#0B6AEA",
              marginLeft: "20px",
              zIndex: 1,
              fontSize: "12px",
              padding: "0 8px",
              background: "white",
              fontFamily: "Inter",
              fontWeight: 400,
              lineHeight: "15px",
              wordWrap: "break-word",
            }}
          >
            Language
          </label>
          <select
            className="log_btn_hover"
            style={{
              padding: "12px 20px",
              alignSelf: "stretch",
              color: "#80919F",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              paddingTop: "16px",
              paddingBottom: "16px",
              background: "white",
              borderRadius: "12px",
              border: "1px #E2EBF3 solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              display: "flex",
            }}
            type="text"
            id="Language"
            name="Language"
            key={properties.currentid}
            currentid={properties.currentid}
            onChange={properties.valuechange}
            placeholder="Language"
            required
            autoComplete="off"
          >
            <option
              value=""
              style={{ fontSize: 14, fontWeight: 400, fontFamily: "Inter" }}
              hidden
            >
              Language
            </option>
            {Object.entries(listoflanguages).map(([key, value]) => (
              <option
                key={key}
                value={value}
                selected={
                  properties.selectedvalue.Language === value ? value : false
                }
                disabled={properties.profileData.Languages.some(
                  (each) => each.Language === value
                )}
              >
                {value}
              </option>
            ))}
          </select>
          <span
            style={{
              marginLeft: "28px",
              color: "#EB5757",
              fontSize: "12px",
              fontFamily: "Inter",
              fontWeight: 400,
              lineHeight: "15px",
              wordWrap: "break-word",
            }}
          ></span>
        </div>
      </div>

      <div
        style={{
          flex: "1 1 0",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 4,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            width: "100%",
            alignSelf: "stretch",
            borderRadius: "12px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
            paddingTop: 0,
          }}
        >
          <label
            id={"log_lab_Proficiency"}
            style={{
              marginTop: "-7px",
              position: "absolute",
              display: "none",
              color: "#0B6AEA",
              marginLeft: "20px",
              zIndex: 1,
              fontSize: "12px",
              padding: "0 8px",
              background: "white",
              fontFamily: "Inter",
              fontWeight: 400,
              lineHeight: "15px",
              wordWrap: "break-word",
            }}
          >
            Proficiency
          </label>
          <select
            className="log_btn_hover"
            style={{
              padding: "12px 20px",
              alignSelf: "stretch",
              color: "#80919F",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              paddingTop: "16px",
              paddingBottom: "16px",
              background: "white",
              borderRadius: "12px",
              border: "1px #E2EBF3 solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              display: "flex",
            }}
            type="text"
            id="Proficiency"
            name="Proficiency"
            placeholder="Proficiency"
            currentid={properties.currentid}
            onChange={properties.valuechange}
            required
            autoComplete="off"
          >
            <option
              value=""
              style={{ fontSize: 14, fontWeight: 400, fontFamily: "Inter" }}
              hidden
            >
              Proficiency
            </option>
            {Object.entries(listproficiency).map(([key, value]) => (
              <option
                key={key}
                value={value}
                selected={
                  properties.selectedvalue.Proficient === value ? value : false
                }
              >
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div
        style={{
          width: 32,
          height: 32,
          position: "relative",
          alignSelf: "center",
        }}
      >
        <img
          src={delete_details}
          onClick={() => deleteLanguage(properties.currentid)}
          style={{ width: 32, height: 32, position: "relative" }}
        />
      </div>
    </div>
  );
}
