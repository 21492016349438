import { useState, useEffect } from "react";
import Styles from "./personalDetails.module.css";
import EditIcon from "../../../assets/edit_icon.svg";
import { Details } from "./Details";
import Verifyotpmodel from "../../verifyotpmodel";
import axios from "axios";

export const PersonalDetails = ({
  savePersonalToKeycloak,
  profileData,
  refid,
  setProfileData,
  handleSubmit,
  fieldWisePercentage
}) => {
  const [editMode, setEditMode] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpsent, setOtpSent] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [isOtpModalOpen, setOtpModalOpen] = useState(false);
  const [unverifiedOTP, setUnverifiedOTP] = useState({ type: "", message: "" });
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const nodeapiBaseUrl = process.env.REACT_APP_NODEJS_TS;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [phoneNumber1, setPhoneNumber1] = useState("");
  const [enteredOTP, setEnteredOTP] = useState("");
  const [enteredNumber, setEnteredNumber] = useState("");

  const handleShowErrorModal = (errorMessage) => {
    setModalErrorMessage(errorMessage);
    setShowErrorModal(true);
  };

  const handleSendOTP = async () => {
    const mobileNumber =
      enteredNumber.length === 10 ? `+91${enteredNumber}` : `${enteredNumber}`;

   
    try {
      const response = await axios.post(`${nodeapiBaseUrl}/send-sms-otp`, {
        phone: mobileNumber,
      });
      setOtpSent(true);

      setPhoneNumber1(mobileNumber); // Update the state after successful API call
      handleShowErrorModal(response.data.message); // Assuming this is for showing success message
      setShowVerifyButton(false);
    } catch (error) {
      console.error("Error sending OTP:", error);
      // If the error response is from the server
      if (error.response) {
        console.log("Response data:", error.response.data);
        console.log("Response status:", error.response.status);
        console.log("Response headers:", error.response.headers);
      }
      handleShowErrorModal(error.message);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await axios.post(`${nodeapiBaseUrl}/verify-sms-otp`, {
        phone: phoneNumber1,
        otp: enteredOTP,
      });

    
      // Add logic to handle successful OTP verification, such as updating UI or redirecting
      if (response.data.isOTPValid) {
        setOtpVerified(true);
        setOtpModalOpen(false);
        setProfileData({
          ...profileData,
          isOTPValidated: true,
          isPhoneValidated: true,
          phoneNumber: enteredNumber,
        });
        setInterval(() => {
          window.location.reload();
        }, 1500);
        setUnverifiedOTP({ type: "", message: "" });
        return true;
        // Additional actions on successful verification
      } else {
        setOtpVerified(false);
        setOtpModalOpen(true);
        setUnverifiedOTP({
          type: "Mobile OTP",
          message: "Mobile OTP verification failed. Please try again.",
        }); // Set error message for mobile OTP verification failure
        // Additional actions on failed verification
        return false;
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
  };
  const [personalDetails, setPersonalDetails] = useState({
    ...profileData,
  });

  const handleEditMode = () => {
    setEditMode(!editMode);
  };
 
  useEffect(() => {
    handleSubmit();
  }, [profileData]);
  return (
    <div className="personal-details-container" style={editMode ? { boxShadow: '0px 8px 40px rgba(9, 44, 76, 0.08)' } : {}}  ref={refid} id="personalid">
      <div className={Styles.personal_details}>
        <h1 className={Styles.personal_details_title}>Personal Details</h1>

        {!editMode && (
          <div className={Styles.edit_details_button} onClick={handleEditMode}>
            <p className={Styles.edit_para}>
              <span>Edit Details</span>
            </p>
            <img className={Styles.editIcon} src={EditIcon} alt="edit" />
          </div>
        )}
       
      </div>
      {!editMode && <p className={Styles.personal_completion_text } >{ fieldWisePercentage.personal}</p>}
      <Details
        personalDetails={{ ...profileData }}
        profileData={profileData}
        setProfileData={setProfileData}
        setPersonalDetails={setPersonalDetails}
        isOTPValidated={profileData.isPhoneValidated}
        editMode={editMode}
        setEditMode={setEditMode}
        savePersonalToKeycloak={savePersonalToKeycloak}
        handleSendOTP={handleSendOTP}
        setEnteredNumber={setEnteredNumber}
      />

      {showErrorModal && (
        <Verifyotpmodel
          show={showErrorModal}
          handleCloseModal={() => setShowErrorModal(false)}
          handleVerify={handleVerifyOTP}
          handleResendOTP={handleSendOTP}
          enteredOTP={enteredOTP}
          setEnteredOTP={setEnteredOTP}
          errorMessage={unverifiedOTP}
          setOtpVerified={setOtpVerified}
        />
      )}
    </div>
  );
};
