import {useState,useEffect,useRef} from "react";

const SideTab = ({selectedTab2,setselectedTab2}) => {
  const [activeSection, setActiveSection] = useState("Personal Details");

  const sectionRefs = {
    personalid: useRef(null),
    educationid: useRef(null),
    workexperienceid: useRef(null),
    interestid: useRef(null),
    certificateid: useRef(null),
    // Add more sections as needed
  };

  const tabClickHandler2 = (tabName) => {
    setselectedTab2(tabName);
    scrollToSection(tabName);
  };


  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "end",
      });
      setselectedTab2(sectionId);
    }
  };

    //To move the left panel topic at the time of scroll
    useEffect(() => {
      const handleScroll = () => {
        for (const section in sectionRefs) {
        
          const target = sectionRefs[section].current;
          if (target) {
            const rect = target.getBoundingClientRect();
            if (
              rect.top <= window.innerHeight / 2 &&
              rect.bottom >= window.innerHeight / 2
            ) {
              setselectedTab2(section);
            
              break;
            }
          }
        }
      };

      // Attach the event listener when the component mounts
      window.addEventListener("scroll", handleScroll);

      // Detach the event listener when the component unmounts
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [sectionRefs]); // Include sectionRefs in the dependency array

    return (
        <div
        className=" col-md-3"
        style={{ paddingTop: 15, margin: 0, padding: 0, paddingLeft: 32 }}
      >
        <div
          className={
            window.innerWidth <= 576
              ? "hide-for-mobile"
              : "desktopview_sticky "
          }
        >
          <div
            style={{
              height: 653,
              paddingTop: 20,
              paddingBottom: 48,
              paddingLeft: 8,
              paddingRight: 8,
              background: "white",
              borderRadius: 10,
              border: "1px #E2EBF3 solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 32,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                paddingLeft: 12,
                paddingRight: 12,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "#00213D",
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                Quick Links
              </div>
            </div>

            <div
              style={{
                alignSelf: "stretch",
                height: 350,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 8,
                display: "flex",
              }}
            >
              <nav class="navigation">
                <ul style={{ paddingLeft: 0 }}>
                  <li
                    style={{
                      listStyle: "none",
                      display: "inline-flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      onClick={() => tabClickHandler2("personalid")}
                      className={
                        selectedTab2 == "personalid"
                          ? "activeprofiletab"
                          : "none"
                      }
                    >
                      {" "}
                      <div
                        className={`highlightable-field ${
                          activeSection === "Personal Details" ? "active" : ""
                        }`}
                        style={{
                          width: "100%",
                          alignSelf: "stretch",
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 12,
                          paddingBottom: 12,
                          borderRadius: 4,
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <span
                          style={{
                            flex: "1 1 0",
                            color: "#00213D",
                            fontSize: 16,
                            fontFamily: "Inter",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Personal Details
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => tabClickHandler2("educationid")}
                      className={
                        selectedTab2 == "educationid"
                          ? "activeprofiletab"
                          : "none"
                      }
                    >
                      {" "}
                      <div
                        className={`highlightable-field ${
                          activeSection === "Education Details"
                            ? "active"
                            : ""
                        }`}
                        style={{
                          width: "100%",
                          alignSelf: "stretch",
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 12,
                          paddingBottom: 12,
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <span
                          style={{
                            flex: "1 1 0",
                            color: "#00213D",
                            fontSize: 16,
                            fontFamily: "Inter",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Education Details
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => tabClickHandler2("workexperienceid")}
                      className={
                        selectedTab2 == "workexperienceid"
                          ? "activeprofiletab"
                          : "none"
                      }
                    >
                      {" "}
                      <div
                        className={`highlightable-field ${
                          activeSection === "Work Experience" ? "active" : ""
                        }`}
                        style={{
                          width: "100%",
                          alignSelf: "stretch",
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 12,
                          paddingBottom: 12,
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <span
                          style={{
                            flex: "1 1 0",
                            color: "#00213D",
                            fontSize: 16,
                            fontFamily: "Inter",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Work Experience / Internship Details
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => tabClickHandler2("interestid")}
                      className={
                        selectedTab2 == "interestid"
                          ? "activeprofiletab"
                          : "none"
                      }
                    >
                      {" "}
                      <div
                        className={`highlightable-field ${
                          activeSection === "Interests" ? "active" : ""
                        }`}
                        style={{
                          width: "100%",
                          alignSelf: "stretch",
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 12,
                          paddingBottom: 12,
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <span
                          style={{
                            flex: "1 1 0",
                            color: "#00213D",
                            fontSize: 16,
                            fontFamily: "Inter",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Technical Competencies
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => tabClickHandler2("certificateid")}
                      className={
                        selectedTab2 == "certificateid"
                          ? "activeprofiletab"
                          : "none"
                      }
                    >
                      {" "}
                      <div
                        className={`highlightable-field ${
                          activeSection === "Certifications" ? "active" : ""
                        }`}
                        style={{
                          width: "100%",
                          alignSelf: "stretch",
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 12,
                          paddingBottom: 12,
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <span
                          style={{
                            flex: "1 1 0",
                            color: "#00213D",
                            fontSize: 16,
                            fontFamily: "Inter",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Certifications
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    )
}

export default SideTab