import avatar from "../../assets/img/Avatar.svg";
import add_details from "../../assets/img/add_details.svg";
import delete_details from "../../assets/img/delete_details.svg";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import dateFormat from "dateformat";
import ProfileImageUpload from "./ProfileImageUpload";
import SideTab from "./SideTab";
import Pencil from "../../assets/img/Pencil.svg";
import { CircularProgressbar } from "react-circular-progressbar";
// import UpArrowIcon from '../../assets/img/UpArrowIcon.jpg';
import "./profile.css";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Verifyotpmodel from "../verifyotpmodel";
import axios from "axios";
import qs from "qs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import BreadCrumbCCIcon from "./CustomIcons/BreadCrumbCCIcon";
import BreadCrumbNextIcon from "./CustomIcons/BreadCrumbNextIcon";
import UserService from "../../services/UserService";
import { PersonalDetails } from "./PersonalDetails/PersonalDetails";
import Keycloak_config from "../../services/Keycloak_config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Journey from "../Platform/Journey/Jounney";
import Describing from "../Platform/Journey/Describing";

import WorkExperience from "./WorkExperience/WorkExperience";
import EducationField from "./Education/EducationField";
import TechnicalCompetenciesSection from "./TechnicalCompetencies/TechnicalCompetenciesSection";
import Certificates from "./Certificates/Certificates";

import { Addlanguages } from "./Profile_components";

function Profile_page() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const profilePicUrl = process.env.REACT_APP_NODEJS_TS;

  const [countryCodes, setCountryCodes] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  let content = "";
  const [keyCloakUserId, setKeyCloakUserId] = useState(null);
  const [percentage, setProfilePercentage] = useState("0");
  const [isEduEditMode, setIsEduEditMode] = useState(false);
  const [isWorkEditMode, setIsWorkEditMode] = useState(false);
  const [isIntEditMode, setIsIntEditMode] = useState(false);
  const [isCertiEditMode, setIsCertiEditMode] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [enteredOTP, setEnteredOTP] = useState("");

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const [croppedImage, setCroppedImage] = useState(null);

  const [backupData, setBackupData] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
  const [phoneNumber1, setPhoneNumber1] = useState("");
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [selectedTab, setSelectedTab] = useState("default");
  const [selectedContent, setContentChange] = useState();
  const personalDetailsRef = useRef(null);
  const editorComponentRef = useRef(null);
  const educationDetailsRef = useRef(null);
  const workExperienceRef = useRef(null);
  const interestsRef = useRef(null);
  const certificationsRef = useRef(null);
  const [unverifiedOTP, setUnverifiedOTP] = useState({ type: "", message: "" });
  const [otpsent, setOtpSent] = useState(false);
  const [isOtpModalOpen, setOtpModalOpen] = useState(false);

  const [isEditModegap, setEditModegap] = useState("24");
  const [education_action, seteducation_action] = useState("view");
  const [education_editid, seteducation_editid] = useState(null);
  const [workexperience_action, setworkexperience_action] = useState("view");
  const [workexperience_editid, setworkexperience_editid] = useState(null);
  const [cert_action, setcert_action] = useState("view");
  const [cert_editid, setcert_editid] = useState(null);
  const [addlanguagedatacount, setaddlanguagedata] = useState([]);
  const [educationtypevalue, seteducationtypevalue] = useState(0);
  const [allowsubmission, setsubmission] = useState(0);
  const [sentences, setSentences] = useState([<Addlanguages />]);
  const [LanguageCount, setLanguageCount] = useState(1);

  const [image, setImage] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [editedImage, setEditedImage] = useState(null);

  const [picLoaded, setPicLoaded] = useState(false);
  const [tempProfileData, setTempProfileData] = useState(null);
  const [scale, setScale] = useState(1);
  const editorRef = useRef(null);
  const fileRef = useRef(null);
  const [bothNumSame, setBothNumSame] = useState(true);
  const [AmazonKey, setAmazonKey] = useState(null);
  const [selectedTab2, setselectedTab2] = useState("personalid");
  const [fieldWisePercentage, setFieldWisePercentage] = useState({});
  let AWSS3Key = undefined;
  const location = useLocation();

  const showRespectedSentence = () => {
    const respectedSentence = <Addlanguages />;
    setSentences((prevSentences) => [...prevSentences, respectedSentence]);
  };
  const sectionRefs = {
    personalid: useRef(null),
    educationid: useRef(null),
    workexperienceid: useRef(null),
    interestid: useRef(null),
    certificateid: useRef(null),
    // Add more sections as needed
  };

  const [activeSection, setActiveSection] = useState("Personal Details");

  /* const sectionRefs = {
    personalid: useRef(null),
    educationid: useRef(null),
    workexperienceid: useRef(null),
    interestid: useRef(null),
    certificateid: useRef(null),
    // Add more sections as needed
  }; */

  const tabClickHandler2 = (tabName) => {
    setselectedTab2(tabName);
    scrollToSection(tabName);
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  /*  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "end",
      });
      setselectedTab2(sectionId);
    }
  };
 */
  //To move the left panel topic at the time of scroll

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedTab2FromUrl = searchParams.get("scrollTo");

    if (selectedTab2FromUrl) {
      // setselectedTab2(selectedTab2FromUrl);
      scrollToSection(selectedTab2FromUrl);
    }
  }, [location.search]);

  useEffect(() => {
    const handleScroll = () => {
      for (const section in sectionRefs) {
        const target = sectionRefs[section].current;
        if (target) {
          const rect = target.getBoundingClientRect();
          if (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          ) {
            setselectedTab2(section);

            break;
          }
        }
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionRefs]); // Include sectionRefs in the dependency array

  //On the time of clicking edit button
  const editablebtnclick = (index) => {
    setBackupData({ ...profileData.workexperience[index] });
    setworkexperience_action("edit");
    setworkexperience_editid(index);
  };
  //On the time of clicking edit button
  const editablebtnclick3 = (index) => {
    seteducation_action("edit");
    setBackupData({ ...profileData.educationaldetails[index] });
    seteducation_editid(index);
  };
  //On the time of clicking delete button
  const deleteablebtnclick = (delete_index) => {
    const filteredData = profileData.workexperience.filter(
      (item, index) => index !== delete_index
    );
    setProfileData({ ...profileData, workexperience: filteredData });
    setsubmission(1);
  };
  const deleteablebtnclick3 = (delete_index) => {
    const filteredData = profileData.educationaldetails.filter(
      (item, index) => index !== delete_index
    );
    setProfileData({ ...profileData, educationaldetails: filteredData });
    setsubmission(1);
  };

  const editablebtnclick2 = (index) => {
    setBackupData({ ...profileData.certificate[index] });
    setworkexperience_action("view");
    setcert_action("edit");
    setcert_editid(index);
  };
  const deleteablebtnclick2 = (delete_index) => {
    const filteredData = profileData.certificate.filter(
      (item, index) => index !== delete_index
    );
    setProfileData({ ...profileData, certificate: filteredData });
    setsubmission(1);
  };
  //On click on discard btn change the state to view
  const discardfunction = () => {
    const newData = [...profileData.workexperience];
    if (backupData && workexperience_editid !== null) {
      newData[workexperience_editid] = { ...backupData };
    }
    setProfileData({ ...profileData, workexperience: newData });
    setworkexperience_action("view");
    setBackupData(null);
    setworkexperience_action("view");
  };
  const discardfunction2 = () => {
    const newData = [...profileData.certificate];
    if (backupData && cert_editid !== null) {
      newData[cert_editid] = { ...backupData };
    }

    setProfileData({ ...profileData, certificate: newData });
    setBackupData(null);
    setcert_action("view");
  };
  const discardfunction3 = () => {
    const newData = [...profileData.educationaldetails];
    if (backupData && education_editid !== null) {
      newData[education_editid] = { ...backupData };
    }
    setProfileData({ ...profileData, educationaldetails: newData });
    setBackupData(null);
    seteducation_action("view");
  };

  //scroll to particular section at the time of clicking topic name
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "end",
      });
      setselectedTab2(sectionId);
    }
  };

  /* else {
      // For other inputs (firstname, lastname, email, etc.), update as usual
      setProfileData({ ...profileData, [name]: value });
    } */

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleworkexperirnceadata = (e) => {
    const { name, value } = e.target;
    setemptydata({ ...emptydata, [name]: value });
  };
  const handleeducationdata = (e) => {
    const { name, value } = e.target;
    setemptydata3({ ...emptydata3, [name]: value }); //changeeducationform
  };

  const handlecertdata = (e) => {
    const { name, value } = e.target;
    setemptydata2({ ...emptydata2, [name]: value });
  };
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;

    setProfileData((profileData) => {
      const newData = { ...profileData };
      newData.workexperience[workexperience_editid][name] = value ?? {};
      return newData;
    });
  };

  const handleInputChange4 = (e) => {
    const { name, value } = e.target;
    // Ensure that profileData.workexperience is initialized as an array
    setProfileData((profileData) => {
      const newData = { ...profileData };
      newData.educationaldetails[education_editid][name] = value ?? {};
      return newData;
    });
  };
  const handleInputChange3 = (e) => {
    const { name, value } = e.target;

    setProfileData((profileData) => {
      const newData = { ...profileData };
      newData.certificate[cert_editid][name] = value ?? {};
      return newData;
    });
  };

  const handledateInputChange3 = (name, date) => {
    const value = date;

    setProfileData((profileData) => {
      const newData = { ...profileData };
      newData.certificate[cert_editid][name] = value ?? {};
      return newData;
    });
  };

  const handleEduEditDetailsClick = () => {
    setIsEduEditMode(true);
  };
  const handleWorkEditDetailsClick = () => {
    setIsWorkEditMode(true);
  };

  const handleIntEditDetailsClick = () => {
    setIsIntEditMode(true);
  };
  const handleCertiEditDetailsClick = () => {
    setIsCertiEditMode(true);
  };

  // image to url conversion

  function urlToImage(url, callback) {
    var img = new Image();
    img.onload = function () {
      callback(img);
    };
    img.src = url;
  }

  // Function to close the error modal
  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const [emptydata2, setemptydata2] = useState({
    cetification_provider_name: "",
    certificatio_course_name: "",
    certification_date: "",
    certificate_url: "",
  });
  const [emptydata, setemptydata] = useState({
    employertype: "",
    employmenttype: "",
    companyname: "",
    designation: "",
    joiningdate: "",
    lastworkingday: "",
    jobdescription: "",
  });
  const [emptydata3, setemptydata3] = useState({
    educationLevel: "",
    boardOrg: "",
    schoolMedium: "",
    startDate: "",
    endDate: "",
    mark: "",
  });

  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    nationality: "",
    state: "",
    phoneNumber: "",
    permanentAddress: "",
    pinCode: "",
    gender: "",
    dob: null,
    languages: [],
    aboutYou: "",
    linkedinProfileUrl: "",
    educationaldetails: [],
    workexperience: [],
    certificate: [],
    technicalSkills: [],
  });

  const [dataLoaded, setDataLoaded] = useState(false);
  const [skillSectionEditState, setSkillSectionEditState] = useState(false);

  const returnbackskillsnormal = () => {
    setSkillSectionEditState(false);
  };
  const email = UserService.getUsername();
  const [registrationStatus, setRegistrationStatus] = useState(null);

  const handleadddateInputChange3 = (name, date) => {
    // const  name ="joiningdate";
    const value = date;
    setemptydata2({ ...emptydata2, [name]: date });
  };
  // Use apiBaseUrl in your code

  // Define the getUserIdByEmail function within the Profile component

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [userGender, setUserGender] = useState("");

  useEffect(() => {
    try {
      // Use the obtained access token to fetch user profile data
      axios
        .get(
          `${apiBaseUrl}/admin/realms/${
            process.env.REACT_APP_KEYCLOAK_REALM_NAME
          }/users/${UserService.getUserId()}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((profileResponse) => {
          const userProfile = profileResponse.data;

          //fetching profile percentage value from backend:

          const requestData = {
            userId: localStorage.getItem("userId"),
          };
          axios
            .post(
              `${profilePicUrl}/get-profile-percentage`,
              qs.stringify(requestData),
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then(async (response) => {
              let percentage_value = response.data.percentage
                ? response.data.percentage.slice(0, -1)
                : "0";
              setProfilePercentage(percentage_value); //
              setFieldWisePercentage({
                work: response.data.work,
                personal: response.data.personal,
                education: response.data.education,
                technicalSkills: response.data.technicalSkills,
                certificates: response.data.certificates,
              });
            })
            .catch((error) => {
              // Handle error
              console.error("Response Error:", error);
            });
        });
    } catch (error) {
      console.error("Axios Error", error);
    }
  }, [profileData, registrationStatus]);

  useEffect(() => {
    if (!dataLoaded) {
      // Define your Keycloak client credentials and token URL

      // Make a POST request to obtain an access token
      try {
        // Use the obtained access token to fetch user profile data
        axios
          .get(
            `${apiBaseUrl}/admin/realms/${
              process.env.REACT_APP_KEYCLOAK_REALM_NAME
            }/users/${localStorage.getItem("userId")}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          )
          .then((profileResponse) => {
            const userProfile = profileResponse.data;
            //fetching profile percentage value from backend:
            AWSS3Key = userProfile.attributes["AWSKey"]
              ? userProfile.attributes["AWSKey"][0]
              : null;

            userProfile.attributes["isPhoneValidated"]
              ? setOtpVerified(
                  userProfile.attributes["isPhoneValidated"][0] === "true"
                )
              : setOtpVerified(false);

            setProfileData({
              firstname: userProfile.firstName,
              lastname: userProfile.lastName,
              AWSKey: userProfile.attributes["AWSKey"]
                ? userProfile.attributes["AWSKey"][0]
                : null,

              // username: userProfile.username,
              email: userProfile.email,
              //password: "", // Password should not be fetched or displayed
              city: userProfile.attributes
                ? userProfile.attributes["city"]?.[0] || ""
                : "",

              aboutYou: userProfile.attributes
                ? userProfile.attributes["aboutYou"]?.[0] || ""
                : "",

              nationality: userProfile.attributes
                ? userProfile.attributes["nationality"]?.[0] || ""
                : "",

              state: userProfile.attributes
                ? userProfile.attributes["state"]?.[0] || ""
                : "",

              phoneNumber: userProfile.attributes
                ? userProfile.attributes["phoneNumber"]?.[0] || ""
                : "",

              isPhoneValidated: userProfile.attributes["isPhoneValidated"]
                ? userProfile.attributes["isPhoneValidated"][0] === "true"
                : false,

              permanentAddress: userProfile.attributes
                ? userProfile.attributes["Permanent Address"]?.[0] || ""
                : "",

              gender: userProfile.attributes
                ? userProfile.attributes["gender"]?.[0] || ""
                : "",

              languages:
                userProfile.attributes && userProfile.attributes["languages"]
                  ? JSON.parse(userProfile.attributes["languages"])
                  : [],

              pinCode:
                userProfile.attributes && userProfile.attributes["pinCode"]
                  ? userProfile.attributes["pinCode"][0]
                  : "",

              dob:
                userProfile.attributes && userProfile.attributes["dob"]
                  ? userProfile.attributes["dob"]
                  : "",

              educationaldetails:
                userProfile.attributes &&
                userProfile.attributes["educationaldetails"]
                  ? JSON.parse(userProfile.attributes["educationaldetails"])
                  : [],
              linkedinlink:
                userProfile.attributes && userProfile.attributes["linkedinlink"]
                  ? userProfile.attributes["linkedinlink"][0]
                  : "",
              technicalSkills:
                userProfile.attributes &&
                userProfile.attributes["technicalSkills"]
                  ? JSON.parse(userProfile.attributes["technicalSkills"])
                  : [],
              workexperience:
                userProfile.attributes &&
                userProfile.attributes["workexperience"]
                  ? JSON.parse(userProfile.attributes["workexperience"])
                  : [],
              certificate:
                userProfile.attributes && userProfile.attributes["certificate"]
                  ? JSON.parse(userProfile.attributes["certificate"])
                  : [],
            });

            const requestData = {
              userId: localStorage.getItem("userId"),
            };

            const formData = new FormData();

            setAmazonKey(
              userProfile.attributes["AWSKey"]
                ? userProfile.attributes["AWSKey"][0]
                : null
            );

            // formData.append("key", userProfile.attributes['AWSKey'][0]);
            // Getting profile picture details

            axios
              .post(
                `${profilePicUrl}/get-profile-percentage`,
                qs.stringify(requestData),
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then(async (response) => {
                let percentage_value = response.data.percentage
                  ? response.data.percentage.slice(0, -1)
                  : "0";
                setProfilePercentage(percentage_value); //
                setFieldWisePercentage({
                  work: response.data.work,
                  personal: response.data.personal,
                  education: response.data.education,
                  technicalSkills: response.data.technicalSkills,
                });
              })
              .catch((error) => {
                // Handle error
                console.error("Response Error:", error);
              });

            // Check the structure of userProfile.attributes

            setFName(userProfile.firstName);
            setLName(userProfile.lastName);

            setUserGender(userProfile.attributes.Gender);
            setDataLoaded(true);
            const myvals = JSON.parse(userProfile.attributes["Languages"]);
            setLanguageCount(myvals.length);
            // Getting profile picture details
          })
          .catch((error) => {
            console.error("Error fetching user profile:", error);
          });
      } catch (error) {
        console.error("Axios Error", error);
      }
    }
  }, [dataLoaded, UserService, picLoaded]);

  useEffect(() => {
    handleSubmit();
  }, [AmazonKey]);

  useEffect(() => {
    if (registrationStatus === "success") {
      // Registration successful, you can redirect or perform other actions.
    }
  }, [registrationStatus]);

  const handleSubmit = (e) => {
    let collegeNameForMoodl = "";
    let branchNameForMoodl = "";

    profileData.educationaldetails.forEach((element) => {
      if (element.educationLevel === "Graduation") {
        collegeNameForMoodl = element.collegeName;
        branchNameForMoodl = element.specalizations;
      }
    });

    const registrationData = {
      attributes: {
        AWSKey: profileData.AWSKey,
        "Personal Email": profileData.personalEmail,
        phoneNumber: profileData.phoneNumber,
        isPhoneValidated: profileData.isPhoneValidated,
        "Permanent Address": profileData.permanentAddress,
        pinCode: profileData.pinCode,
        gender: profileData.gender,
        dob: profileData.dob,
        nationality: profileData.nationality,
        state: profileData.state,
        aboutYou: profileData.aboutYou,
        Institution: collegeNameForMoodl,
        Department: branchNameForMoodl,
        city: profileData.city,
        educationaldetails: JSON.stringify(profileData.educationaldetails),
        workexperience: JSON.stringify(profileData.workexperience),
        certificate: JSON.stringify(profileData.certificate),
        linkedinlink: profileData.linkedinlink,
        technicalSkills: JSON.stringify(profileData.technicalSkills),
        languages: JSON.stringify(profileData.languages),
      },

      // username: profileData.username,
      firstName: profileData.firstname,
      lastName: profileData.lastname,
      // firstName: "test",
      // lastName: "testtt",
      email: profileData.email,
      emailVerified: true,
      enabled: true,
    };

    try {
      axios
        .put(
          `${apiBaseUrl}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/users/` +
            UserService.getUserId(),
          registrationData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
          }
        ).then((response) => {
          console.log(response, 'just to clear warning')
          
          axios.post(`${process.env.REACT_APP_NODEJS_TS}/update-profile-details`, {
            
            ...registrationData.attributes,email:registrationData.email
          })
        })
        .catch((error) => {
          //   window.alert('Error updating profile:', error);
          console.error("Error updating profile:", error);
        });
    } catch (error) {
      console.log("Axios Error: ", error);
    }
  };
  const handleSkillEditState = () => {
    setSkillSectionEditState(!skillSectionEditState);
  };

  const handlecertsubmit = () => {
    const certProviderName = document.getElementById(
      "input-cetification_provider_name"
    );
    const certCourseName = document.getElementById(
      "input-certification_course_name"
    );
    const certURL = document.getElementById("input-certificate_url");
    const certDate = document.getElementById("dateEl-certification_date");

    if (
      certProviderName !== "" &&
      certCourseName.value !== "" &&
      certURL.value !== "" &&
      certDate.value !== ""
    ) {
      setsubmission(1);
      //handleSubmit();
      setBackupData(null);
      setcert_action("view");

      setemptydata([]);
      setemptydata2([]);
      setemptydata3([]);
    }
  };

  const saveSkillData = (dataArr) => {
    setProfileData({ ...profileData, technicalSkills: dataArr });
    setsubmission(1);
  };

  const checkingvalues = (e) => {
    const { value, name, id } = e.target;
    const customfunction = e.target.getAttribute("functionnamecall");
    const validationarea = e.target.getAttribute("validationarea");

    if (
      validationarea === "alphaonly" &&
      /^[a-zA-Z]{1,24}(?: [a-zA-Z]{1,24})?$/.test(value) === false
    ) {
      document.getElementById(id).value = value.substring(0, value.length - 1);
      /*document.getElementById(id).value = prevValue.slice(0, -1); */
    } else if (validationarea == "limitrangeofmark") {
      const numberInput = document.getElementById(e.target.id);

      numberInput.addEventListener("input", function () {
        let value = parseInt(this.value);
        // Ensure it's a number
        if (isNaN(value)) {
          this.value = ""; // Clear the input if not a number
        } else {
          // Enforce the limit
          if (value > 100) {
            this.value = 100;
          } else if (value < 0) {
            this.value = 0;
          }
        }
      });
    } else if (validationarea === "cgpa") {
      const numberInput = document.getElementById(e.target.id);

      numberInput.addEventListener("blur", function () {
        // Apply the updated CGPA regex pattern
        if (!/^10(\.0{1,2})?$|^[0-9](\.[0-9]{1,2})?$/.test(this.value)) {
          document.getElementById(id).value = value.substring(
            0,
            value.length - 1
          );
        }
      });
    } else if (validationarea === "pincode") {
      const numberInput = document.getElementById(e.target.id);

      numberInput.addEventListener("input", function () {
        // Apply the updated CGPA regex pattern
        if (/^[0-9]{7}$/.test(value)) {
          this.value = ""; // Clear the input if not a valid CGPA
        }
      });
    } else if (validationarea === "address") {
      const numberInput = document.getElementById(e.target.id);

      numberInput.addEventListener("input", function () {
        // Apply the updated CGPA regex pattern
        if (/^[a-zA-Z0-9,]{1,50}$/.test(value)) {
          this.value = ""; // Clear the input if not a valid CGPA
        }
      });
    }

    if (customfunction == "handleeducationdata") {
      handleeducationdata(e);
    }
    if (customfunction == "handleInputChange2") {
      handleInputChange2(e);
    }
    if (customfunction == "handleworkexperirnceadata") {
      handleworkexperirnceadata(e);
    }
    if (customfunction == "handleInputChange4") {
      handleInputChange4(e);
    }
    if (customfunction == "handlecertdata") {
      handlecertdata(e);
    }
    if (customfunction == "handleInputChange3") {
      handleInputChange3(e);
    }
    if (customfunction == "handleInputChange") {
      handleInputChange(e);
    }
  };

  // Avatar

  const [editor, setEditor] = useState(null);
  const inputRef = useRef(null); // Define inputRef here

  const handleCroppedImageUpload = () => {
    // Example: Update state with the cropped image

    setImage(croppedImage);
    //setImage(croppedImage);

    // Example: Send cropped image to server
    setCroppedImage(null);
  };
  const handleAvatarClick = () => {
    inputRef.current.click();
  };

  const handleSave = () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas().toDataURL();
      // Here you can send the canvas data to your backend or do something else with it
    }
  };

  // Avatar End

  useEffect(() => {
    if (image !== null) {
      localStorage.setItem("imageURL", image);
    }
  }, [image, AmazonKey]);

  const saveEduDataToKeyCloak = (data) => {
    // Avoid mutating the original state by creating a new array with the added data
    const updatedEducationalDetails = [...profileData.educationaldetails, data];

    // Update the state with the new educational details array
    setProfileData({
      ...profileData,
      educationaldetails: updatedEducationalDetails,
    });
  };

  const savePersonalToKeycloak = (data) => {
    //const updatedPersonalDetails = [...profileData, data];

    setProfileData({
      ...data,
    });
  };

  const saveWorkDataToKeyCloak = (data) => {
    // Avoid mutating the original state by creating a new array with the added data
    const updatedWorkDetails = [...profileData.workexperience, data];

    // Update the state with the new educational details array
    setProfileData({
      ...profileData,
      workexperience: updatedWorkDetails,
    });
  };

  const saveEditedCertificateDataToKeyCloak = (data, index) => {
    // If the certificate is found, update it in the array
    if (index !== -1) {
      const updatedCertDetails = [...profileData.certificate];
      updatedCertDetails[index] = data;

      // Update the state with the updated certificate details array
      setProfileData({
        ...profileData,
        certificate: updatedCertDetails,
      });
    }
    //handleSubmit()
  };

  const saveEditedWorkDataToKeyCloak = (data, index) => {
    // If the work experience is found, update it in the array
    if (index !== -1) {
      const updatedWorkDetails = [...profileData.workexperience];
      updatedWorkDetails[index] = data;

      // Update the state with the updated work experience array
      setProfileData({
        ...profileData,
        workexperience: updatedWorkDetails,
      });
    }
    //handleSubmit()
  };

  const saveEditedEduDataToKeyCloak = (data, index) => {
    // If the work experience is found, update it in the array
    if (index !== -1) {
      const updatedEduDetails = [...profileData.educationaldetails];
      updatedEduDetails[index] = data;

      // Update the state with the updated work experience array
      setProfileData({
        ...profileData,
        educationaldetails: updatedEduDetails,
      });
    }
    //handleSubmit()
  };

  const saveCertificateDataToKeyCloak = (data) => {
    // Avoid mutating the original state by creating a new array with the added data
    const updatedCertDetails = [...profileData.certificate, data];

    // Update the state with the new educational details array
    setProfileData({
      ...profileData,
      certificate: updatedCertDetails,
    });
    //handleSubmit()
  };

  useEffect(() => {
    handleSubmit();
  }, [profileData]);

  useEffect(() => {}, [profileData]);

  return (
    <div className="profile-page-container ">
      <div
        style={{
          marginTop: 8,
          margin: "12px 24px",
        }}
      >
        <Stack spacing={3}>
          <Breadcrumbs seperator="?" aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              <BreadCrumbCCIcon />
            </Link>
            <Typography color="text.primary">Your Profile </Typography>
          </Breadcrumbs>
        </Stack>
      </div>
      <div
        className="row "
        style={{
          marginBottom: 100,
          padding: "0px",
          width: "100%",
          marginTop: 30,
          height: "100%",
          background: "#F6F9FC",
          maxWidth: 1152,
          margin: "auto",
        }}
      >
        <div
          style={{
            marginLeft: 15,
            width: "100%",
            background: "#F6F9FC",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "inline-flex",
          }}
        ></div>
        <SideTab
          selectedTab2={selectedTab2}
          setselectedTab2={setselectedTab2}
        />
        <div
          className="col-md-9"
          style={
            window.innerWidth <= 576
              ? { margin: 0, padding: 0, paddingRight: 0, paddingLeft: 0 }
              : { margin: 0, padding: 0, paddingRight: 32, paddingLeft: 40 }
          }
        >
          <div
            style={{
              marginBottom: "58px",
              justifyContent: "center",
              alignItems: "center",
              gap: 24,
              display: "inline-flex",
            }}
          >
            <div>
              <ProfileImageUpload
                avatar={localStorage.getItem("imageURL") ?? avatar}
                AmazonKey={AmazonKey}
                setAmazonKey={setAmazonKey}
                profileData={profileData}
                profilePic={image}
                image={image}
                setImage={setImage}
                percentage={percentage}
                keyCloakUserId={keyCloakUserId}
                handleSubmit={handleSubmit}
                setProfileData={setProfileData}
              />
            </div>
            <div
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 40,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 24,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 8,
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      color: "#00213D",
                      fontSize: 24,
                      fontFamily: "Inter",
                      wordWrap: "break-word",
                    }}
                  >
                    <strong className="pb-3" style={{ fontWeight: "700" }}>
                      {localStorage.getItem("userName")}
                    </strong>
                  </div>
                  <div
                    style={{
                      color: "#4A5965",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}
                  >
                    {profileData.educationaldetails
                      ? profileData.educationaldetails.map((data) => {
                          return data.collegeName;
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              flexDirection: "column",
              gap: 32,
              marginBottom: "150px",
            }}
          >
            <PersonalDetails
              refid={sectionRefs.personalid}
              savePersonalToKeycloak={savePersonalToKeycloak}
              profileData={profileData}
              setProfileData={setProfileData}
              handleSubmit={handleSubmit}
              fieldWisePercentage={fieldWisePercentage}
            />

            {/*Education Field */}
            <EducationField
              fieldWisePercentage={fieldWisePercentage}
              profileData={profileData}
              education_action={education_action}
              sectionRefs={sectionRefs}
              seteducation_action={seteducation_action}
              deleteablebtnclick3={deleteablebtnclick3}
              editablebtnclick3={editablebtnclick3}
              education_editid={education_editid}
              handleEduEditDetailsClick={handleEduEditDetailsClick}
              discardfunction3={discardfunction3}
              saveEditedEduDataToKeyCloak={saveEditedEduDataToKeyCloak}
              saveEduDataToKeyCloak={saveEduDataToKeyCloak}
            />

            {/* work experience */}
            <WorkExperience
              fieldWisePercentage={fieldWisePercentage}
              emptydata={emptydata}
              handleEduEditDetailsClick={handleEduEditDetailsClick}
              workexperience_editid={workexperience_editid}
              setworkexperience_editid={setworkexperience_editid}
              sectionRefs={sectionRefs}
              workexperience_action={workexperience_action}
              setworkexperience_action={setworkexperience_action}
              editablebtnclick={editablebtnclick}
              deleteablebtnclick={deleteablebtnclick}
              discardfunction={discardfunction}
              profileData={profileData}
              saveWorkDataToKeyCloak={saveWorkDataToKeyCloak}
              saveEditedWorkDataToKeyCloak={saveEditedWorkDataToKeyCloak}
            />

            <TechnicalCompetenciesSection
              fieldWisePercentage={fieldWisePercentage}
              skillSectionEditState={skillSectionEditState}
              sectionRefs={sectionRefs}
              profileData={profileData}
              handleSkillEditState={handleSkillEditState}
              saveSkillData={saveSkillData}
            />

            {/* Certificates */}
            <Certificates
              checkingvalues={checkingvalues}
              fieldWisePercentage={fieldWisePercentage}
              handlecertdata="handlecertdata"
              handleadddateInputChange3={handleadddateInputChange3}
              setsubmission={setsubmission}
              emptydata2={emptydata2}
              handleInputChange3="handleInputChange3"
              handledateInputChange3={handledateInputChange3}
              handleEduEditDetailsClick={handleEduEditDetailsClick}
              cert_editid={cert_editid}
              setcert_editid={setcert_editid}
              sectionRefs={sectionRefs}
              cert_action={cert_action}
              setcert_action={setcert_action}
              editablebtnclick2={editablebtnclick2}
              deleteablebtnclick2={deleteablebtnclick2}
              handlecertsubmit={handlecertsubmit}
              discardfunction2={discardfunction2}
              profileData={profileData}
              setProfileData={setProfileData}
              saveCertificateDataToKeyCloak={saveCertificateDataToKeyCloak}
              saveEditedCertificateDataToKeyCloak={
                saveEditedCertificateDataToKeyCloak
              }
            />
          </div>
        </div>
      </div>

      {showErrorModal && (
        <Verifyotpmodel
          show={showErrorModal}
          handleCloseModal={() => setShowErrorModal(false)}
          //handleVerify={handleVerifyOTP}
          //handleResendOTP={handleSendOTP}
          enteredOTP={enteredOTP}
          setEnteredOTP={setEnteredOTP}
          errorMessage={unverifiedOTP}
          setOtpVerified={setOtpVerified}
        />
      )}
    </div>
  );
}

export default Profile_page;
