import { Provider } from "react-redux";
import { BrowserRouter as Router,HashRouter } from "react-router-dom";

import RenderOnAnonymous from "../src/components/RenderOnAnonymous";
import RenderOnAuthenticated from "../src/components/RenderOnAuthenticated";
import Main from "../src/Website/Index/Main";
import Mergedindex from "./Website/Index/Mergedindex";
import Platform from "./Website/Platform/Platform";
//import { HashRouter as Router} from 'react-router-dom';
//
const App = ({ store }) => (

  <Provider store={store}>

    {/* <HashRouter> */}
      <div>
      <Router >
        <RenderOnAnonymous>
        
          <Main/>
          
        </RenderOnAnonymous>
        <RenderOnAuthenticated>
       
          <Platform/>
         
        </RenderOnAuthenticated>
       </Router> 
      </div>
    {/* </HashRouter> */}
  </Provider>
  
);

export default App;
