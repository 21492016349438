import Home from "./Dashboard/Home";
import Headers from "../Headers";
import Journey from "./Journey/Jounney";
import React from "react";
import Journeycontent from "./Journeycontent";
import CourseDescribing from "./CourseDescribing";
import ProfileAccount from "./ProfileAccount";
import Logout from "../Logout";
import Mergedindex from "../Index/Mergedindex";
import Keyclockloginpage from "../Keyclockloginpage";

import { Routes, Route, Link } from "react-router-dom";
import AssessmentReport from "../../components/Assessment/AssessmentReport";
// import Assignment from "./Journey/Assignment";
// import AssessmentReport  from "../../components/AssessmentReport";
//import AssessmentReport from "../../components/Assessment/AssessmentReport";
import AssignmentDownload from "./Journey/AssignmentDownload";
import EngineeringAssessmentReport from "./Journey/EngineeringAssessmentReport";
import RoleRecommendation from "./Journey/RoleRecommendation";
function Platform() {


  return (

    <Routes>
      <Route path='/loginpage' element={<Keyclockloginpage />} />
      {/* grouping dashborard pages start */}
      <Route path='/' element={<Journeycontent />} />
      <Route path='/dashboard/journey' element={<Journeycontent />} />
      <Route path='/dashboard/describing' element={<CourseDescribing />} />
      <Route path='/dashboard/profile' element={<ProfileAccount />} />
      <Route path='/dashboard/assessment-report' element={<AssessmentReport />} />
      {/* <Route path='/dashboard/assign' element={<Assignment />} /> */}
      <Route path='/dashboard/assign' element={<AssignmentDownload />} />
      <Route path='/dashboard/report' element={<EngineeringAssessmentReport />} />
      <Route path='/dashboard/role' element={<RoleRecommendation />} />
      {/* grouping dashborard pages end*/}
      <Route path='/logout' element={<Mergedindex />} />
    </Routes>

  );
}

export default Platform;
