import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import stem_img from "../../../assets/img/stem_img.svg";
import ProfileCompletionPopUp from "./ProfileCompletionPopUp";
export function JourneyEngineeringAssessment(properties) {
    const {
        icon,
        icon_width,
        icon_height,
        specificid,
        topic,
        typeoftopic,
        description,
        btnname,
        btnlink,
        dificulity_level,
        duration,
        lock_img,
        progressDetails,
        isLearningDisplay,
        noDot,
        noDeadline,
        deadlineUnix,
        container_visbility_class,
        handleProfilePopup,
    } = properties;
    // const [showProfilePopup, setshowProfilePopup] = useState(false);

    let progress = 0;
    // let showProfilePopup = false;
    let completedModules = 0;

    // let modules = 0
    if (progressDetails !== null && progressDetails !== undefined) {
        progress = progressDetails.progress;
        completedModules = progressDetails.display;
        // modules = progressDetails.modules
        // completedModules = (progressDetails.progress / 100) * progressDetails.modules
    }

    // const handleProfilePopup = () => {
    //     showProfilePopup = true;
    // };
    const createMarkup = () => {
        return { __html: description };
    };

    return (
        <div>
            {/* <div hidden={true}>
                <ProfileCompletionPopUp
                    personalDetailsPercentage={personalDetailsPercentage}
                    educationalDetailsPercentage={educationalDetailsPercentage}
                    technicalDetailsPercentage={technicalDetailsPercentage}
                />
            </div> */}
            <div className={`common_journey_container ${container_visbility_class}`}>
                <div className="common_spacing_div_container">
                    <div className="common_top_container">
                        <div className="common_img_container">
                            <img
                                src={icon}
                                style={
                                    icon_width && icon_height
                                        ? { width: icon_width, height: icon_height }
                                        : { width: 43, height: 43 }
                                }
                                alt=""
                            />
                            <div className="common_content_container">
                                <div className="common_topic_type_line">{typeoftopic}</div>
                                <div className="common_description_container">
                                    <div className="common_content_container_div">
                                        <div key={specificid}>
                                            <h1 className="content_topic_name">{topic} </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="symbol_display_container">
                                {!lock_img ? (
                                    <div className="symbol_display_area">COMING SOON</div>
                                ) : (
                                    <div className="symbol_display_area">
                                        {window.innerWidth > 991 && <img src={lock_img} alt="" />}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="progress_container_width" id="moodldesc">
                        <div className="common_description_container">
                            <div
                                className="common_description_content"
                                dangerouslySetInnerHTML={createMarkup()}
                            />
                        </div>
                        <div hidden={!isLearningDisplay}>
                            <div className="progress_top_container">
                                <div className="progress_bar">
                                    <div
                                        style={{
                                            width: `${progress}%`,
                                            height: 8,
                                            background: "#2F80ED",
                                            borderRadius: 10,
                                        }}
                                    />
                                </div>
                                <div className="progress_bottom_container">
                                    <div className="progress_percentage">
                                        {progress !== null && progress !== undefined
                                            ? `${progress}% completed`
                                            : "0% completed"}
                                    </div>
                                    <div>
                                        <span
                                            style={{
                                                color: "#00213D",
                                                fontSize: 12,
                                                fontFamily: "Inter",
                                                fontWeight: "600",
                                                wordWrap: "break-word",
                                                marginRight: 5,
                                            }}
                                        >
                                            {completedModules}
                                        </span>
                                        <span
                                            style={{
                                                color: "#667A8B",
                                                fontSize: 12,
                                                fontFamily: "Inter",
                                                fontWeight: "400",
                                                wordWrap: "break-word",
                                            }}
                                        >
                                            Modules
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="common_button_container">
                    <Link
                        // to="/dashboard/Describing"
                        state={{ deadlineUnix: deadlineUnix }}
                        style={{
                            textDecoration: "none",
                            width: "100%",
                            textAlign: "center",
                        }}>
                        <button
                            className="common_button_area"
                            onClick={() => handleProfilePopup(true)

                            }
                        >
                            <div className="btn_area_text">Test Details</div>
                        </button>
                    </Link>

                    <div className="course_sub_description_container">
                        <div className="dificulity_level_description" hidden={!noDeadline}>
                            Deadline:
                        </div>
                        <div className="dificulity_level_description">
                            {dificulity_level}
                        </div>
                        <div className="word_seperation_round" hidden={noDot} />
                        <div className="duration_description">{duration}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
