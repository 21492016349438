import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
export function JourneyAssignmentNotStarted(properties) {
  const {
    icon,
    icon_width,
    icon_height,
    specificid,
    topic,
    typeoftopic,
    description,
    btnlink,
    duration,
    lock_img,
    container_visbility_class, //locked unlocked completed
  } = properties;

  const createMarkup = () => {
    return { __html: description };
  };

  return (
    <div>
      <div className={`common_journey_container ${container_visbility_class}`}>
        <div className="common_spacing_div_container">
          <div className="common_top_container">
            <div className="common_img_container">
              <img
                src={icon}
                style={
                  icon_width && icon_height
                    ? { width: icon_width, height: icon_height }
                    : { width: 43, height: 43 }
                }
                alt=""
              />
              <div className="common_content_container">
                <div className="common_topic_type_line">{typeoftopic}</div>
                <div className="common_description_container">
                  <div className="common_content_container_div">
                    <div key={specificid}>
                      <h1 className="content_topic_name">{topic} </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="symbol_display_container">
                {!lock_img ? (
                  <div className="symbol_display_area">COMING SOON</div>
                ) : (
                  <div className="symbol_display_area">
                    {window.innerWidth > 991 && <img src={lock_img} alt="" />}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="progress_container_width" id="moodldesc">
            <div className="common_description_container">
              <div
                className="common_description_content"
                dangerouslySetInnerHTML={createMarkup()}
              />
            </div>
          </div>
        </div>
        <div className="common_button_container">
          <Link
            // to={btnlink}
            style={{
              textDecoration: "none",
              width: "100%",
              textAlign: "center",
            }}
          >
            <button
              className="common_button_area"
              onClick={() =>
                (window.location.href = process.env.REACT_APP_ASSINGMENT_LINK)
              }
            >
              <div className="btn_area_text">Assignment Details</div>
            </button>
          </Link>

          <div className="course_sub_description_container">
            <div className="duration_description">{duration}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
