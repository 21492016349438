import React from "react";

const FirstNameBlock = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 4,
        display: "inline-flex",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 12,
          paddingBottom: 12,
          background: "#EDEDED",
          borderRadius: 8,
          border: "1px #E2EBF3 solid",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <div
          style={{
            flex: "1 1 0",
            height: 24,
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div style={{ width: 1 }} />
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 4,
              display: "flex",
            }}
          >
            <div
              style={{
                color: "#80919F",
                fontSize: 14,
                fontFamily: "Inter",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              First Name
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LastNameBlock = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        background: "#EDEDED",
        borderRadius: 8,
        border: "1px #E2EBF3 solid",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "inline-flex",
      }}
    >
      <div
        style={{
          flex: "1 1 0",
          height: 24,
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div style={{ width: 1 }} />
        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 4,
            display: "flex",
          }}
        >
          <div
            style={{
              color: "#80919F",
              fontSize: 14,
              fontFamily: "Inter",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            Last Name
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileNumberBlock = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        background: "#EDEDED",
        borderRadius: 8,
        border: "1px #E2EBF3 solid",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "inline-flex",
      }}
    >
      <div
        style={{
          flex: "1 1 0",
          height: 24,
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            width: 117,
            alignSelf: "stretch",
            paddingRight: 16,
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 16,
            display: "flex",
          }}
        >
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 8,
              display: "flex",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 4,
                display: "flex",
              }}
            >
              <div style={{ width: 24, height: 24, position: "relative" }}>
                {/* <div style={{ width: 20.67, height: 12.67, left: 1.67, top: 5.66, position: 'absolute', background: '#5C9E31' }}></div>
                                <div style={{ width: 20.67, height: 4.33, left: 1.67, top: 5.66, position: 'absolute', background: '#E27022' }}></div>
                                <div style={{ width: 20.67, height: 4, left: 1.67, top: 10, position: 'absolute', background: 'white' }}></div>
                                <div style={{ width: 3, height: 3, left: 10.50, top: 10.50, position: 'absolute', background: '#1E50A0' }}></div>
                                <div style={{ width: 3.67, height: 3.67, left: 10.17, top: 10.16, position: 'absolute', background: '#1E50A0' }}></div>
                                <div style={{ width: 21.33, height: 13.33, left: 1.33, top: 5.34, position: 'absolute', background: '#4A5965' }}></div> */}
              </div>
              <div
                style={{
                  color: "#4A5965",
                  fontSize: 14,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                +91
              </div>
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "flex",
              }}
            >
              <div style={{ width: 24, height: 24, position: "relative" }}>
                {/* <div style={{ width: 14, height: 8, left: 5, top: 8, position: 'absolute', background: '#4A5965' }}></div> */}
              </div>
            </div>
          </div>
          <div
            style={{
              width: 24,
              alignSelf: "stretch",
              transform: "rotate(90deg)",
              transformOrigin: "0 0",
              border: "1px #E2EBF3 solid",
            }}
          ></div>
        </div>
        <div style={{ width: 1 }} />
        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 4,
            display: "flex",
          }}
        >
          <div
            style={{
              color: "#80919F",
              fontSize: 14,
              fontFamily: "Inter",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            Mobile Number
          </div>
        </div>
      </div>
    </div>
  );
};

const PasswordBlock = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        background: "#EDEDED",
        borderRadius: 8,
        border: "1px #E2EBF3 solid",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "inline-flex",
      }}
    >
      <div
        style={{
          flex: "1 1 0",
          height: 24,
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div style={{ width: 1 }} />
        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 4,
            display: "flex",
          }}
        >
          <div
            style={{
              color: "#80919F",
              fontSize: 14,
              fontFamily: "Inter",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            Password
          </div>
        </div>
      </div>
      <div
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 4,
          display: "flex",
        }}
      >
        <div
          style={{
            width: 24,
            height: 24,
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 4.86,
            paddingBottom: 4.86,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {/* <div style={{ width: 20, height: 14.29, position: 'relative' }}>
                        <div style={{ width: 20, height: 14.29, left: 0, top: 0, position: 'absolute', background: '#4A5965' }}></div>
                        <div style={{ width: 8.57, height: 8.57, left: 5.72, top: 2.86, position: 'absolute', background: '#4A5965' }}></div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

const SubmitButtonBlock = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "48px",
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 12,
        paddingBottom: 12,
        background: "#EDEDED",
        borderRadius: 8,
        justifyContent: "center",
        alignItems: "center",
        gap: 8,
        display: "inline-flex",
      }}
    >
      <div
        style={{
          color: "#72818C",
          fontSize: 14,
          fontFamily: "Inter",
          fontWeight: "600",
          wordWrap: "break-word",
        }}
      >
        Verify Email and Mobile Number
      </div>
    </div>
  );
};

export {
  FirstNameBlock,
  LastNameBlock,
  MobileNumberBlock,
  PasswordBlock,
  SubmitButtonBlock,
};
