import Headers from "../../Headers";
import Home from "../Dashboard/Home";
import EngineeringReportPage from "./EngineeringReportPage";
const EngineeringAssessmentReport = () => {
    const content = <EngineeringReportPage />;
    return (
        <div>
            <Headers />
            <Home contentdes={content} />
        </div>
    );
}

export default EngineeringAssessmentReport;
