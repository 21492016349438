import React, { useState, useEffect } from "react";
import Icon from "../../../assets/img/Icon.svg";
import { Link } from "react-router-dom";
import "./SubmissionTimer.css";

const SubmissionTimer = ({ deadlineUnix, onClose }) => {
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(deadlineUnix));
    const assingmentSubmissionLink = process.env.REACT_APP_ASSINGMENT_SUBMISSION_LINK
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining(deadlineUnix));
        }, 10000);

        return () => clearInterval(timer);
    }, [deadlineUnix]);

    function calculateTimeRemaining(deadlineUnix) {
        const now = new Date();
        const formattedEndTime = new Date(deadlineUnix * 1000); // Convert Unix timestamp to milliseconds
       

        let totalSeconds = Math.floor((formattedEndTime - now) / 1000);

        const days = Math.floor(totalSeconds / (24 * 3600));
        totalSeconds %= (24 * 3600);

        const hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;

        const minutes = Math.floor(totalSeconds / 60);

        return {
            days,
            hours,
            minutes
        };
    }

    const handleClosePanel = () => {
       
        onClose();
    };

    return (
        <div className="mycontainer" >
            <div className="row" style={{ padding: 0, margin: 0 }}>
                <div className="col-lg-12" style={{ zIndex: 2000, width: "100%", height: "61px", position: "relative", background: "#F2F4F8" }}>

                    <div style={{ width: "100%", height: "61px" }}>
                        <div className="submission-container-gap">
                            <div className="submission-timer-gap">
                                <div className="submission-timer-width">
                                    <div className="submission-assignment">ASSIGNMENT</div>
                                    <div style={{ textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Submission Deadline</div>
                                </div>
                                <div style={{ width: 1, alignSelf: 'stretch', transformOrigin: '0 0', borderRight: '1px #4373B9 solid' }}></div>
                                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                                    <div className="submission-timer-unit">
                                        <div className="submission-timer-value">{isNaN(timeRemaining.days) ? '' : timeRemaining.days}</div>
                                        <div className="submission-timer-label">Days</div>
                                    </div>
                                    <div className="submission-timer-dot" />
                                    <div className="submission-timer-unit">
                                        <div className="submission-timer-value">{isNaN(timeRemaining.days) ? '' : timeRemaining.hours}</div>
                                        <div className="submission-timer-label">Hours</div>
                                    </div>
                                    <div className="submission-timer-dot" />
                                    <div className="submission-timer-unit">
                                        <div className="submission-timer-value">{isNaN(timeRemaining.days) ? '' : timeRemaining.minutes}</div>
                                        <div className="submission-timer-label">Minutes</div>
                                    </div>
                                </div>
                            </div>
                            <Link to={assingmentSubmissionLink} className="submission-button">
                                <button className="submission-timer-button">
                                    <div className="submission-timer-word">Your Submission</div>
                                </button>
                            </Link>
                            <div className="submission-timer-close" onClick={handleClosePanel}>

                                <img src={Icon} alt="Close" onClick={handleClosePanel} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubmissionTimer;