import ContactSections from "./ContactSections";
import Footer from "../Footer";
import Headers from "../Headers";
import Navpages from "../Navpages";

function Contact()  {
    return (
  <div>
     <Headers/>
        <Navpages/>
        <ContactSections/>
        <Footer/>
  </div> 
    );
  }
  
  export default Contact;
  