import { useState,useEffect } from "react";
import {
  Profile_details_header,
  Profile_details_generalview,
  Profile_edit_view_footer,
  Profile_edit_educational_content,
  Profile_edit_educational_content2,
  Profile__education_details_header,
} from "../Profile_components";
import add_details from "../../../assets/img/add_details.svg";
import dateFormat from "dateformat";
import Pencil from "../../../assets/img/Pencil.svg";
import delete_details from "../../../assets/img/delete_details.svg";
import SchoolAcademics from "./SchoolAcademics";
import CollegeAcademics from "./CollegeAcademics";
import * as Yup from "yup";
import { Formik, Form } from "formik";
const EducationField = (props) => {
  const [eduDetails, setEduDetails] = useState({});

  const {
    education_action,
    profileData,
    sectionRefs,
    seteducation_action,
    deleteablebtnclick3,
    education_editid,
    editablebtnclick3,
    handleEduEditDetailsClick,
    discardfunction3,
    saveEduDataToKeyCloak,
    saveEditedEduDataToKeyCloak,
    fieldWisePercentage,
  } = props;

   const ClassXSchema = Yup.lazy((values) => {
    if (eduDetails.gradingType === "Percentage") {
      return Yup.object().shape({
        educationLevel: Yup.string().required("Education is required"),
        board: Yup.string().required("Board is not Selected"),
        medium: Yup.string().required("Required"),
        passedOutYear: Yup.date().required("Required Pass out Year"),
        gradingSystem: Yup.string().required("Required"),
        percentage: Yup.number()
          .min(0, "Percentage cannot be negative")
          .max(100, "Percentage cannot be greater than 100")
          .required("Percentage is required"),
      })
    }

    return Yup.object().shape({
      educationLevel: Yup.string().required("Education is required"),
      board: Yup.string().required("Board is not Selected"),
      medium: Yup.string().required("Required"),
      passedOutYear: Yup.date().required("Required Pass out Year"),
      gradingSystem: Yup.string().required("Required"),
    /*   classxgrade: Yup.string()
        .matches(/^[A-F][1-6]?$/i, "Invalid grade format")
        .required("Grade is Required"), */
    });
  });
 
  


  const ClassXIISchema = Yup.object().shape({
    educationLevel: Yup.string().required("Education is required"),
    board: Yup.string().required("Board is not Selected"),
    medium: Yup.string().required("Required"),
    passedOutYear: Yup.date().required("Required Pass out Year"),
    percentage: Yup.number()
      .min(0, "Percentage cannot be negative")
      .max(100, "Percentage cannot be greater than 100")
      .required("Percentage is required"),
  });

  const graduationSchema = Yup.lazy((values) => {
    const today = new Date(); // Get current date

    if (eduDetails.gradingType === "Percentage") {
      return Yup.object().shape({
        educationLevel: Yup.string().required("Education is required "),
        collegeName: Yup.string()
          .matches(
            /^[A-Za-z\s]+$/,
            "College name should only contain letters and spaces"
          )
          .required("College Name is required"),
        courses: Yup.string().required("Select a Course"),
        specalizations: Yup.string().required("Select a Specalization"),
        collegeStartDate: Yup.date()
          .nullable()
          .required("Enter College Start Date"),
        collegeEndDate: Yup.date()
          .nullable()
          .required("Enter College End Date"),
        courseType: Yup.string().required("Choose the mode of course"),
        collegeGradeSystem: Yup.string().required("Required"),
        percentage: Yup.number()
          .min(0, "Percentage cannot be negative")
          .max(100, "Percentage cannot be greater than 100")
          .required("Percentage is required"),
      });
    }

    return Yup.object().shape({
      educationLevel: Yup.string().required("Education is required "),
      collegeName: Yup.string()
        .matches(
          /^[A-Za-z\s ]+$/,
          "College name should only contain letters and spaces"
        )
        .required("College Name is required"),
      courses: Yup.string().required("Select a Course"),
      specalizations: Yup.string().required("Select a Specalization"),
      collegeStartDate: Yup.date()
        .nullable()
        .required("Enter College Start Date"),
      collegeEndDate: Yup.date().nullable().required("Enter College End Date"),
      courseType: Yup.string().required("Choose the mode of course"),
      collegeGradeSystem: Yup.string().required("Required"),
      collegeCGPA: Yup.number()
        .min(1, "CGPA cannot be less than 1")
        .max(10, "CGPA cannot be greater than 10")
        .required("CGPA is required"),
    });
  });

  let validationSchema = Yup.lazy((values) => {
    if (eduDetails.educationLevel === "Class X") {
      return ClassXSchema;
    } else if (eduDetails.educationLevel === "Class XII") {
      return ClassXIISchema;
    } else if (eduDetails.educationLevel === "Graduation") {
      return graduationSchema;
    } else {
      return ClassXSchema; // Default to an empty schema if no match
    }
  });

  useEffect(() => {
    validationSchema = Yup.lazy(() => {
      if (eduDetails.educationLevel === "Class X") {
    
        return ClassXSchema;
      } else if (eduDetails.educationLevel === "Class XII") {
        return ClassXIISchema;
      } else if (eduDetails.educationLevel === "Graduation") {
        return graduationSchema;
      } else {
        return null; // Default to an empty schema if no match
      }
    });

    //  setEduDetails({});
  }, [eduDetails.educationLevel, education_action]);

  const dropDownApiCall = async () => {
    const res = await fetch(`${process.env.REACT_APP_NODEJS_TS}/get-profile-data`)
    const data = await res.json()
    return data
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await dropDownApiCall();
      console.log(data);
      localStorage.setItem('branchData', JSON.stringify(data.branchs));
    };

    fetchData();
  }, []);

  return (
    <div ref={sectionRefs.educationid}>
      {/* view start*/}
      {education_action == "view" ? (
        <div
          ref={sectionRefs.educationid}
          id="educationid"
          className="education-view-container"
        >
          <Profile__education_details_header
            fieldWisePercentage={fieldWisePercentage.education}
            adddetailsbtn="yes"
            bottomborder={`${
              profileData.educationaldetails.length ? "profilebottomborder" : ""
            }`}
            clickname={() => seteducation_action("add")}
            imgtoshow={add_details}
            topic="Education Details"
            propData={profileData}
          />
          <div className="education-list-container">
            {profileData.educationaldetails.map((item, index) => (
              <div className="education-list">
                <div
                  style={{
                    alignSelf: "stretch",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "40px",
                    display: "inline-flex",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      visibility: "visible",
                      flex: "1 1 0px",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: "40px",
                      display: "inline-flex",
                      alignSelf: "stretch",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Profile_details_generalview
                        heading={item.educationLevel}
                        deletebtnclick={() => deleteablebtnclick3(index)}
                        editbtnclick={() => editablebtnclick3(index)}
                        description={`${item.board || item.courses} - ${
                          item.educationLevel === "Graduation"
                            ? dateFormat(item.collegeEndDate, "yyyy")
                            : dateFormat(item.passedOutYear, "yyyy")
                        }`}
                        editicon={Pencil}
                        deleteicon={delete_details}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* view end */}

      {/* edit start */}

      {education_action === "edit" ? (
        <div
          ref={sectionRefs.educationid}
          id="educationid"
          className="education-view-container"
        >
          <Profile__education_details_header
            bottomborder="profilebottomborder"
            clickname={handleEduEditDetailsClick}
            imgtoshow={add_details}
            topic="Educational Details"
            propData={profileData}
          />

          {education_action === "edit" &&
            profileData.educationaldetails &&
            profileData.educationaldetails.map((item, index) => {
              if (
                index === education_editid &&
                item.educationLevel === "Class X"
              ) {
                return (
                  <Formik
                    initialValues={item}
                    validationSchema={
                   ClassXSchema
                    }
                    validateOnChange={true}
                    onSubmit={(values) => {
                   
                      saveEditedEduDataToKeyCloak(values, index);
                      seteducation_action("view");
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setErrors,
                    }) => (
                      <Form fullwidth style={{ width: "100%" }}>
                        <SchoolAcademics
                          setEduDetails={setEduDetails}
                          eduDetails={item}
                          values={values}
                          setErrors={setErrors}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          education_action={education_action}
                          profileData={profileData}
                          seteducation_action={seteducation_action}
                          discardfunction3={discardfunction3}
                        />

                        {/*   <button type="submit" > Save here </button>
                         */}
                        {/*    <Profile_edit_view_footer
                          discard_editview={discardfunction3}
                          handleSubmit={handleSubmit}
                          errors={errors}
                          eduDetails={eduDetails}
                        /> */}
                      </Form>
                    )}
                  </Formik>
                );
              }
              if (
                index === education_editid &&
                item.educationLevel === "Class XII"
              ) {
                return (
                  <Formik
                    initialValues={item}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    onSubmit={(values) => {
                    
                      saveEditedEduDataToKeyCloak(values, index);
                      seteducation_action("view");
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setErrors,
                    }) => (
                      <Form fullwidth style={{ width: "100%" }}>
                        <SchoolAcademics
                          setEduDetails={setEduDetails}
                          eduDetails={item}
                          values={values}
                          setErrors={setErrors}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          education_action={education_action}
                          profileData={profileData}
                          seteducation_action={seteducation_action}
                          discardfunction3={discardfunction3}
                        />

                        {/*    <Profile_edit_view_footer
                          discard_editview={discardfunction3}
                          handleSubmit={handleSubmit}
                          errors={errors}
                          eduDetails={eduDetails}
                        /> */}
                      </Form>
                    )}
                  </Formik>
                );
              }

              if (
                index === education_editid &&
                item.educationLevel === "Graduation"
              ) {
                return (
                  <Formik
                    initialValues={item}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                
                      saveEditedEduDataToKeyCloak(values, index);
                      seteducation_action("view");
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setErrors,
                    }) => (
                      <Form
                        fullwidth
                        style={{ width: "100%" }}
                        validateOnChange={false}
                      >
                        <CollegeAcademics
                          setEduDetails={setEduDetails}
                          eduDetails={item}
                          values={values}
                          errors={errors}
                          setErrors={setErrors}
                          touched={touched}
                          handleChange={handleChange}
                          seteducation_action={seteducation_action}
                          education_action={education_action}
                          profileData={profileData}
                          discardfunction3={discardfunction3}
                        />

                        {/*    <Profile_edit_view_footer
                          discard_editview={discardfunction3}
                          handleSubmit={handleSubmit}
                          errors={errors}
                          eduDetails={eduDetails}
                        /> */}
                      </Form>
                    )}
                  </Formik>
                );
              }
            })}
        </div>
      ) : null}

      {education_action == "add" ? (
        <div
          ref={sectionRefs.educationid}
          id="educationid"
          className="education-view-container"
        >
      
          <Profile__education_details_header
            bottomborder="profilebottomborder"
            clickname={handleEduEditDetailsClick}
            imgtoshow={add_details}
            topic="Educational Details"
            propData={profileData}
          />
          <Formik
            initialValues={{}}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
             
              saveEduDataToKeyCloak(values);
              seteducation_action("view");
              setEduDetails({});
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setErrors,
            }) => (
              <Form fullwidth style={{ width: "100%" }}>
                {eduDetails.educationLevel !== "Graduation" ? (
                  <SchoolAcademics
                    eduDetails={education_action === "edit" ? {} : eduDetails}
                    setEduDetails={setEduDetails}
                    values={values}
                    setErrors={setErrors}
                    errors={errors}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                    handleChange={handleChange}
                    seteducation_action={seteducation_action}
                    profileData={profileData}
                    handleSubmit={handleSubmit}
                    discardfunction3={discardfunction3}
                  />
                ) : (
                  <CollegeAcademics
                    eduDetails={eduDetails}
                    setEduDetails={setEduDetails}
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    touched={touched}
                    handleChange={handleChange}
                    seteducation_action={seteducation_action}
                    profileData={profileData}
                    discardfunction3={discardfunction3}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        ""
      )}

      {/* edit end */}
    </div>
  );
};

export default EducationField;
