import React, { useState } from "react";
import Styles from "./accordion.module.css";
import DownIcon from "../../../assets/img/down_icon.svg";

export const Accordion = ({ items, instructions }) => {

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={Styles.accordion}>
      {items.map((item, index) => (
        <div key={index} className={Styles.accordionItem}>
          <div
            className={`${Styles.assignmentInstruction} ${activeIndex === index ? Styles.active : ""
              }`}
            onClick={() => handleClick(index)}
          >
            <p className={Styles.instructionText}>Instructions</p>
            <img
              src={DownIcon}
              alt="down icon"
              className={`${activeIndex === index ? Styles.downIconActive : Styles.downIcon
                }`}
            />
          </div>

          {activeIndex === index && (
            <div className={Styles.accordionContent}>
              {/* {item.content} */}
              <div dangerouslySetInnerHTML={{ __html: instructions }}></div>
              {/* <p className={Styles.instructionTitle}>General Guidelines</p>
              <ul className={Styles.instructionList}>
                <li>Each question is timed</li>
                <li>Do not use search engines or get help from others</li>
                <li>Once you’ve submitted an answer, you cannot go back</li>
                <li>You may exit the test, but the timer will continue to run</li>
                <li>You can retake the assessment every 60 days</li>
              </ul>
              <p className={Styles.instructionTitle}>How To Submit</p>
              <ul className={Styles.instructionList}>
                <li>Each question is timed</li>
                <li>Do not use search engines or get help from others</li>
                <li>Once you’ve submitted an answer, you cannot go back</li>
                <li>You may exit the test, but the timer will continue to run</li>
                <li>You can retake the assessment every 60 days</li>
              </ul> */}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
