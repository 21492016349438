import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./dashboardstyle.css";

import Journey from "../Journey/Jounney";

import Account from "../Account/Account";
import axios from "axios";

import Describing from "../Journey/Describing";
import logo from "../../../assets/img/CC_Logo.svg";
import avatar from "../../../assets/img//Avatar.svg";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import logout_img from "../../../assets/img/line-md_logout.png";
import Right_Arrow_Icon from "../../../assets/img/Right_Arrow_Icon.png";
import UserService from "../../../services/UserService";
import Profile_page from "../../Profile/Profile_page";
import qs from "qs";
import Keycloak_config from "../../../services/Keycloak_config";
const fetchSesskey = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_MOODLE_URL}/get_sesskey.php`,
      {
        withCredentials: true, // This is important to include cookies
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // console.log("Response data:", response.data);
    return response.data.sesskey;
  } catch (error) {
    console.error("Error fetching session key:", error);
    throw error;
  }
};
function Home({ contentdes }) {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isTimes, setIsTimes] = useState(false);

  const [selectedTab, setSelectedTab] = useState("Journey");
  const [selectedContent, setContentChange] = useState(contentdes);
  const [sidebaractive, setsidebaractive] = useState(0);
  const [activeprofilebar, setactiveprofilebar] = useState(0);
  const [profile_click, setprofile_click] = useState(0);
  const [noti_click, setnoti_click] = useState(0);
  const [percentage, setProfilePercentage] = useState("0");

  const profilePicUrl = process.env.REACT_APP_NODEJS_TS;
  const handleClickOutside = (event) => {
    const profileMenu = document.getElementById("profileMenu"); // Ensure you have an ID on your div
    if (profileMenu && !profileMenu.contains(event.target)) {
      // Close the profile menu
      setprofile_click(false);
    }
  };

  console.log(
    process.env.REACT_APP_NODEJS_TS,
    localStorage.getItem("accessToken")
  );
  /*   localStorage.getItem("refreshToken") === null */
  useEffect(() => {
    try {
      axios
        .post(
          `${apiBaseUrl}/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/token`,
          qs.stringify(Keycloak_config),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(async (response) => {
          const accessToken = response.data.access_token;
          console.log(response, "check", localStorage.getItem("refreshToken"));
          localStorage.setItem("refreshToken", response.data.refresh_token);
          localStorage.setItem("accessToken", accessToken);

          const userId = UserService.getUserId();
          localStorage.setItem("userId", userId);

          // Use the obtained access token to fetch user profile data
          axios
            .get(
              `${apiBaseUrl}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/users/${userId}`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            )
            .then((profileResponse) => {
              const userProfile = profileResponse.data;
              console.log(userProfile, "profileData");
              localStorage.setItem(
                "userName",
                userProfile.firstName + " " + userProfile.lastName
              );
              console.log(profileResponse.data.attributes["AWSKey"]);
              if (profileResponse.data.attributes["AWSKey"] !== undefined) {
                axios
                  .post(`${profilePicUrl}/get-presigned-profile-url`, {
                    key: profileResponse.data.attributes["AWSKey"][0],
                  })
                  .then((profilePicResponse) => {
                    const profilePic = profilePicResponse.data.data;

                    localStorage.setItem("imageURL", profilePic);
                  });
              } else {
                localStorage.setItem("imageURL", avatar);
              }

              const requestData = {
                userId: userId,
              };
              axios
                .post(
                  `${profilePicUrl}/get-profile-percentage`,
                  qs.stringify(requestData),
                  {
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                  }
                )
                .then(async (response) => {
                  let percentage_value = response.data.percentage
                    ? response.data.percentage.slice(0, -1)
                    : "0";
                  setProfilePercentage(percentage_value); //
                  localStorage.setItem("profilePercentage", percentage_value);
                })
                .catch((error) => {
                  // Handle error
                  console.error("Response Error:", error);
                });
            });
        })
        .catch((error) => {
          console.error("Error obtaining access token:", error);
        });
    } catch (error) {
      console.error("Axios Error", error);
    }
  }, []);

  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Handleusericon = () => {
    setactiveprofilebar(!activeprofilebar);
  };
  const tabClickHandler = (tabName) => {
    let content = "";
    switch (tabName) {
      case "Journey":
        content = <Journey />;
        break;
      case "Describing":
        content = <Describing />;
        break;
      case "Account":
        content = <Account />;
        break;
      case "Profile":
        content = <Profile_page />;

        break;
      default:
        content = "Select a tab to view content";
        break;
    }

    setContentChange(content);
    setSelectedTab(tabName);
  };

  const handleLogout = async () => {
    console.log("logout happenning");
    try {
      const moodlUserId = await axios.post(
        `${process.env.REACT_APP_NODEJS_TS}/userId`,
        { email: UserService.getUsername() }
      );

      const sesskey = await fetchSesskey();
      if (!sesskey) {
        console.error("Session key not retrieved.");
        return;
      }
      console.log(moodlUserId, sesskey, "logout here");
      const terminateSessionUrl = `${process.env.REACT_APP_MOODLE_URL}/terminate_session.php?userid=${moodlUserId.data}&sesskey=${sesskey}`;
      const terminateResponse = await axios.get(terminateSessionUrl, {
        withCredentials: true,
      });

      if (terminateResponse.status === 200) {
        console.log("Moodle session terminated successfully.");

        // Clear Moodle cookies
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              "=;expires=" +
                new Date().toUTCString() +
                ";path=/;domain=.cream-collar.com"
            );
        });
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("profilePercentage");
        localStorage.removeItem("emailForOtp");
        localStorage.removeItem("userId");
        localStorage.removeItem("imageURL");
        localStorage.removeItem("userName");
        localStorage.removeItem("hasShownDeadlinePopup");

        // Logout from Keycloak
        UserService.doLogout({
          redirectUri: process.env.REACT_APP_FRONTEND_URL,
        });

        console.log("Logout successful from both Keycloak and Moodle.");
      } else {
        console.error(
          "Failed to terminate Moodle session:",
          terminateResponse.status
        );
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const [panelOpen, setPanelOpen] = useState(true);

  // const handleClosePanel = () => {
  //   setPanelOpen(false);
  // };

  return (
    <div className="mycontainer">
      <div className="row " style={{ padding: 0, margin: 0 }}>
        <div
          className="col-lg-12"
          style={{
            zIndex: 5,
            width: "100%",
            height: "64px",
            position: "relative",
            background: "#F2F4F8",
          }}
        >
          <div
            style={{
              width: "100%",
              left: 0,
              top: 0,
              position: "absolute",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              display: "inline-flex",
              borderBottom: "1px #E2EBF3 solid",
            }}
          >
            <div
              style={{
                width: 32,
                height: 64,
                paddingBottom: "1%",
                paddingLeft: 14,
                paddingRight: 14,
                background: "white",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 5,
                display: "flex",
              }}
            ></div>
            <div
              style={{
                width: "100%",
                height: 64,
                background: "white",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 20,
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  height: 52,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 15,
                  display: "flex",
                }}
              >
                <NavLink
                  to="/dashboard/journey"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className={`${
                      selectedTab === "Profile" ? "active" : "none"
                    } buttonbkcolornone`}
                    style={{
                      padding: 0,
                      color: "#5A5A5A",
                      fontSize: 32,
                      fontFamily: "Inter",
                      fontWeight: "700",
                      wordWrap: "break-word",
                    }}
                  >
                    <img src={logo} alt="" />
                  </button>
                </NavLink>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 30,
                  display: "flex",
                  padding: "0 32px",
                }}
              >
                <div
                  style={{ width: 48, height: 48, position: "relative" }}
                  onClick={() => Handleusericon()}
                >
                  <button
                    className="buttonbkcolornone"
                    onClick={() => setprofile_click(!profile_click)}
                  >
                    <img
                      src={localStorage.getItem("imageURL") ?? avatar}
                      alt=""
                      style={{
                        width: 48,
                        height: 48,
                        left: 0,
                        top: 0,
                        position: "absolute",
                        background: "green",
                        borderRadius: 9999,
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="profileMenu"
        className={`${profile_click ? "" : "displaynone"}`}
        style={{
          width: "auto",
          height: "auto",
          position: "absolute",
          zIndex: 5000,
          padding: "32px",
          backgroundColor: "white",
          top: "64px",
          right: "0%",
          boxShadow: "0px 8px 16px -12px rgba(9, 44, 76, 0.22)",
          borderBottomLeftRadius: 20,
          overflow: "hidden",
          border: "1px #E2EBF3 solid",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 20,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            paddingBottom: 20,
            borderBottom: "1px #E2EBF3 solid",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 24,
            display: "flex",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              gap: 24,
              display: "inline-flex",
            }}
          >
            <div style={{ width: 96, height: 100.63, position: "relative" }}>
              <div>
                <img
                  src={localStorage.getItem("imageURL") ?? avatar}
                  className="circular_status"
                />
              </div>
              <div
                style={{ width: 84, height: 84, transform: "rotateZ(42deg)" }}
              >
                <CircularProgressbar
                  value={localStorage.getItem("profilePercentage")}
                  maxValue={131}
                />
              </div>
              <div
                style={{
                  width: 50,
                  height: 20,
                  paddingTop: 4,
                  paddingBottom: 2,
                  paddingLeft: 12,
                  paddingRight: 12,
                  left: 17,
                  top: 0,
                  position: "absolute",
                  background: "white",
                  borderRadius: 16,
                  border: "1px #EBEDF0 solid",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    color: "#27AE60",
                    fontSize: 12,
                    fontFamily: "Inter",
                    fontWeight: "700",
                    wordWrap: "break-word",
                  }}
                >
                  {`${localStorage.getItem("profilePercentage")}%`}
                </div>
              </div>
            </div>
            <div
              style={{
                flex: "1 1 0",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 16,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  color: "#00213D",
                  fontSize: 20,
                  fontFamily: "Inter",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                <strong className="pb-3">
                  {localStorage.getItem("userName")}
                </strong>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",

                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 4,
                    display: "inline-flex",
                  }}
                >
                  <div className="nav-item">
                    <div
                      onClick={() => {
                        setprofile_click(!profile_click);
                      }}
                      className={selectedTab == "Profile" ? "active" : "none"}
                      style={{ textDecoration: "none" }}
                    >
                      <NavLink
                        to="/dashboard/profile"
                        style={{ textDecoration: "none" }}
                      >
                        <button
                          className="buttonbkcolornone2"
                          style={{
                            color: "#0B6AEA",
                            fontSize: 16,
                            fontFamily: "Inter",
                            fontWeight: "700",
                            wordWrap: "break-word",
                            marginLeft: -5,
                          }}
                        >
                          <span
                            style={{
                              marginLeft: -15,
                            }}
                          >
                            View & Update Profile
                          </span>
                          <img
                            src={Right_Arrow_Icon}
                            style={{ marginLeft: 6, height: 22 }}
                            alt=""
                          />
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            height: 45,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 8,
            display: "flex",
          }}
        >
          <button
            className="buttonbkcolornone"
            onClick={() => handleLogout()}
            style={{
              alignSelf: "stretch",
              width: 20,
              paddingTop: 8,
              paddingBottom: 8,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 8,
              display: "inline-flex",
            }}
          >
            <div style={{ width: 24, height: 24, position: "relative" }}>
              {" "}
              <img src={logout_img} alt="Logout" />{" "}
            </div>{" "}
            <div
              style={{
                color: "#00213D",
                fontSize: 16,
                fontFamily: "Inter",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Logout
            </div>
          </button>
        </div>
      </div>
      <div className="row" noGutters={true} style={{ padding: 0, margin: 0 }}>
        <div
          className={` ${
            sidebaractive
              ? "col-md-10 rightContainer"
              : " col-md-12 rightContainer"
          }  `}
          style={{ padding: 0 }}
        >
          {selectedContent}
        </div>
      </div>
    </div>
  );
}

export default Home;
