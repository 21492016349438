import Footer from "./Footer";
import Headers from "./Headers";
import Navpages from "./Navpages";

function Keyclockloginpage() {
  return (
    <div style={{ backgroundColor: "#F6F9FC" }}>
      {/* <Headers/>
        <Navpages/> */}
      <iframe
        src={`${process.env.REACT_APP_API_BASE_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/auth?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MOODLE_URL}/auth/oidc/?&state=8316e9fd-768e-45d1-9c88-595c1ca0d541&response_mode=fragment&response_type=code&scope=openid&nonce=6fc24b3b-ef98-4f3f-b927-561c53ce947f&code_challenge=zFer1p93lWjVT38ffMpM4Xx1zPbLtozhM4IZxzUtE-s&code_challenge_method=S256`}
        style={{
          overflow: "hidden",
          overflowX: "hidden",
          overflowY: "hidden",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        }}
        height="100%"
        width="100%"
      ></iframe>
    </div>
  );
}

export default Keyclockloginpage;
