import React, { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Profile_details_header,
  Profile_edit_view_footer,
} from "../Profile_components";
import add_details from "../../../assets/img/add_details.svg";
import Styles from "../profile.module.css";
import "react-image-crop/dist/ReactCrop.css";
import TextField from "@mui/material/TextField";
import { Field } from "formik";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import CertStyles from "./CertStyles.module.css";
import SkillSelection from "./SkillSelection";

const AddCertificate = (properties) => {
  const [certificateProviderNameError, setCertficateProviderNameError] =
    useState("");
  const [certificateCourseNameError, setCertficateCourseNameError] =
    useState("");
  const [certificateURLError, setCertficateURLError] = useState("");
  const [certificateDateError, setCertficateDateError] = useState("");

  const [selectedAccquiredSkills, setSelectedAccquiredSkills] = useState([]);

  const {
    values,
    errors,
    touched,
    handleChange,
    setErrors,
    certificateDetails,
    setCertificateDetails,
  } = properties;

  const setCertficateCourseName = (e) => {
    let val = e.target.value;
    if (val.length > 25) {
      e.target.value = val.substring(0, 25);
    }
    setCertificateDetails({
      ...certificateDetails,
      certCourseName: val.substring(0, 25),
    });

    // Validate certificate company name
    if (!val) {
      setCertficateCourseNameError("Certficate course name is required");
      handleChange(e);
    } else if (!/^[a-zA-Z0-9 ]{0,25}$/.test(val) || val.startsWith(" ")) {
      e.target.value = val.replace(/[^a-zA-Z0-9\s]{0,25}/g, "");

      setCertificateDetails({ ...certificateDetails, certProvider: val });
      setCertficateCourseNameError(
        "Enter valid course name with less than 25 characters "
      );
    } else {
      setCertficateCourseNameError("");
      handleChange(e);
    }
  };

  const setCertproviderName = (e) => {
    const val = e.target.value;

    if (val.length > 25) {
      e.target.value = val.slice(0, 25);
    }

    // Validate certificate company name
    if (!val) {
      setCertficateProviderNameError("Certificate provider name is required");
      handleChange(e);
    } else if (!/^[a-zA-Z0-9 ]{0,25}$/.test(val) || val.startsWith(" ")) {
      e.target.value = val.replace(/[^a-zA-Z0-9\s]{0,25}/g, "");
      setCertficateProviderNameError(
        "Enter valid certificate provider name with less than 25 characters"
      );

      setCertificateDetails({ ...certificateDetails, certProvider: val });
    } else {
      setCertficateProviderNameError("");
      handleChange(e);
    }
  };

  const setCertUrl = (e) => {
    const val = e.target.value;
    setCertificateDetails({ ...certificateDetails, certUrl: val });
    handleChange(e);
    // Validate certificate company name
    if (!val) {
      setCertficateURLError("Certificate URL is required");
    } else if (!val.startsWith("https://")) {
      setCertficateURLError("Invalid Certificate URL");
    } else {
      setCertficateURLError("");
    }
  };

  const setCertDate = (e) => {
    const val = e.target.value;
    setCertificateDetails({ ...certificateDetails, certDate: val });

    // Validate certificate company name
    if (!val) {
      setCertficateDateError("Certficate date is required");
    } else if (new Date(val) > new Date()) {
      setCertficateDateError("Certificate date should not be a future date");
    } else {
      setCertficateDateError("");
    }
    handleChange(e);
  };

   useEffect(() => { 
    handleChange({
      target: {
        name: "skillsAccquired",
        value: selectedAccquiredSkills,
      },
    })
  }, [selectedAccquiredSkills]);
 
  return (
    <div className={Styles.profile_input_section}>
      <div className={Styles.profile_section_row}>
        <div className={Styles.profile_element}>
          <Field
            fullWidth
            name="certProvider"
            as={TextField}
            id="outlined-basic"
            label="Certification Provider Name *"
            variant="outlined"
            onChange={setCertproviderName}
            onBlur={setCertproviderName}
            error={ certificateProviderNameError || errors.certProvider && touched.certProvider}
            helperText={
              certificateProviderNameError || errors.certProvider && touched.certProvider
                ? errors.certProvider
                : ""
            }
            sx={{
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          />
        </div>
        <div className={Styles.profile_element}>
          <Field
            fullWidth
            name="certCourseName"
            as={TextField}
            id="outlined-basic"
            label="Certificate Course Name *"
            variant="outlined"
            onChange={setCertficateCourseName}
            onBlur={setCertficateCourseName}
            error={ certificateCourseNameError || errors.certCourseName && touched.certCourseName}
            helperText={
              certificateCourseNameError || errors.certCourseName && touched.certCourseName
                ? errors.certCourseName
                : ""
            }
            sx={{
              borderRadius: "8px",

              backgroundColor: "#FFFFFF",
            }}
          />
        </div>
      </div>

      <div className={Styles.profile_section_row}>
        <div className={Styles.profile_element}>
          <Field
            fullWidth
            name="certUrl"
            as={TextField}
            id="outlined-basic"
            label="Certification URL *"
            variant="outlined"
            onChange={setCertUrl}
            onBlur={setCertUrl}
            error={certificateURLError || (errors.certUrl && touched.certUrl)}
            helperText={
              certificateURLError || (errors.certUrl && touched.certUrl)
                ? errors.certUrl || certificateURLError
                : ""
            }
            sx={{
              borderRadius: "8px",

              backgroundColor: "#FFFFFF",
            }}
          />
        </div>
        <div className={Styles.profile_element}>
          <Field
            fullWidth
            name="certDate"
            as={TextField}
            id="outlined-basic"
            label="Certification Date *"
            type="date"
            variant="outlined"
            onChange={setCertDate}
            onBlur={setCertDate}
            error={ certificateDateError ||  errors.certDate && touched.certDate}
            helperText={
              certificateDateError || errors.certDate && touched.certDate ? errors.certDate : ""
            }
            sx={{
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      
        <DatePicker label="Basic date picker" />
    </LocalizationProvider> */}
        </div>
      </div>
      <div className={CertStyles.profile_section_work_skill_container}>
        <h1 className={CertStyles.skill_required_head}>Skills Acquired</h1>
        <div className={CertStyles.skill_selection_container}>
          <SkillSelection
            selectedAccquiredSkills={selectedAccquiredSkills}
            setSelectedAccquiredSkills={setSelectedAccquiredSkills}
            handleChange={handleChange}
            errors={errors}
            touched={touched}   
            name="skillsAccquired"
          
          />
        </div>
      </div>
      <div
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 24,
          display: "inline-flex",
          marginTop: 24,
        }}
      >
        <button
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
            background: "#0B6AEA",
            borderRadius: 8,
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            display: "flex",
          }}
          disabled={
            certificateProviderNameError ||
            certificateCourseNameError ||
            certificateURLError ||
            certificateDateError
          }
          type="submit"
        >
          <div className="save-changes-btn">Save Changes</div>
        </button>

        <button className="discard-btn" onClick={properties.discard_editview}>
          <div className="discard-btn">Discard Changes</div>
        </button>
        {/*  <button
          type="button"
          onClick={() => {
            console.log(
              percentageFlag,
              passedOutYearFlag,
              gradesFlag,
              "booo percentageFlag , passedOutYearFlag , gradesFlag"
            );
          }}
        >
          C H E C K F L A G
        </button> */}
      </div>
    </div>
  );
};

export default AddCertificate;
