import React from "react";

const BreadCrumbCCIcon = () => (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4371 15.4604L8.21798 12.403H7.67055C6.89925 12.403 6.17697 12.1889 5.55437 11.8147C4.28466 11.0532 3.43001 9.62986 3.43001 7.99901C3.43001 6.04951 4.6507 4.39741 6.34038 3.8173C6.58223 3.73396 6.83552 3.6735 7.09534 3.63591L7.51694 2.83029L8.39773 1.14225L8.93699 0.109488L8.99419 0H7.51694V0.00490215C3.35158 0.0915104 0 3.63591 0 7.99901C0 12.3621 3.35158 15.9081 7.51694 15.9931V15.998H10.8293L10.4387 15.4604H10.4371Z" fill="#0B6AEA"/>
    <path d="M17.208 7.30961C17.2685 6.95338 17.3028 6.58733 17.3028 6.21149C17.3028 6.20822 17.3028 6.20495 17.3028 6.20331C17.3028 5.36991 17.1427 4.57573 16.855 3.85018C16.4204 2.75042 15.6932 1.8108 14.7732 1.13428C14.2552 0.75353 13.6783 0.457757 13.0574 0.263297C12.6913 0.148909 12.3106 0.0704688 11.9167 0.03125L11.4183 0.985574L11.2827 1.2454L10.0539 3.59853H11.366C12.0409 3.60506 12.6521 3.88286 13.1015 4.33388C13.2682 4.50056 13.412 4.69175 13.5296 4.90092C13.7453 5.28494 13.8695 5.72778 13.8695 6.20331C13.8695 6.48602 13.8254 6.75728 13.7437 7.01057C13.4724 7.86685 12.7845 8.52703 11.9265 8.7362C11.7403 8.78195 11.5458 8.8081 11.3464 8.8081H8.54883L10.2026 11.0861L10.8153 11.9309L11.1585 12.4032L11.1683 12.4162L12.291 13.9637L13.7682 15.9999H17.1998L14.5902 12.4048L14.247 11.9325L14.0934 11.7217C14.1359 11.6989 14.1767 11.676 14.2176 11.6531C15.7618 10.7658 16.8861 9.18395 17.2064 7.30961H17.208Z" fill="#0B6AEA"/>
    </svg>
    
);

export default BreadCrumbCCIcon;
