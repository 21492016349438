const oidcConfig = {
  clientId:process.env.REACT_APP_KEYCLOAK_CLIENT_ID ,
  moodleBaseUrl: process.env.REACT_APP_MOODLE_URL,
  redirectUri: `${ process.env.REACT_APP_MOODLE_URL}/auth/oidc/`,
  scope: 'openid profile email',
  responseType: 'token',
  authority: process.env.REACT_APP_API_BASE_URL,
};

export default oidcConfig;

