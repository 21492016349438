import React, { useState, useRef, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Modal } from "react-bootstrap";
import axios from "axios";
import UserService from "../../services/UserService";
import qs from "qs";
import Keycloak_config from "../../services/Keycloak_config";
import Avatar from "../../assets/img/Avatar.svg";
const ProfileImageUpload = ({
  avatar,
  profileData,
  profilePic,
  percentage,
  AmazonKey,
  setAmazonKey,
  keyCloakUserId,
  handleSubmit,
  setProfileData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(profilePic);
  const [editedImage, setEditedImage] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [scale, setScale] = useState(1);

  const editorRef = useRef(null);
  const fileRef = useRef(null);
  const [profileImageKey, setProfileImageKey] = useState(null);
  const profilePicUrl = process.env.REACT_APP_PROFILEPIC_URL;

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const formData = new FormData();
  /* const [refreshFlag, triggerRefreshSetter] = useState(false);

  const triggerReload = () => window.location.reload();

  

  useEffect(() => {
    if (refreshFlag) {
      triggerReload()
      triggerRefreshSetter(false)
     }
  },[image]);  
 */
  const handleImageUpload = (event) => {
    const uploadedImage = event.target.files[0];
    
    formData.append("file", uploadedImage, { type: "image/jpeg" });
    setImage(uploadedImage);
  };

  const handleImageEdit = async (event) => {
    
    if (editorRef.current) {
      /*   const editedImageCanvas = editorRef.current
        .getImageScaledToCanvas()
        .toDataURL();
      const base64Image = editedImageCanvas.replace(
        /^data:image\/(png|jpeg);base64,/,
        ""
      ); */

    
      formData.append("file", image);
      formData.append("userId", keyCloakUserId); // Change this to a userId
      if (profileData.AWSKey !== null) {
        formData.append("key", AmazonKey);
        await axios
          .post(`${profilePicUrl}/upload-profile-pic`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((result) => {
      
            // alert(JSON.stringify(result.data.data.PresignedUrl));
            window.location.reload();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        await axios
          .post(`${profilePicUrl}/upload-profile-pic`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((result) => {
            setAmazonKey(result.data.data.key);
            setProfileData({ ...profileData, AWSKey: result.data.data.key });
            window.location.reload();
          })
          .catch((error) => {
            console.error(error);
          });
      }

      setShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  const uploadFile = () => {
    fileRef?.current?.click();
    setShowModal(true);
  };

  return (
    <div style={{ position: "relative", width: 150, height: 150 }}>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileRef}
        accept="image/*"
        onChange={handleImageUpload}
        image={image ?? avatar}
      />
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 32,
            paddingBottom: 0,
            borderRadius: 5,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "inline-flex",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit profile picture</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ top: 13 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <AvatarEditor
                ref={editorRef}
                image={image ?? avatar}
                width={250}
                height={250}
                border={0}
                borderRadius={125}
                scale={scale}
                onImageReady={() => console.log("Image ready")}
              />
              <button
                className="btn btn-primary"
                onClick={uploadFile}
                style={{ color: "white", backgroundColor: "#0B6AEA" }}
              >
                Upload New picture
              </button>
              <input
                type="range"
                style={{ position: "" }}
                min="1"
                max="3"
                step="0.01"
                value={scale}
                onChange={(e) => setScale(parseFloat(e.target.value))}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={handleImageEdit}
              type="file"
              style={{ color: "white", backgroundColor: "#0B6AEA" }}
            >
              Save Image
            </button>
          </Modal.Footer>
        </div>
      </Modal>
      <div
        style={{
          width: "95%",
          height: "95%",
          position: "absolute",
          top: 9,
          left: 8,
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "23.25%",
            top: "-6%",
            color: "#27AE60",
            width: "84.34px",
            height: "33.74px",
            border: "1px solid #E2EBF3",
            borderRadius: "99px",
            background: "white",
            fontSize: "20.24px",
            fontWeight: "bold",
            padding: "0.125rem 1.25rem",
            zIndex: 1,
          }}
        >
          {`${percentage}%`}
        </div>
        <CircularProgressbarWithChildren
          styles={{ root: { transform: "rotateZ(42deg)" } }}
          value={percentage}
          maxValue={131}
          children={
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowModal(true);
              }}
            >
              {editedImage ? (
                <img
                  src={editedImage}
                  alt="Edited"
                  style={{
                    scale: "0.85",
                    borderRadius: "999px",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <img
                  src={AmazonKey ? avatar : Avatar}
                  alt="Avatar"
                  style={{
                    width: 140,
                    height: 140,
                    border: "5px solid transparent",
                    borderRadius: 9999,
                    transformOrigin: "0 0",
                    padding: 8,
                  }}
                />
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ProfileImageUpload;
