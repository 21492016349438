const TermsAndConditionModal = () => (
  <span
    style={{
      textDecoration: "underline",
      color: "#80919F",
      fontSize: "10px",
      fontFamily: "Inter",
      fontWeight: 400,
      wordWrap: "break-word",
      cursor: "pointer",
    }}
    data-bs-toggle="modal"
    data-bs-target="#exampleModal"
  >
    Conditions of Use
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              <strong>
                <u>Terms of Service</u>
              </strong>
            </p>
            <p>
              <u>INFORMATION ABOUT USE</u>
            </p>
            <p>
              Welcome to Creamcollar Edutech Private Limited&rdquo; or
              &ldquo;We&rdquo; or &ldquo;Us&rdquo;, a company incorporated under
              the Companies Act, 1956, operating from its web site being&nbsp;
              <a href="https://creamcollar.com&nbsp;">
                https://creamcollar.com&nbsp;
              </a>
              and its related application programming interfaces (API&rsquo;s),
              mobile applications and online services (collectively,
              &ldquo;Website&rdquo;).
            </p>
            <p>
              This Terms of Service is a legal contract between you
              (&ldquo;You&rdquo;) and Creamcollar Edutech Private Limited
              regarding your use of the Website. For ease of reference, the
              visitors and users of the Website are referred to individually as
              &ldquo;User&rdquo; and collectively as &ldquo;Users&rdquo;.
            </p>
            <p>
              PLEASE READ THIS TERMS OF SERVICE CAREFULLY. BY REGISTERING FOR,
              ACCESSING, BROWSING, OR USING THE WEBSITE, YOU ACKNOWLEDGE THAT
              YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS TERMS OF
              SERVICE, INCLUDING THE CREAMCOLLAR EDUTECH PRIVATE LIMITED PRIVACY
              NOTICE&nbsp;
              <a href="https://www.embibe.com/privacy-policy">
                (https://www.creamcollar.com/privacy-policy)&nbsp;
              </a>
              , THIRD PARTY CONTENT POLICY (include appropriate URL as and when
              it is made live) AND ANY ADDITIONAL GUIDELINES (AS DEFINED BELOW)
              (COLLECTIVELY, THE &ldquo;TERMS&rdquo; or &ldquo;TERMS OF
              SERVICE&rdquo;).
            </p>
            <ol>
              <li>
                <u> INTRODUCTION</u>
              </li>
            </ol>
            <p>
              This Terms and Conditions of Use (&ldquo;Terms of Use&rdquo;/
              &ldquo;Terms&rdquo;) is a legal contract between
              &ldquo;CREAMCOLLAR EDUTECH PRIVATE LIMITED&rdquo; or
              &ldquo;We&rdquo; or &ldquo;Us&rdquo;), a company incorporated
              under the Companies Act, 1956, and (&ldquo;You&rdquo; or
              &ldquo;you&rdquo;), a User (as defined below) of the Creamcollar
              Edutech Private Limited website being&nbsp;
              <a href="https://www.creamcollar.com/">https://www.creamcollar.com/</a>
              &nbsp;and its related application programming interfaces
              (API&rsquo;s), mobile applications, Creamcollar Edutech Private
              Limited Services (as defined below) and any other product and
              services that Creamcollar Edutech Private Limited may provide now
              or in the future (collectively &ldquo;Platform&rdquo;). These
              Terms of Use govern your use of the Platform through any mode of
              computers, tablets, mobile or any other storage/transmitting
              device.
            </p>
            <p>
              Creamcollar Edutech Private Limited is an EduTech platform that
              improves learning outcomes. By registering for, accessing,
              browsing, or using the website, you acknowledge that you have
              read, understood, and agree to be bound by these Terms of Use,
              including the Creamcollar Edutech Private Limited Privacy
              Policy&nbsp;
              <a href="https://www.embibe.com/privacy-policy">
                (https://www.creamcollar.com/privacy-policy&nbsp;
              </a>
              .If you do not agree with these Terms of Use and Privacy Policy,
              then please do not use the Platform or avail the Services.
            </p>
            <p>
              The Terms are an electronic record in terms of the Information
              Technology Act, 2000 (as amended / re-enacted) (&ldquo;IT Act")
              and rules thereunder and is published in accordance with the
              provisions of Rule 3 (1) of Information Technology (Intermediaries
              Guidelines and Digital Media Ethics code) Rules, 2021, which
              mandates for publishing of rules and regulations, privacy policy
              and terms of use for access or usage of Application. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures.
            </p>
            <ol start="2">
              <li>
                <u> ELIGIBILITY TO USE THE PLATFORM</u>
              </li>
            </ol>
            <p>
              You must be legally competent and should have at least attained
              the age of majority in your country / state of residence for
              entering into a binding contract. If you are a Minor in your
              country / state of residence, i.e. under the age of 18 years in
              India or such age group which is considered a Minor in your
              country / state of residence, you are not eligible to utilize or
              register for the Platform. If you wish to use our Platform, such
              use shall be made available to you by your legal guardian or
              parents. The parent or legal guardian agree to be bound by these
              Terms of Use in respect of your child&rsquo;s use of the Platform.
              In the event a Minor utilizes or registers to the Platform, We
              will assume that he/she has obtained the consent of the legal
              guardian or parents and such use is made available by the legal
              guardian or parents. We will not be responsible for any
              consequence that may arise as a result of a Minor&rsquo;s usage or
              registering to the Platform or any of the Services.
            </p>
            <p>
              If you are a school/ educational institution or a buyer, you
              agree, on behalf of your school/ educational institution, that the
              User is bound by the Terms of Use, unless you, your school
              /educational institution has a separate written service agreement
              with Creamcollar Edutech Private Limited that accepts these Terms
              on behalf of you, school/ educational institution.
            </p>
            <p>
              You represent and warrant that You are in compliance with all
              Applicable Laws when you access and use the Platform. You agree to
              use the Platform only in compliance with these Terms of Use and
              Applicable Laws, and in a manner that does not violate legal
              rights of Creamcollar Edutech Private Limited or those of any
              third party(ies).
            </p>
            <ol start="3">
              <li>
                <u> MODIFICATIONS</u>
              </li>
            </ol>
            <p>
              <strong>
                3.1 &ldquo;Modifications of the Terms:&rdquo;&nbsp;
              </strong>
              We may update these Terms of Use from time to time to reflect new
              practices or technologies that impact use of the Platform. The
              date of the last revision or update appears at the top under the
              title. Please read this Terms of Use carefully. Creamcollar
              Edutech Private Limited reserves the right to change the Terms
              under which the Creamcollar Edutech Private Limited Services are
              offered, including but not limited to the charges, if any,
              associated with the use of the Creamcollar Edutech Private Limited
              Services. The Terms may be further modified based on changes in
              the business, legal and regulatory requirements and will be
              updated online. We will notify you whenever any change to the
              Terms and Conditions are made. You are encouraged to periodically
              visit this page to review these Terms and any changes to it.
            </p>
            <p>
              <strong>
                3.2 &ldquo;Modification of Creamcollar Edutech Private Limited
                Services:&rdquo;&nbsp;
              </strong>
              Creamcollar Edutech Private Limited reserves the right to add,
              modify or delete any content or features available in the
              Creamcollar Edutech Private Limited Services at any time at its
              sole discretion.
            </p>
            <ol start="4">
              <li>
                <u> DEFINITIONS</u>
              </li>
            </ol>
            <p>
              <strong>4.1 &ldquo;Applicable Law&rdquo;&nbsp;</strong>
              shall mean all applicable Indian laws, by-laws, rules,
              regulations, orders ordinances, protocols, codes, guidelines,
              policies, notices, directions, judgments, decrees or other
              requirements or official directive of any governmental authority
              or person acting under the authority of any governmental authority
              of India;
            </p>
            <p>
              <strong>4.2 &ldquo;Content&rdquo;&nbsp;</strong>
              shall include (but is not limited to) any data, content, images,
              videos, location data or other materials or information of any
              type that is available through the Platform and shall include Free
              Content;
            </p>
            <p>
              <strong>
                4.3 &ldquo;Intellectual Property Rights&rdquo;&nbsp;
              </strong>
              shall mean registered and unregistered rights in all forms of
              intellectual property subsisting under the laws of India and all
              analogous rights subsisting under the laws of other jurisdictions
              and shall include any legally protectable product or process of
              the human intellect whether registrable as patents, trademarks,
              copyrights, designs or otherwise such as an invention, expression
              or literary creation, unique name, trade secret, business method,
              database, industrial process, computer program, source code,
              process or presentation, visual interfaces, graphics, design,
              compilation, information. content, educational videos and
              exercises;
            </p>
            <p>
              <strong>
                4.4 &ldquo;Creamcollar Edutech Private Limited Data&rdquo;&nbsp;
              </strong>
              shall mean any and all information relating to the User provided
              to the Platform by the User at his sole discretion in order to use
              the Platform;
            </p>
            <p>
              And any other service which may be offered by Creamcollar Edutech
              Private Limited to its users in the future.
            </p>
            <p>
              <strong>4.5 &ldquo;Free Content&rdquo;&nbsp;</strong>
              mean any Content available for free to you through the Platform.
            </p>
            <p>
              <strong>4.6 &ldquo;User Content&rdquo;&nbsp;</strong>
              shall mean Content that you upload, share or transmit to, through
              or in connection with the Platform, such as likes, ratings,
              reviews, images, photos, messages, profile information, and any
              other materials that you publicly display or displayed in your
              account profile; and
            </p>
            <p>
              <strong>4.7 "User/ Users"&nbsp;</strong>shall mean teachers,
              educational institutes, students, or any person that uses or
              visits the Platform.
            </p>
            <ol start="5">
              <li>
                <u> USER ACCOUNT, PASSWORD AND SECURITY</u>
              </li>
            </ol>
            <p>
              If you wish to access our Platform, then you will be asked to
              create an account. In order to create an account with the Platform
              you will be required to select a username and password and provide
              us with certain information. This information will be collected,
              stored, and processed in accordance with our Privacy Policy&nbsp;
              <u>(https://</u> <u>creamcollar.com/privacy-policy)</u>
            </p>
            <p>
              If permitted by Creamcollar Edutech Private Limited, you may also
              register to the Platform using your existing accounts with
              Facebook, Google or any other account integrated by Creamcollar
              Edutech Private Limited and their respective log-in credentials.
            </p>
            <p>
              You must provide accurate, correct and complete registration
              information at the time of account set up and post account set up.
              You are responsible for the security of your passwords and for any
              use of your account. If you become aware of any unauthorized use
              of your password or of your account, you agree to notify
              Creamcollar Edutech Private Limited immediately by sending an
              email to support@creamcollar.com.
            </p>
            <p>
              You agree that you are eligible to create and use only one account
              with one User name. You are not permitted to register or create an
              account on behalf of any entity other than yourself.
            </p>
            <p>
              You agree not to register and use an account with an identity that
              is fraudulent or use the account of another User without the
              written permission of the User.
            </p>
            <p>
              You shall ensure that you exit from your account at the end of
              each session.
            </p>
            <p>
              You are solely responsible for maintaining the confidentiality of
              your account and password, and for all the activities that occur
              in relation to your account or password.
            </p>
            <p>
              You can delete/unregister your account anytime by submitting a
              request to support@ creamcollar.com. Creamcollar Edutech Private
              Limited may require additional information from the User in order
              to enable us to verify your account before the deletion of your
              account.
            </p>
            <ol start="6">
              <li>
                <u> USER CONTENT</u>
              </li>
            </ol>
            <p>
              You are permitted to use the Platform for your personal use only
              and not for any commercial use, unless expressly permitted by
              Creamcollar Edutech Private Limited. By posting, submitting or
              transmitting User Content in the Platform other than information
              submitted for registration you grant to Creamcollar Edutech
              Private Limited a worldwide, non-exclusive, royalty free,
              transferable fully paid, license to copy, publicly perform,
              publicly display, reformat, translate, excerpt (in whole or in
              part) and distribute User Content, in or through any medium now
              known or hereafter invented, for any purpose.
            </p>
            <p>
              By posting, submitting, or transmitting User Content using our
              Platform you permit Creamcollar Edutech Private Limited the right
              to store that information with us, and to use such User Content
              for posting, maintenance and marketing. You agree and understand
              that you must evaluate the submission of such User Content to the
              Platform and will ensure that the User Content does not infringe
              the rights of others. You hereby agree that you shall, defend, and
              hold harmless Creamcollar Edutech Private Limited for all claims
              resulting from User Content provided by the User to Creamcollar
              Edutech Private Limited.
            </p>
            <p>
              You agree that Creamcollar Edutech Private Limited has the right
              to reject, remove, edit, and move or prevent access to any User
              Content provided by the User to Creamcollar Edutech Private
              Limited. However, the obligation to mention the accuracy and
              correctness of the User Content remains with you.
            </p>
            <p>
              You acknowledge and agree that neither Creamcollar Edutech Private
              Limited nor any third party service providers are responsible for
              the Content of any material submitted, transmitted or posted by
              any User of the Platform.
            </p>
            <p>
              You agree that you will not upload on the Creamcollar Edutech
              Private Limited Services, any Content that is contrary to the
              Terms and the Creamcollar Edutech Private Limited&rsquo;s content
              guidelines, the Privacy Policy, or any other policy of Company, as
              updated from time to time, or that is contrary to applicable laws
              and regulations. If any such Content is uploaded on the
              Creamcollar Edutech Private Limited Services and in your account
              in any manner and for any reason whatsoever, you shall ensure that
              such Content is deleted from the Creamcollar Edutech Private
              Limited Services and your account immediately.
            </p>
            <ol start="7">
              <li>
                <u> CONTENT POSTED ON THE PLATFORM</u>
              </li>
            </ol>
            <p>
              You understand that any material, Content and its arrangements
              that appear as &ldquo;Creamcollar Edutech Private Limited
              content&rdquo; or &ldquo;Creamcollar Edutech Private Limited
              Content&rdquo; are owned by or licensed to Creamcollar Edutech
              Private Limited, subject to copyright or other Intellectual
              Property Rights held by third parties. Accessing or using Platform
              does not give you ownership of any Intellectual Property Rights of
              Creamcollar Edutech Private Limited
            </p>
            <p>
              The Content posted in the Platform is made available to you solely
              for academic purposes and for such other purpose as may be
              expressly permitted by Creamcollar Edutech Private Limited.
            </p>
            <p>
              You are not permitted to download, copy, photoshoot or circulate
              any part or portion of the Content offered on the Platform.
            </p>
            <ol start="8">
              <li>
                <u> FREE CONTENT</u>
              </li>
            </ol>
            <p>
              The User may be provided with certain Free Content upon
              registering to the Platform. Such Free Content is provided at the
              discretion of Creamcollar Edutech Private Limited and can be
              modified into a paid subscription any point of time after
              notification to the user.
            </p>
            <ol start="9">
              <li>
                <u> CREAMCOLLAR EDUTECH PRIVATE LIMITED LICENSE GRANT</u>
              </li>
            </ol>
            <p>
              Subject to the terms and conditions of this Terms of Use,
              Creamcollar Edutech Private Limited grants you a personal,
              limited, non-exclusive, non-transferable, non-sub-licensable,
              revocable license to access the Platform. Except for this limited
              grant of access and use under these Terms of Use, we do not grant
              you any other right to the Platform.
            </p>
            <p>
              As a condition for your use of the Platform you hereby represent
              and warrant that you shall not use any kind of measures,
              mechanisms or tools (software or hardware) that could interfere
              with the functioning of the Platform or any of Creamcollar Edutech
              Private Limited Services.
            </p>
            <ol start="10">
              <li>
                <u> RESTRICTIONS</u>
              </li>
            </ol>
            <p>
              You agree, undertake and confirm that Your use of the Platform
              shall be strictly governed by the following binding principles and
              You agree not to:
            </p>
            <p>
              <strong>10.1</strong>&nbsp;defeat, damage or interfere with any
              security feature of our Platform, nor attempt to do so.
            </p>
            <p>
              <strong>10.2</strong>&nbsp;cheat, use any deceptive methods or
              otherwise evade or violate the rules or intended operation of any
              software, nor attempt to do so.
            </p>
            <p>
              <strong>10.3</strong>&nbsp;alter or modify any Content of our
              Platform, other than information or User Content you have
              submitted or posted through the Platform.
            </p>
            <p>
              <strong>10.4</strong>&nbsp;reproduce, duplicate, copy, sell,
              trade, or exploit, for any commercial purpose, any Content or
              component of or any access to the Platform, without the prior
              written permission of Creamcollar Edutech Private Limited, except
              for User Content or registration information you have submitted or
              posted through the Platform.
            </p>
            <p>
              <strong>10.5</strong>&nbsp;use or attempt to use any automated
              system, software, or device to generate and send communications to
              or through the Platform nor will you annoy or harass any person
              through or in connection with the use of the Platform.
            </p>
            <p>
              <strong>10.6</strong>&nbsp;use or attempt to use any engine,
              software, tool, agent, or other device or mechanism (including,
              without limitation, browsers, spiders, robots, avatars, or
              intelligent agents) to navigate or search the Platform other than
              the search engine and search agents available on the Platform.
            </p>
            <p>
              <strong>10.7</strong>&nbsp;upload, post, transmit, share or
              otherwise make available through the Platform any illegal
              material, or any material that contains software viruses or any
              other computer code designed to interfere with the functionality
              of any computer software or hardware or telecommunications
              equipment.
            </p>
            <p>
              <strong>10.8</strong>&nbsp;upload, transmit, or post any material
              that is obscene, sexually explicit, hateful, intimidating or
              threatening, or that violates the rights of Creamcollar Edutech
              Private Limited or of any third party.
            </p>
            <p>
              <strong>10.9</strong>&nbsp;use the Platform to upload, modify,
              publish, post, transmit, or share material that is defamatory,
              obscene, pornographic, paedophilic, invasive of another&rsquo;s
              privacy
            </p>
            <p>
              <strong>10.10</strong>&nbsp;including bodily privacy, insulting or
              harassing on the basis of gender, libellous,
            </p>
            <p>
              <strong>10.11</strong>&nbsp;racially or ethnically objectionable,
              relating or encouraging money laundering or
            </p>
            <p>
              <strong>10.12</strong>&nbsp;gambling, or otherwise inconsistent
              with or contrary to the laws in force.
            </p>
            <p>
              <strong>10.13</strong>&nbsp;use our Platform to post, transmit, or
              share material that you did not create or that you do not have
              permission to use.
            </p>
            <p>
              <strong>10.14</strong>&nbsp;use the Platform for any commercial
              use as the Platform is intended for personal, non-commercial use
              only except with our prior written permission.
            </p>
            <p>
              <strong>10.15</strong>&nbsp;copy or distribute in any manner, any
              proprietary information for any commercial or business use, except
              with our prior written permission.
            </p>
            <p>
              <strong>10.16</strong>&nbsp;modify, edit or change or otherwise
              make any derivative work of the paper or digital copies of any
              materials You have printed off or downloaded in any way.
            </p>
            <p>
              <strong>10.17</strong>&nbsp;make unsolicited offers,
              advertisements, proposals, or send junk mail or spam to other
              Users of the Platform.
            </p>
            <p>
              <strong>10.18</strong>&nbsp;reverse engineer, decompile,
              disassemble or otherwise attempt to discover the source code of
              the Platform or any part thereof, except and only to the extent
              that such activity is expressly permitted by Applicable Law.
            </p>
            <p>
              <strong>10.19</strong>&nbsp;interfere with or damage operation of
              the Platform, upload or otherwise disseminate viruses, adware,
              spyware, worms, or other malicious code intended to interrupt,
              destroy or limit the functionality of any computer resource.
            </p>
            <p>
              <strong>10.20</strong>&nbsp;not to upload, modify, publish,
              transmit, store, update or share any information that, &mdash; is
              harmful to child
            </p>
            <p>
              <strong>10.21</strong>&nbsp;violates any law for the time being in
              force;
            </p>
            <p>
              <strong>10.22</strong>&nbsp;deceives or misleads the addressee
              about the origin of the message or knowingly and intentionally
              communicates any information which is patently false or misleading
              in nature but may reasonably be perceived as a fact;
            </p>
            <p>
              <strong>10.23</strong>&nbsp;impersonates another person;
            </p>
            <p>
              <strong>10.24</strong>&nbsp;threatens the unity, integrity,
              defence, security or sovereignty of India, friendly relations with
              foreign States, or public order, or causes incitement to the
              commission of any cognisable offence or prevents investigation of
              any offence or is insulting other nation;
            </p>
            <p>
              <strong>10.25</strong>&nbsp;is patently false and untrue, and is
              written or published in any form, with the intent to mislead or
              harass a person, entity or agency for financial gain or to cause
              any injury to any person;
            </p>
            <ol start="11">
              <li>
                <u> LIABILITY DISCLAIMER</u>
              </li>
            </ol>
            <p>
              <strong>11.1</strong>&nbsp;CREAMCOLLAR EDUTECH PRIVATE LIMITED
              AND/OR ITS RESPECTIVE AFFILIATES MAKES NO REPRESENTATIONS ABOUT
              THE COMPATIBILITY, SUITABILITY, RELIABILITY, AVAILABILITY,
              TIMELINESS AND ACCURACY OF THE INFORMATION, SOFTWARE AND
              CREAMCOLLAR EDUTECH PRIVATE LIMITED SERVICES CONTAINED ON THE
              APPLICATION FOR ANY PURPOSE. ALL SUCH INFORMATION, SOFTWARE AND
              CREAMCOLLAR EDUTECH PRIVATE LIMITED SERVICES ARE PROVIDED "AS IS"
              WITHOUT WARRANTY OF ANY KIND. CREAMCOLLAR EDUTECH PRIVATE LIMITED
              AND/OR ITS RESPECTIVE SUPPLIERS HEREBY DISCLAIMS ALL WARRANTIES
              AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, AND
              CREAMCOLLAR EDUTECH PRIVATE LIMITED SERVICES, INCLUDING ALL
              IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
            </p>
            <p>
              <strong>11.2</strong>&nbsp;YOU SPECIFICALLY AGREE THAT CREAMCOLLAR
              EDUTECH PRIVATE LIMITED SHALL NOT BE RESPONSIBLE FOR UNAUTHORISED
              ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, ANY
              MATERIAL OR DATA SENT OR RECEIVED OR NOT SENT OR RECEIVED, OR ANY
              TRANSACTIONS ENTERED INTO THROUGH THE PLATFORM.
            </p>
            <p>
              <strong>11.3</strong>&nbsp;YOU SPECIFICALLY AGREE THAT CREAMCOLLAR
              EDUTECH PRIVATE LIMITED IS NOT RESPONSIBLE OR LIABLE FOR ANY
              INFRINGEMENT OF ANOTHER'S RIGHTS, INCLUDING INTELLECTUAL PROPERTY
              RIGHTS. YOU SPECIFICALLY AGREE THAT CREAMCOLLAR EDUTECH PRIVATE
              LIMITED IS NOT RESPONSIBLE FOR ANY SERVICES SENT USING AND/OR
              INCLUDED IN THE APPLICATION BY ANY THIRD PARTY.
            </p>
            <p>
              <strong>11.4</strong>&nbsp;CREAMCOLLAR EDUTECH PRIVATE LIMITED
              DOES NOT GUARANTEE COMPATIBILITY WITH ALL OPERATING SYSTEMS, SMART
              PHONES, NETWORKS, INCLUDING ALL VERSIONS OF THEM. THE USE OF THE
              SMART PHONE, SITE, AND SOFTWARE, AND AUTOMATIC UPGRADES OF THE
              SOFTWARE, MAY REQUIRE UPGRADES TO COMPUTER OR SMART PHONE
              OPERATING SYSTEMS,&nbsp;NETWORKS, AND APPLICATION SOFTWARE.
            </p>
            <p>
              <strong>11.5</strong>&nbsp;ANY REQUIREMENTS FOR THE FUNCTIONING OF
              THE PLATFORM ON YOUR PHONE OR DEVICE, SUCH AS SUFFICIENT BATTERY
              BACK UP, INTERNET NETWORK STRENGTH ETC. IS YOUR SOLE
              RESPONSIBILITY.
            </p>
            <p>
              <strong>11.6</strong>&nbsp;IN NO EVENT SHALL CREAMCOLLAR EDUTECH
              PRIVATE LIMITED AND/OR ITS AFFILIATES BE LIABLE FOR ANY DIRECT,
              INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR
              ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
              OR IN RELATION TO THE PROVISION OF OR FAILURE TO PROVIDE
              CREAMCOLLAR EDUTECH PRIVATE LIMITED SERVICES, OR FOR ANY
              INFORMATION, SOFTWARE AND CREAMCOLLAR EDUTECH PRIVATE LIMITED
              SERVICES OBTAINED THROUGH THE PLATFORM, OR OTHERWISE ARISING OUT
              OF THE USE OF, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
              LIABILITY OR OTHERWISE, EVEN IF CREAMCOLLAR EDUTECH PRIVATE
              LIMITED OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
              POSSIBILITY OF DAMAGES.
            </p>
            <p>
              <strong>11.7</strong>&nbsp;YOU EXPRESSLY AGREE AND UNDERSTAND THAT
              THE CONTENT AVAILABLE IN THE PLATFORM IS FOR EDUCATIONAL
              INFORMATION PURPOSES ONLY AND IS NOT A SUBSTITUTE FOR YOUR
              ACADEMIC CURRICULUM. CREAMCOLLAR EDUTECH PRIVATE LIMITED AND ITS
              AFFILIATES, SUBSIDIARIES, DIRECTORS, AGENTS AND EMPLOYEES, AND
              THIRD PARTIES ASSOCIATED WITH THE PLATFORM MAKE NO WARRANTY OF ANY
              KIND CONCERNING THE CONTENT OR ANY OUTCOME AND/OR THE RESULTS OF
              THE ENTRANCE EXAMS OR ANY OTHER EXAM TAKEN BY YOU INCLUDING BUT
              NOT LIMITED TO THE MOCK TESTS, QUESTIONS, PRODUCTS, INTERACTIVE
              SESSIONS AND ANY OTHER INFORMATION PROVIDED ON THE PLATFORM. WHILE
              EVERY EFFORT IS MADE IN PREPARATION OF EDUCATIONAL MATERIAL
              INTENDED FOR ITS USERS, CREAMCOLLAR EDUTECH PRIVATE LIMITED AND
              ITS AFFILIATES, SUBSIDIARIES, DIRECTORS, AGENTS AND EMPLOYEES ARE
              NOT RESPONSIBLE FOR ANY ERRORS, OMISSIONS OR STATEMENTS ON THE
              PLATFORM OR ANY WEBSITE WITH WHICH THESE PAGES CONNECT WITH.
            </p>
            <ol start="12">
              <li>
                <u> SPONSORS, ADVERTISERS AND THIRD PARIES</u>
              </li>
            </ol>
            <p>
              The Platform may contain links to other web sites/applications
              ("Linked Sites"). The Linked Sites are not under the control of
              Creamcollar Edutech Private Limited and Creamcollar Edutech
              Private Limited is not responsible for the contents of any Linked
              Site, including without limitation any link contained in a Linked
              Site, or any changes or updates to a Linked Site or any
              information transmitted on a Linked Site. The inclusion of any
              link does not imply endorsement by Creamcollar Edutech Private
              Limited of the site or any association with its operators. Any
              dealings with third parties (including advertisers) included
              within or available via a link from the Platform or participation
              in promotions, including the delivery of and the payment for goods
              and services, and any other terms, conditions, warranties or
              representations associated with such dealings or promotions, are
              solely between you and the advertiser or other third party.
              Creamcollar Edutech Private Limited shall not be responsible or
              liable for any part of any such dealings or promotions.
            </p>
            <p>
              You acknowledge and agree that the use of any Linked Sites is
              governed by such third party&rsquo;s terms of use, license
              agreement, privacy policy, or other such agreement. CREAMCOLLAR
              EDUTECH PRIVATE LIMITED DISCLAIMS ANY AND ALL RESPONSIBILITY FOR
              ANY DISCLOSURE OF INFORMATION OR ANY OTHER PRACTICES OF ANY THIRD
              PARTY. CREAMCOLLAR EDUTECH PRIVATE LIMITED EXPRESSLY DISCLAIMS ANY
              WARRANTY WITH RESPECT TO YOUR PERSONAL OR OTHER INFORMATION THAT
              MAY BE COLLECTED, PROCESSED, SHARED OR RETAINED BY ANY THIRD PARTY
            </p>
            <ol start="13">
              <li>
                <u> INTEGRATED SERVICE</u>
              </li>
            </ol>
            <p>
              We permit You to access the Platform, or other platforms on the
              internet or via associated user accounts such as but not limited
              to Creamcollar Edutech Private Limited&rsquo;s Facebook Account
              (https://www.facebook.com/Creamcollar Edutech Private Limited.me/)
              (&ldquo;Integrated Service&rdquo;). By registering for the
              Platform using (or otherwise granting access to) an Integrated
              Service, you agree that We may access your Integrated
              Service&rsquo;s account information, and You agree to any and all
              terms and conditions of the Integrated Service regarding your
              access or use of the Platform, or the mobile application platform
              or other platforms on the internet as the case may be via the
              Integrated Service. You agree that any Integrated Service is a
              Reference Site, i.e. an integrated service account and You are
              solely responsible for your interactions with the Integrated
              Service as a result of accessing or using the Platform, or the
              mobile application platform or other platforms on the internet as
              the case may be through the Integrated Service. The Terms do not
              apply, replace or supersede the terms and conditions of use or the
              privacy policy of the Integrated Service. Please be advised that
              any affiliated service account such as Facebook, Google, etc.
              shall be construed into the definition of &ldquo;Integrated
              Service&rdquo; even if the same has not been specifically
              mentioned herein.
            </p>
            <ol start="14">
              <li>
                <u> INDEMNIFICATION</u>
              </li>
            </ol>
            <p>
              To the maximum extent permitted by Applicable Law, you agree to
              defend, indemnify and hold Creamcollar Edutech Private Limited and
              its affiliates, subsidiaries, directors, agents and employees, and
              third parties associated with the Platform harmless against any
              damages, costs and expenses, including reasonable attorneys&rsquo;
              fees, arising out of or related to your breach or violation of
              these Terms of Use.
            </p>
            <ol start="15">
              <li>
                <u> INTELLECTUAL PROPERTY RIGHTS INFRINGEMENT</u>
              </li>
            </ol>
            <p>
              We respect the Intellectual Property Rights of others and You
              agree to protect Creamcollar Edutech Private Limited Intellectual
              Proprietary Rights and the intellectual proprietary rights of all
              others having rights in the Platform during and after the term of
              this Terms of Use. We restrict Users from uploading, posting or
              otherwise transmitting materials that violate the Intellectual
              Property Rights of third parties.
            </p>
            <p>
              When we receive notification of alleged copyright complaints that
              do not comply with the Copyright Right Act, 1957, we shall
              endeavor to promptly remove or disable access to the illegal
              infringing material. We may also terminate the accounts of the
              infringers.
            </p>
            <p>
              If you are a copyright owner or an agent thereof and you believe
              that any content on the Platform that infringes upon your
              copyrights, you may submit a notice to us located at
              support@creamcollar.com
            </p>
            <ol start="16">
              <li>
                <u> TERMINATION</u>
              </li>
            </ol>
            <p>
              Creamcollar Edutech Private Limited may, in its sole discretion,
              immediately terminate, limit or suspend your account, delete your
              registration, other information, or User Content, and/or prohibit
              you from using or accessing the Platform without any notice, if
              you have violated any of the provisions in this Terms of Use.
            </p>
            <p>
              If you wish to terminate your account on your mobile device, you
              may do so by removing the application from your device and
              following the instructions on the application or through the
              Platform.
            </p>
            <p>
              Upon termination of the Terms by you, your account status will be
              reflected as &ldquo;Inactive&rdquo;. Such termination is only
              reflected upon a written intimation by the User to Creamcollar
              Edutech Private Limited. On termination, you lose the right to
              access or use the Platform.
            </p>
            <ol start="17">
              <li>
                <u> REPRESENTATION AND WARRANTIES</u>
              </li>
            </ol>
            <p>
              You represent, warrant and indemnify that You have the necessary
              right, license, authorization or permission to post, upload or
              publish any video, image, text, software, information or any
              content on the Platform through the Message Feature. The Message
              Feature, means and includes, a &lsquo;pop-up&rsquo; featured on
              Creamcollar Edutech Private Limited&rsquo;s Platform to provide
              support to any user, whether a registered user or an unregistered
              user. You hereby agree that by posting, uploading or publishing
              the same on our Platform, You have authorized Us to use the same
              without any restrictions.
            </p>
            <ol start="18">
              <li>
                <u> PAYMENT</u>
              </li>
            </ol>
            <p>
              Creamcollar Edutech Private Limited may determine subscription
              plans which shall allow access to the Platform and usage of the
              Services. These subscription plans may be available on the
              Platform from time to time. You are responsible for any taxes,
              including goods and services tax, resulting from your use of the
              Platform. All fees are non-refundable.
            </p>
            <p>
              On non-payment of subscription fees, Creamcollar Edutech Private
              Limited reserves its right to terminate your access to the
              Platform.
            </p>
            <ol start="19">
              <li>
                <u> PLATFORM INFORMATION</u>
              </li>
            </ol>
            <p>
              This Platform does not sell or rent the User information to any
              third party. You hereby acknowledge that when you use the
              Platform, We may collect certain information from you like. Such
              collection of information shall be in accordance with our Privacy
              Policy available on the Platform.
            </p>
            <ol start="20">
              <li>
                <u> COMPLAINTS AND GRIEVANCES</u>
              </li>
            </ol>
            <p>
              You understand that by using this Platform or any materials
              provided in the Platform, you may encounter Platform Content that
              may be deemed by some to be offensive, indecent, or objectionable,
              which Platform Content may or may not be identified as such. You
              agree to use the Platform and any related materials or services at
              your sole risk and that we will have no liability to you for the
              Content that may be deemed offensive, indecent, or objectionable.
              All complaints should be sent by email at support@creamcollar.com
              or write to us at the following address:
            </p>
            <p>Attn: Legal Team</p>
            <p>Creamcollar Edutech Private Limited, Chennai</p>
            <p>Or</p>
            <p>You may forward Your request/complaints to Grievance Officer.</p>
            <p>Name:</p>
            <p>Contact E-mail: info@creamcollar.com</p>
            <ol start="21">
              <li>
                <u> CONTACT US</u>
              </li>
            </ol>
            <p>
              If you have any questions concerning the Terms of Use please
              contact us at creamcollar.com.
            </p>
            <ol start="22">
              <li>
                <u> CONSENT FOR RECEIPT OF PHONE CALLS, SMS AND/OR E-MAILS:</u>
              </li>
            </ol>
            <p>
              Your registration on the Platform shall be deemed to be your
              consent to be contacted by Creamcollar Edutech Private Limited,
              (i) on the mobile number shared by you (ii) by way of SMS or email
              notifications or messages in any other electronic form (iii) Chat
              Support tools; (iv) Facebook Messenger, Twitter, Instagram, or any
              other social media network; (v) WhatsApp, Telegram, Viber and any
              other such messaging modes. You agree and acknowledge that at no
              times shall Creamcollar Edutech Private Limited be liable for
              contacting you through any of the mediums stated above.
            </p>
            <ol start="23">
              <li>
                <u> CHARGES FOR ACCESSING THE PLATFORM</u>
              </li>
            </ol>
            <p>
              In order to sign up or access the Platform, you may incur standard
              network charges which shall be borne by you. You may incur
              additional data charges from the User&rsquo;s network operator as
              per the terms and conditions of the network operator. Such charges
              at no times shall be borne by Creamcollar Edutech Private Limited.
            </p>
            <ol start="24">
              <li>
                <u> MISCELLANEOUS</u>
              </li>
            </ol>
            <p>
              <strong>24.1 Governing Law and Jurisdiction:&nbsp;</strong>
              These Terms of Use is governed by the laws of India. Any claim
              related to any dispute arising as a result of the Platform or
              under these Terms of Use will be made before a court of competent
              jurisdiction located in Bengaluru, Karnataka. You agree that
              regardless of any statute or law to the contrary, any claim or
              cause of action arising out of or related to the Terms of Use or
              use of the Platform must be filed within one year after such claim
              or cause of action arose or be forever barred.
            </p>
            <p>
              <strong>24.2 No Partnership:&nbsp;</strong>You agree that no joint
              venture, partnership, employment, or agency relationship exists
              between You or other parties and Creamcollar Edutech Private
              Limited as a result of these Terms or your access to or use of the
              Platform.
            </p>
            <p>
              <strong>24.3 Headings:&nbsp;</strong>Headings used in these Terms
              of Use are for reference purposes only and in no way define or
              limit the scope of the section.
            </p>
            <p>
              <strong>24.4 Severability:&nbsp;</strong>If any provision of these
              Terms of Use is held to be unenforceable, such provision will be
              reformed only to the extent necessary to make it enforceable.
            </p>
            <p>
              <strong>24.5 Force Majeure:&nbsp;</strong>
              Creamcollar Edutech Private Limited shall be under no liability
              whatsoever in the event of non-availability of any portion of the
              Application or subscribed content occasioned by act of God, war,
              disease, revolution, riot, civil commotion, strike, lockout,
              pandemic, epidemic, lockdown, flood, fire, failure of any public
              utility, manmade disaster, infrastructure failure or any other
              cause whatsoever beyond the control of Creamcollar Edutech Private
              Limited.
            </p>
            <p>
              <strong>24.5 Waiver:&nbsp;</strong>The failure of Creamcollar
              Edutech Private Limited to act with respect to a breach of these
              Terms of Use by you or others does not constitute a waiver and
              will not limit Creamcollar Edutech Private Limited rights with
              respect to such breach or any subsequent breaches.
            </p>
            <p>
              <strong>24.6 Survival:&nbsp;</strong>The obligations under the
              provisions of section 11, 14 and 15, will survive any expiration
              or termination of this Terms of Use.
            </p>
            <p>
              <strong>24.7 Entire Agreement:&nbsp;</strong>
              These Terms of Use and the Privacy Policy on the Platform
              constitute the entire agreement between you and Creamcollar
              Edutech Private Limited and govern your use of the Platform,
              superseding any prior agreements between you and Creamcollar
              Edutech Private Limited.
            </p>
            <ol start="25">
              <li>
                <u> TAKE DOWN POLICY</u>
              </li>
            </ol>
            <p>
              Creamcollar Edutech Private Limited respects the legitimate rights
              of copyright owners and has adopted an efficient and take down
              procedure as described herein. This policy is intended to guide
              and encourage copyright owners in utilizing that procedure.
            </p>
            <p>
              Creamcollar Edutech Private Limited has posted various videos on
              the Platform which are freely available on various other media
              platforms. We understand that the videos have been uploaded on
              these media platforms by owners with the motive of further
              dissemination. Source code of such video has been provided by the
              owners for third party use, including Creamcollar Edutech Private
              Limited. Creamcollar Edutech Private Limited does not claim
              copyrights in the said videos.
            </p>
            <p>
              Creamcollar Edutech Private Limited abides by The Copyright Act,
              1957 / The Copyright Rules, 2013 and The Information Technology
              Act, 2000, and Rules framed thereunder and their subsequent
              amendments. As part of this, Creamcollar Edutech Private Limited
              shall endeavor to respond to written notifications of alleged
              infringement by legitimate copyright owners by removing or
              disabling access to alleged infringing material residing on our
              Platform.
            </p>
            <p>
              To submit a copyright infringement notification, please write
              Creamcollar Edutech Private Limited, Chennai, India or send an
              email to www.creamcollar.com by providing the following &ndash;
            </p>
            <ol>
              <li>
                Identification of the copyrighted work that you claim has been
                infringed
              </li>
              <li>
                Identification of the specific material / Content that is
                alleged as infringing and information sufficient for Creamcollar
                Edutech Private Limited to locate (by providing a URL, etc.) the
                material / Content.
              </li>
              <li>
                A Statement that you believe, in good faith, that the use of the
                Platform is not authorised by the Copyright owner, its agent or
                by law.
              </li>
              <li>
                If you are not a copyright owner, a description of your
                relationship with the copyright owner.
              </li>
              <li>
                A physical signature or electronic signature of a person
                authorised to act on behalf of the copyright owner.
              </li>
              <li>
                Your contact information, including your name, address and
                telephone number.
              </li>
              <li>
                A statement that the information in the notification is accurate
                and true to the best of your knowledge and that you are the
                copyright owner or are authorised to act on behalf of the
                copyright owner.
              </li>
            </ol>
            <p>&nbsp;</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              style={{
                backgroundColor: "#0b6aea",
              }}
              data-bs-dismiss="modal"
            >
              Did you really read this much ?
            </button>
          </div>
        </div>
      </div>
    </div>
  </span>
);

export default TermsAndConditionModal;
